import { useMemo, useState } from "react";
import { Spinner } from "reactstrap";
import AnalyticsNotFound from "../common/emptyScreens/analyticsNotFound";

const LocationStat = ({ country, city, date, loading }) => {
  const [tab, setTab] = useState("country");
  const totalClicks = useMemo(() => {
    const values = date?.map((item) => item?._count?._all);
    const clicks = values?.reduce((total, val) => total + val, 0) || 0;
    return clicks;
  }, [date]);

  const countries = useMemo(() => {
    return country.map((item) => {
      return {
        name: item?.country === "" ? "Others" : item?.country,
        clicks: item?._count?._all,
        percent: ((item?._count?._all / totalClicks) * 100).toFixed(2),
      };
    });
  }, [country, totalClicks]);

  const cities = useMemo(() => {
    return city.map((item) => {
      return {
        name: item?.city === "" ? "Others" : item?.city,
        clicks: item?._count?._all,
        percent: ((item?._count?._all / totalClicks) * 100).toFixed(2),
      };
    });
  }, [city, totalClicks]);

  const tableData = tab === "country" ? countries : cities;

  return (
    <div class="col-lg-6 pe-0 clickscanbylocationmap">
      <div class="card card-flush h-xl-100" style={{ borderRadius: "15px" }}>
        <div class="card-header min-h-lg-70px min-h-50px px-5 px-lg-10">
          <div class="card-title flex-column">
            <h3 class="fw-bold mb-1">Clicks + Scan by Location</h3>
          </div>

          <div class="card-toolbar">
            <div
              class="nav-group nav-group-outline rounded-1 w-fit-content"
              data-kt-buttons="true"
              data-kt-initialized="1"
            >
              <button
                type="button"
                onClick={() => setTab("country")}
                class={`btn btn-color-gray-400 btn-active btn-active-light-info rounded-1 fw-bold px-6 py-3 me-2 ${
                  tab === "country" ? "active" : ""
                }`}
              >
                Country
              </button>
              <button
                type="button"
                onClick={() => setTab("city")}
                class={`btn btn-color-gray-400 btn-active btn-active-light-info rounded-1 fw-bold px-6 py-3 ${
                  tab === "city" ? "active" : ""
                }`}
              >
                City
              </button>
            </div>
          </div>
        </div>

        <div
          class="card-body p-0"
          style={{ overflowY: "scroll", height: "500px !important;" }}
        >
          <div class="table-responsive px-7">
            <table class="table px-0 table-bordered px-8 border-right-0">
              <thead class="bg-light">
                <tr>
                  <th class="w-100 border-start-0 fs-5">
                    {tab === "country" ? "Country" : "City"}
                  </th>
                  <th class="fs-5 min-w-150px">Clicks + Scans</th>
                  <th class="border-end-0 fs-5">%</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "250px",
                    }}
                  >
                    <Spinner />
                  </div>
                ) : tableData?.length > 0 ? (
                  tableData?.map((data) => {
                    const { name = "", clicks = 0, percent = 0 } = data || {};
                    return (
                      <tr class="bg-hover-light-info">
                        <td class="border-start-0 fs-6">{name}</td>
                        <td class="fs-6">{clicks}</td>
                        <td class="border-end-0 fs-6">{percent}%</td>
                      </tr>
                    );
                  })
                ) : (
                  <AnalyticsNotFound />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationStat;
