import dayjs from "dayjs";
import capitalizeFirstLetter from "../../utils/captilizeFirstLetter";
import { countries } from "countries-list";
import AdminAPI from "../../services/http/admin";
import Dropdown from "react-bootstrap/Dropdown";

const Subscriber = ({
  subscriber,
  subscriberHandler,
  subscriberSelectorHandler,
}) => {
  const {
    firstName,
    lastName,
    username,
    createdAt = new Date(),
    country = "-",
    mobile = "-",
    ip = "-",
    plan,
    email = "-",
    isActive = false,
    id,
  } = subscriber || {};

  const name = `${firstName !== null ? firstName : ""} ${
    lastName !== null ? lastName : ""
  } ${(firstName === null && lastName === null && username) || "-"}`;

  const planDuration = plan?.split("-")[0];
  const planType = plan?.split("-")[1];

  const disableHandler = async () => {
    try {
      const response = await AdminAPI.updateUserStatus({
        id,
        isActive: !isActive,
      });
      if (!response?.data?.error) {
        subscriberHandler((preState) => {
          return preState?.map((item) =>
            item?.id === id
              ? { ...item, isActive: response?.data?.data?.isActive }
              : item
          );
        });
      }
      console.log({ response });
    } catch (e) {
      console.log("Error while changing user status: ", e);
    }
  };

  return (
    <>
      <tr class="link-row border border-right-0 border-top-0 border-left-0">
        <td class="ps-6">
          <div class="form-check form-check-sm form-check-custom form-check-info form-check-solid rounded-1">
            <input
              class="form-check-input rounded-1"
              type="checkbox"
              checked={subscriber?.isSelected}
              onChange={() => subscriberSelectorHandler(id)}
            />
          </div>
        </td>
        <td>
          <div class="text-gray-800 fw-bold">
            {dayjs(createdAt).format("MMM DD, YYYY - HH:mm")}
          </div>
        </td>
        <td class="text-start pe-0">
          <div class="text-gray-800 fw-bold my-auto d-block">{name}</div>
        </td>
        <td class="text-start pe-0">
          <div class="text-gray-800 fw-bold my-auto d-block">{email}</div>
        </td>
        <td>
          <div class="text-gray-800 fw-bold my-auto d-block">{mobile}</div>
        </td>
        <td>
          <div class="text-gray-800 fw-bold">
            {capitalizeFirstLetter(planType)}
          </div>
        </td>
        <td>
          <div class="text-gray-800 fw-bold">
            {capitalizeFirstLetter(planDuration)}
          </div>
        </td>
        <td>
          <div class="text-gray-800 fw-bold">
            {countries[country]?.name || "-"}
          </div>
        </td>
        <td>
          <div class="text-gray-800 fw-bold">{ip}</div>
        </td>
        <td>
          <div class={`fw-bold ${isActive ? "text-success" : "text-danger"}`}>
            {isActive ? "Active" : "Inactive"}
          </div>
        </td>
        <td class="ps-0 cursor-pointer">
          <Dropdown className="d-inline nospce">
            <Dropdown.Toggle
              id="dropdown-autoclose-false"
              className="btn btn-icon bg-light-info rounded-1"
              variant="bg-light"
            >
              <i class="fa-regular fa-ellipsis-vertical fs-2 ms-5"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-3">
              <div
                className={`menu-item px-2 bg-hover-light-info ${
                  isActive ? "text-danger" : "text-success"
                }`}
                onClick={disableHandler}
              >
                {isActive ? "Disable" : "Enable"}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    </>
  );
};

export default Subscriber;
