import React from "react";
import { addDays } from 'date-fns';
import { FormGroup, Label, Input, Col, Row } from "reactstrap";
import { Controller } from "react-hook-form";

const AddNewTagLinks = ({ control }) => {
  return (
    <Row className="mb-7 mt-5">
      <Col md={6}>
        <FormGroup>
          <Label className="fs-5 fw-bold mb-2">
            Tags for your link <span className="text-gray-400">(Optional)</span>
          </Label>
          <Controller
            name="tags"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <Input
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  placeholder="Enter tags for your link"
                  className="h-50px"
                />
                {fieldState.error && (
                  <span className="error-text text-danger">
                    {fieldState.error.message}
                  </span>
                )}
              </>
            )}
          />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label className="fs-5 fw-bold mb-2">Expiration date</Label>
          <Controller
            name="expirationDate"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <Input
                  {...field}
                  type="date"
                  className="h-50px"
                  min={addDays(new Date(), 1).toISOString().split("T")[0]}
                />
              </>
            )}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default AddNewTagLinks;
