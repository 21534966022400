import httpMethods from "..";

const FromAddressAPI = {
  createFromAddress: async (token, values) => {
    return httpMethods
      .post(`/from-addresses`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getAllFromAddress: async (token) => {
    return httpMethods
      .get(`/from-addresses`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },

  updateFromAddresss: async (token, id, values) => {
    return httpMethods
      .put(`/from-addresses/${id}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  deleteFromAddresss: async (token, id) => {
    return httpMethods
      .delete(`/from-addresses/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getFromAddressById: async (token, id) => {
    return httpMethods
      .get(`/from-addresses/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },


};

export default FromAddressAPI;
