const TableHeader = ({ allSelected, allUserSelectorHandler }) => {
  return (
    <thead
      className="w-100 rounded-1"
      style={{
        backgroundColor: "#faf8ff",
        borderTop: "1px solid #e9e6f1",
        borderBottom: "1px solid #e9e6f1",
      }}
    >
      <tr className="text-start text-gray-600 fw-semibold fs-6 gs-0">
        {/* <th
          className="w-10px ps-6 pe-2"
          style={{
            borderRadius: "5px 0px 0px 5px !important",
            borderLeft: "1px solid #e9e6f1",
          }}
        >
          <div className="form-check form-check-sm form-check-info form-check-solid me-3">
            <input
              className="form-check-input n-checked-white"
              type="checkbox"
              onChange={(e) => allUserSelectorHandler(e.target.checked)}
              checked={allSelected || false}
            />
          </div>
        </th> */}
        <th className="min-w-200px">User</th>
        <th className="min-w-200px">Role</th>
        <th className="min-w-200px">Last Login</th>
        <th className="min-w-200px">Joined</th>
        <th
          className="min-w-75px"
          style={{
            borderRadius: "0px 5px 5px 0px",
            borderRight: "1px solid #e9e6f1",
          }}
        >
          Action
        </th>
      </tr>
    </thead>
  );
};

export default TableHeader;
