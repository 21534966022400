import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, FormGroup, Input, Spinner, Button } from "reactstrap";
import * as yup from "yup";
import AuthPageWrapper from "../components/wrapper/authPageWrapper";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import AuthApi from "../services/http/auth";
import useCustomMessage from "../hooks/useCustomMessage";
import { EMAIL_REGEX } from "../utils/enum";

const forgetPasswordValidation = yup.object().shape({
  email: yup
    .string()
    .required("Please fill this field")
    .matches(EMAIL_REGEX, "It must be a valid email"),
});

const ForgotPassword = () => {
  const { message, messageHandler } = useCustomMessage();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(forgetPasswordValidation),
  });

  const submitHandler = async (data) => {
    try {
      setLoading(true);
      const response = await AuthApi.resetPassword(data);
      messageHandler(response.data?.data?.type, response?.data?.success)
      setLoading(false);
    } catch (e) {
      messageHandler(
        e?.response?.data?.data?.type ||
          e?.response?.data?.message ||
          "Something went wrong"
      ,false);
      console.log("Error while reseting password", e);
      setLoading(false);
    }
  };
  return (
    <AuthPageWrapper>
      <Form
        onSubmit={handleSubmit(submitHandler)}
        className="px-6 position-relative z-1"
        novalidate="novalidate"
        id="kt_sign_in_form"
        data-kt-redirect-url="/links-list.php"
        action="#"
      >
        <div class="text-lg-center mb-lg-10 text-lg-center mt-lg-0 mt-8 mb-7">
          <h1 class="text-dark mb-3 fs-3x fw-bolder">Forgot Password ?</h1>

          <div class="text-gray-400 fw-semibold fs-4">
            Enter your email to reset your password.
          </div>
        </div>

        <div className="fv-row mb-7 fv-plugins-icon-container">
          <FormGroup>
            <div className="position-relative">
              <Controller
                name={"email"}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    value={value}
                    className="form-control bg-white h-55px border border-gray-300 form-control-lg form-control-solid mb-2 pe-15 rounded-55rem"
                    type="text"
                    placeholder="Enter your email"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    onBlur={onBlur}
                  />
                )}
              />
              <span
                className="btn btn-sm btn-icon  position-absolute translate-middle top-50 end-0 me-n2 opacity-25"
                data-kt-password-meter-control="visibility"
              >
                <i className="fa-regular fa-envelope fs-2"></i>
              </span>
            </div>
            {errors?.email && (
              <span className="error-text text-danger">
                {errors?.email?.message}
              </span>
            )}
          </FormGroup>
        </div>
        <div
          style={{
            textAlign: "end",
          }}
        >
          {message ? message : null}
        </div>

        <div class="fv-row mb-10">
          <div class="text-center">
            <Button
              color="info"
              className="btn btn-lg btn-info bg-brand w-100 h-55px mt-5 mb-5 rounded-55rem btn-hover-dark"
              disabled={loading}
              type="submit"
            >
              {loading ? <Spinner /> : "Submit"}
            </Button>
            <Link
              to="/"
              class="btn btn-lg btn-light bg-white border border-gray-300 w-100 h-55px rounded-55rem d-flex justify-content-center align-items-center fw-bold"
            >
              <span class="indicator-label">Cancel</span>
            </Link>
          </div>
        </div>
      </Form>
    </AuthPageWrapper>
  );
};

export default ForgotPassword;
