import { Nav, NavItem, NavLink } from "reactstrap";

const Tabs = ({ tab }) => {
  return (
    <Nav
      tabs
      className="border-0 fs-6 nav nav-line-tabs nav-stretch nav-tabs py-3 rounded-1 stepper-nav"
    >
      <NavItem className="w-50">
        <NavLink
        style={{borderRadius:"5px 0px 0px 5px !important"}}
          className={`${
            tab === 1 ? "bgcustomlinktab text-info" : "activate-cat"
          } border border-gray-300 border-opacity-10 border-end-0 btn fs-lg-4 fs-6 fw-semibold h-lg-50px h-35px justify-content-center m-0 nav-link rounded-0 text-center w-100`}
        >
          Step 1
        </NavLink>
      </NavItem>
      <NavItem className="w-50">
        <NavLink
        style={{borderRadius:"0px 5px 5px 0px !important"}}
          className={`${
            tab === 2 ? "bgcustomlinktab text-info" : "activate-cat"
          } border border-gray-300 border-opacity-10 btn fs-lg-4 fs-6 fw-semibold h-lg-50px h-35px justify-content-center m-0 nav-link rounded-0 text-center w-100`}
        >
          Step 2
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default Tabs;
