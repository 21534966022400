import { Spinner } from "reactstrap";
import ApexSalesChart from "./apex-charts/salesChart";
import AnalyticsNotFound from "./analyticsNotFound";
import dayjs from "dayjs";
import DateRangeSelector from "./dateRange";
import { useEffect, useState } from "react";
import AdminAPI from "../../services/http/admin";
import convertToK from "../../utils/convertToK";

const SalesChart = () => {
  const [loading, setLoading] = useState(false);
  const [totalSales, setTotalSales] = useState(0);
  const [sales, setSales] = useState([]);

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date().setDate(new Date().getDate() - 30),
    endDate: new Date(),
    key: "selection",
  });

  const fetchSales = async (
    startDate = new Date().setDate(new Date().getDate() - 30),
    endDate = new Date()
  ) => {
    try {
      setLoading(true);
      const response = await AdminAPI.getSales({ startDate, endDate });
      if (!response?.data?.error) {
        setTotalSales(response?.data?.data?.totalSaleAmount);
        setSales(response?.data?.data?.sales);
      }
      setLoading(false);
    } catch (e) {
      console.log("Error while fetching sales: ", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSales();
  }, []);

  const data = sales?.map((item) => +item?.amount);
  const categories = sales?.map((item) => dayjs(item?.date).format("MMM DD"));

  return (
    <div class="col-lg-12 col-xl-12 mb-5 mb-xl-0">
      <div class="card card-flush overflow-hidden h-md-100">
        <div class="card-header py-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fs-1 fw-bold text-gray-900">
              Total Sales
            </span>
            <span class="fs-6 fw-semibold text-gray-500 me-1">
              From all Plans
            </span>
          </h3>

          <div class="card-toolbar">
            <div className="mt-5 mt-lg-0">
              <div
                onClick={() => setOpenDatePicker(!openDatePicker)}
                class="form-control form-control-solid w-lg-250px justify-content-between"
                style={{
                  position: "relative",
                }}
              >
                <span>
                  {dayjs(selectedDateRange.startDate).format("MM/DD/YYYY")}
                </span>
                <span>{` - `}</span>
                <span>
                  {dayjs(selectedDateRange.endDate).format("MM/DD/YYYY")}
                </span>
              </div>
              {openDatePicker ? (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 99999,
                    right: 0,
                  }}
                >
                  <DateRangeSelector
                    analyticsHandler={fetchSales}
                    toggleHandler={setOpenDatePicker}
                    selectedDateRange={selectedDateRange}
                    setSelectedDateRange={setSelectedDateRange}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div class="card-body border-top d-flex justify-content-between flex-column pb-1 px-0">
          <div class="px-9 mb-5">
            <div className="d-flex mb-2">
              <span className="fs-2 fw-semibold text-gray-500 me-1">$</span>
              <span className="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">
                {" "}
                {convertToK(totalSales)}
              </span>
            </div>
          </div>

          <div
            class="min-h-auto ps-2"
            style={{ height: "450px", minHeight: "465px" }}
          >
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "250px",
                }}
              >
                <Spinner />
              </div>
            ) : sales?.length > 0 ? (
              <ApexSalesChart
                stats={{ data: [0, ...data], categories: ["", ...categories] }}
              />
            ) : (
              <AnalyticsNotFound />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesChart;
