import React, { useState, useCallback } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import ProgressBar from '@ramonak/react-progress-bar';
import { useDropzone } from 'react-dropzone';
import ColumnMapping from './ColumnMapping';
import { bulk_upload_application_fields } from '../../config/constants';
import {
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from 'reactstrap';

const CHUNK_SIZE = 1000; // Define chunk size

const FileUpload = ({ apiEndpoint, handleUploadFileCanvas, handleRelod }) => {
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState(null);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]); // Store parsed CSV data

  const toggleCanvas = () => {
    setIsOpen(!isOpen);
  };

  const closeAllCanvas = () => {
    handleUploadFileCanvas(false);
    setIsOpen(false);
  }

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setError('Only CSV files are accepted');
      return;
    }

    const file = acceptedFiles[0];
    if (file && file.type === 'text/csv') {
      setFileName(file.name);
      setProgress(0);
      setError(null);
      handleFileUpload(file);
    } else {
      setError('Invalid file type. Please upload a CSV file.');
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ 
    onDrop, 
    accept: '.csv',
    multiple: false,
  });

  const handleFileUpload = (file) => {
    let uploadedChunks = 0;
    Papa.parse(file, {
      header: true,
      chunk: async (results, parser) => {
        parser.pause();
        try {
          if (uploadedChunks === 0) {
            const headers = Object.keys(results.data[0]);
            setHeaders(headers); // Store headers in state
          }
          // Combine chunks into one array and store
          setCsvData(prevData => [...prevData, ...results.data]);
          
          uploadedChunks += 1;
          setProgress(Math.round((uploadedChunks * CHUNK_SIZE) / file.size * 100));
        } catch (err) {
          setError(err.response ? err.response.data.error : 'Upload failed');
          parser.abort();
        }
        parser.resume();
      },
      complete: () => {
        setProgress(100);
        setIsOpen(!isOpen);
      },
      error: (err) => {
        setError('Error parsing CSV file');
      }
    });
  };

  return (
    <>
      <div {...getRootProps()} style={dropzoneStyle}>
        <input {...getInputProps()} />
        <p className='font20px'>Drag 'n' drop a CSV file here, or click to select one</p>
      </div>
      {fileName && <p className='m-4 mb-0'><b>Uploading:</b> {fileName}</p>}
      {error && <p className='p-4' style={{ color: 'red' }}>Error: {error}</p>}
      <ProgressBar className='pb-4 px-4 my-pg' completed={progress} />

      {/* <button className='btn btn-info py-2 px-4' onClick={toggleCanvas}>
        Map
      </button> */}

      <Offcanvas isOpen={isOpen} toggle={closeAllCanvas} className="custom-canvas-width" direction='end'>
        <OffcanvasHeader className='mb-4 mx-4 mt-2 border-bottom' toggle={closeAllCanvas}>
          <h3 className='font-weight-bold'>Upload Products</h3>
        </OffcanvasHeader>
        <OffcanvasBody>
          <ColumnMapping fields={bulk_upload_application_fields} headers={headers} csvData={csvData} closeAllCanvas={closeAllCanvas} handleRelod = {handleRelod}/>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

const dropzoneStyle = {
  width: '92%',
  height: '200px',
  borderWidth: '2px',
  borderColor: '#666',
  borderStyle: 'dashed',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  minWidth: '570px',
  margin: '0px 25px'
};

export default FileUpload;
