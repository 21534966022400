import * as yup from "yup";
import { SLASH_TAG_REGEX, URL_REGEX } from "../enum";
import { BLOCKED_KEYWORDS } from "../../config/constants";

export const createQrCodeValidation = yup.object().shape({
  destinationUrl: yup
    .string()
    // .url("Enter a valid URL")
    .matches(URL_REGEX, "Enter a valid URL")
    .test('is-not-in-list', 'Restricted Content', (value, data) => {
      if (!value) return true; // Skip validation if value is not provided (handled by required)
      const valueLowerCase = value.toLowerCase();
      return !(BLOCKED_KEYWORDS.some(str => valueLowerCase.includes(str)) || BLOCKED_KEYWORDS.some(str => data?.parent?.title?.toLowerCase().includes(str)) || BLOCKED_KEYWORDS.some(str => data?.parent?.metaDescription?.toLowerCase().includes(str)));
    })
    .required("This field is required"),
  // slashTag: yup.string().required("Custom slug is required!").max(50, "Custom slug must be at most 50 characters!"),
  slashTag: yup.string()
    .matches(SLASH_TAG_REGEX, {
      message: "The characters `~,<>;:'\"/\\[\\]^{}()=+!*@&$?%#|` are not allowed",
      excludeEmptyString: true,
    })
    .matches(/^\S*$/, {
      message: "Space is not allowed in slash tag",
      excludeEmptyString: true,
    })
    .test('is-not-in-list', 'Restricted Content', value => {
      if (!value) return true; // Skip validation if value is not provided (handled by required)
      const valueLowerCase = value.toLowerCase();
      return !BLOCKED_KEYWORDS.some(str => valueLowerCase.includes(str));
    })
    .required("Slash tag is required!")
    .max(50, "Slash tag can't be longer than 50 characters"),
});