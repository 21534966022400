const TableHeader = ({ allSelected, allSubscribersSelectorHandler }) => {
  return (
    <thead
      class="w-100 rounded-1"
      style={{
        backgroundColor: "#faf8ff",
        borderTop: "1px solid #e9e6f1",
        borderBottom: "1px solid #e9e6f1",
      }}
    >
      <tr class="text-start text-gray-600 fw-semibold fs-6 gs-0">
        <th
          class="w-10px ps-6 pe-2"
          style={{
            borderRadius: "5px 0px 0px 5px !important",
            borderLeft: "1px solid #e9e6f1",
          }}
        >
          <div class="form-check form-check-sm form-check-info form-check-solid me-3">
            <input
              class="form-check-input n-checked-white"
              type="checkbox"
              onChange={(e) => allSubscribersSelectorHandler(e.target.checked)}
              checked={allSelected || false}
            />
          </div>
        </th>
        <th class="min-w-150px">Date & Time</th>
        <th class="min-w-100px">Name</th>
        <th class="min-w-150px">Email</th>
        <th class="min-w-150px">Phone</th>
        <th class="min-w-100px">Plan</th>
        <th class="min-w-100px">Payment Mode</th>
        <th class="min-w-125px">Country</th>
        <th class="min-w-125px">IP Address</th>
        <th class="min-w-75px">Status</th>
        <th
          class="min-w-50px"
          style={{
            borderRadius: "0px 5px 5px 0px",
            borderRight: "1px solid #e9e6f1",
          }}
        ></th>
      </tr>
    </thead>
  );
};

export default TableHeader;
