import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const StickyButton = ({ form, activeClass }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const { watch } = form;
  const { qr } = watch();

  return (
    <>
      <button
        type="button"
        className={`view_qr_mobile btn bg-light w-100 mb-4 border rounded-1 desktop-hide position-sticky ${activeClass}`}
        onClick={toggleModal}
        style={{ top: "30px" }}
      >
        Preview QR Code
      </button>
      <Modal
        className="qrmobileviewmodal modal-dialog-centered"
        isOpen={isModalOpen}
        toggle={toggleModal}
      >
        <ModalHeader>
          <div className="d-flex justify-content-between align-items-center w-100">
            <h5 className="fw-bold mb-0">QR Code Preview</h5>
            <i onClick={toggleModal} className="fas fa-times" />
          </div>
        </ModalHeader>
        <ModalBody>
          <img src={qr} alt="Qr code" width={"100%"} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default StickyButton;
