import { useParams } from "react-router-dom";
import CreateQRCode from "../../components/qr-codes/create-qr-code";
import PageWrapper from "../../components/wrapper/pageWrapper";
import { useEffect, useState } from "react";
import QrCodesAPI from "../../services/http/qrCodes";
const Index = () => {
  const { id } = useParams();

  const [qrCode, setQrCode] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const fetchQrCode = async () => {
      const auth_token = localStorage.getItem("auth_token");
      QrCodesAPI.getQrCode(auth_token, id)
        .then(({ data }) => {
          if (data.resultData) {
            setQrCode(data.resultData);
            setIsEdit(true);
          }
        })
        .catch((error) => {
          console.log("Error while fetching qr codes: ", error);
        });
    };

    fetchQrCode();
  }, [id]);

  return (
    <PageWrapper>
      <CreateQRCode edittedData={qrCode} isEdit={isEdit} />
    </PageWrapper>
  );
};

export default Index;
