import React, { useEffect, useState } from 'react'
import { Table } from "reactstrap";
import EmptyState from "../../assets/media/empty-state-link-list.png"
import { Spinner } from "reactstrap";

const ContactTable = (props) => {

    const {
        selectAll,
        handleSelectAllChange,
        
        allContacts,
        isContactSelected,
        handleCheckboxChange,

        handleContactUpdate,

        setContactForDelete,
        toggleSingleDeleteModal,

        openCreateForm,

        search,
        tags,
        isLoading,
        infinitePageLoading
    } = props;

    const [contacts, setContacts] = useState([]);

    useEffect(() => {
        console.log("table component select all",selectAll);
        setContacts(allContacts);
    }, [allContacts]);
    
    return (
        <>
            <Table striped >
                <thead className="table-border" style={{position:"sticky", top:0}}>
                    <tr className="fs-6 fw-semibold gs-0 table-style text-gray-600 text-start">
                    <th className="w-10px ps-6 pe-2 py-5">
                        <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                        className="absolute form-check-input cursor-pointer left-4 top-1/2 -mt-2 h-4 w-4 rounded-1 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                    </th>
                    <th className="min-w-200px py-5">First Name</th>
                    <th className="min-w-150px py-5">Last Name</th>
                    <th className="min-w-100px py-5">Email</th>
                    <th className="min-w-100px py-5">Mobile</th>
                    <th className="min-w-100px py-5">Whatsapp</th>
                    <th className="min-w-100px py-5">Job Title</th>
                    <th className="min-w-100px py-5">Department</th>
                    <th className="min-w-125px py-5">Location</th>
                    <th className="min-w-50px py-5">Actions</th>
                    </tr>
                </thead>
                <tbody className="fw-semibold text-gray-600 w-100" >
                    {contacts.length > 0 ? ( contacts.map((contact, index) => (
                    <>
                        <tr key={index} className="link-row border border-right-0 py-3 border-top-0 border-left-0">
                        <td className="ps-6 py-5">
                            <input
                            type="checkbox"
                            checked={selectAll ? true : isContactSelected(contact)}
                            onChange={() => handleCheckboxChange(contact)}
                            className="absolute form-check-input cursor-pointer left-4 top-1/2 -mt-2 h-4 w-4 rounded-1 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                        </td>
                        <td className="py-5">
                            <div className="d-flex justify-content-start align-items-center">
                            {contact.firstName}
                            </div>
                        </td>
                        <td className="text-start pe-0 py-5">{contact?.lastName ? contact.lastName : <>-</>}</td>
                        <td className="text-start pe-0 py-5">{contact?.email ? contact.email : <>-</>}</td>
                        <td className="text-start pe-0 py-5">{contact?.mobile ? contact.mobile : <>-</>}</td>
                        <td className="text-start pe-0 py-5">{contact?.whatsapp ? contact.whatsapp : <>-</>}</td>
                        <td className="text-start pe-0 py-5">{contact?.job ? contact.job : <>-</>}</td>
                        <td className="text-start pe-0 py-5">{contact?.department ? contact.department : <>-</>}</td>
                        <td className="text-start pe-0 py-5">{contact?.state ? contact.state : <>-</>}, {contact.country ? contact.country : <>-</>}</td>
                        <td className="text-end pe-0 py-5">
                            <div className="w-fit-content d-flex align-items-center">
                            <button 
                                type="button" 
                                className="btn px-0 py-0" 
                                onClick={()=>handleContactUpdate(contact)}
                            >
                                <i className="fa-edit fs-2 cursor-pointer fa-regular ms-5"></i>
                            </button>
                            <button type="button" className="btn px-0 py-0" onClick={() => {
                                setContactForDelete(contact);
                                toggleSingleDeleteModal()
                            }}>
                                <i className="fa-regular fa-trash-can fs-2 ms-5 text-danger cursor-pointer" title="Delete" style={{ marginTop: "-4px" }}></i>
                            </button>
                            </div>
                        </td>
                        </tr>
                    </>
                    ))
                ) : (
                    <tr>
                        <td colSpan="10" rowSpan="20" className="py-5 bg-white">
                        <div className="d-flex flex-column justify-content-center align-items-center mt-15">
                            
                            {isLoading ? <>
                                <Spinner/>
                            </> : <>
                                <img className="opacity-50 img-fluid" src={EmptyState} alt=""/>
                                <div className="text-center mt-lg-10">
                                    <h2 className="fs-2x pt-5 pb-5 fw-bold">No Contacts to display.</h2>
                                    <p className="w-75 mx-auto fw-semibold fs-5 text-gray-500 mb-8">
                                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Odit, deserunt. Lorem ipsum dolor sit amet.
                                    </p>
                                    {(search.trim() === '' && tags.length === 0) &&
                                        <button 
                                            type="button"
                                            className="btn btn-info rounded-1" 
                                            onClick={openCreateForm}
                                        >
                                            Add New Contact
                                        </button>
                                    }
                                </div>
                            </>}
                        </div>
                        </td>
                    </tr>
                    )}
                    {infinitePageLoading && <tr className="link-row border border-right-0 py-3 border-top-0 border-left-0">
                        <td colSpan="10" rowSpan="20" className="bg-white">
                            <div className="d-flex flex-column justify-content-center align-items-center mt-2">
                                <Spinner/>
                            </div>
                        </td>                    
                    </tr>}
                </tbody>
            </Table>
        </>
    )
}

export default ContactTable
