
export const getUtmQuery = (utmParams) => {
    const query = Object.entries(utmParams)
        .filter(([key, value]) => value !== '' && value !== null)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
    return query ? '?' + query : '';
}

export function isValidURL(url) {
    const regex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+)(\.[a-zA-Z]{2,})(:\d{1,5})?(\/\S*)?$/;
    return regex.test(url);
}


export const getUrlOrigin = (destinationUrl) => {
    try {
        const domain = new URL(destinationUrl);
        return domain.origin || destinationUrl
    } catch (e) {
        console.log("error")
        return ""
    }

}

export const getFaviconUrl = async (url) => {
    try {

        const response = await fetch(url);
        const html = await response.text();

        const faviconRegex = /<link.*?rel=["'](?:icon|shortcut icon)["'].*?href=["'](.*?)["'].*?>/i;
        const match = html.match(faviconRegex);

        if (match && match[1]) {
            const faviconUrl = match[1];
            return faviconUrl;
        } else {
            throw new Error("Favicon not found");
        }
    } catch (error) {
        console.error("Error fetching favicon:", error);
    }
}

export const fetchTitleAndFavicon = async (url) => {
    try {
        const response = await fetch(url);
        const html = await response.text();
        let titleAndIcon = {
            icon: '',
            pageTitle: ''
        }
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const pageTitle = doc.querySelector("head title").textContent || '';
        titleAndIcon = { ...titleAndIcon, pageTitle }

        const faviconRegex = /<link.*?rel=["'](?:icon|shortcut icon)["'].*?href=["'](.*?)["'].*?>/i;
        const match = html.match(faviconRegex);

        if (match && match[1]) {
            const icon = match[1];
            titleAndIcon = { ...titleAndIcon, icon }
        } else {
            throw new Error("Favicon not found");
        }
        return titleAndIcon;
    } catch (error) {
        console.error("Error fetching favicon:", error);
    }
}


export const createUrl = (domain, urlId) => {
    return `${domain}/${urlId}`
}