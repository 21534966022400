import BillingAddress from "./addess";
import BillingHistory from "./history";

const Index = () => {
  return (
    <>
      <BillingAddress />
      <BillingHistory />
    </>
  );
};

export default Index;
