import { useMemo, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap";
import InstagramIcon from "../../../assets/media/social-icons/instagram.svg";
import FacebookIcon from "../../../assets/media/social-icons/facebook.svg";
import TwitterIcon from "../../../assets/media/social-icons/twitter.svg";
import YoutubeIcon from "../../../assets/media/social-icons/youtube.svg";

export const SOCIAL_MAPPER = {
  instagram: {
    icon: InstagramIcon,
    label: "instagram",
  },
  facebook: {
    icon: FacebookIcon,
    label: "facebook",
  },
  twitter: {
    icon: TwitterIcon,
    label: "twitter",
  },
  youtube: {
    icon: YoutubeIcon,
    label: "youtube",
  },
};

const options = [
  {
    icon: InstagramIcon,
    label: "instagram",
  },
  {
    icon: FacebookIcon,
    label: "facebook",
  },
  {
    icon: TwitterIcon,
    label: "twitter",
  },
  {
    icon: YoutubeIcon,
    label: "youtube",
  },
];

const SocialDropdown = ({ item = {}, handler, socials }) => {
  const [search, setSearch] = useState();
  const [selectedOption, setSelectedOption] = useState(SOCIAL_MAPPER[item?.id]);

  const searchedResult = useMemo(() => {
    if (search) {
      return options.filter((item) =>
        item?.label.toLowerCase().includes(search.toLowerCase())
      );
    }
    return options;
  }, [search]);

  const searchHandler = (e) => {
    setSearch(e.target.value);
  };

  const optionHandler = (option) => {
    setSelectedOption(option);
    handler(option?.label);
  };

  return (
    <UncontrolledDropdown direction="down">
      <DropdownToggle className="bg-transparent btn-active-light border-gray-300 h-50px rounded-1 w-100 d-flex arrowdowndd" style={{ border: "1px solid #e9e6f1" }}>
        {selectedOption ? (
          <div>
            <img
              src={selectedOption?.icon}
              alt={selectedOption?.label}
              className="rounded-circle h-20px me-2"
            />
            <span >{selectedOption?.label}</span>
          </div>
        ) : (
          <span>{"Select an option"}</span>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <div
          style={{
            padding: "0.5rem 1.25rem",
            margin: "0 0 0.5rem 0",
          }}
        >
          <Input
            value={search}
            onChange={searchHandler}
            style={{
              backgroundColor: "#ffffff",
              padding: "0.55rem 0.75rem",
              color: "#5e6278",
              fontSize: "0.95rem",
              border: "1px solid #e1e3ea",
              borderRadius: "0.35rem",
              outline: "0 !important",
            }}
          />
        </div>
        {searchedResult?.map((item, index) => {
          return (
            <DropdownItem
              disabled={socials.map((item) => item.id).includes(item?.label)}
              onClick={() => optionHandler(item)}
              key={index}
            >
              <div>
                {item?.icon ? (
                  <img
                    src={item?.icon}
                    alt={item?.label}
                    className="rounded-circle h-20px me-2"
                  />
                ) : null}
                <span>{item?.label}</span>
              </div>
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default SocialDropdown;
