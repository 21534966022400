import { useContext, useState } from "react";
import CustomLink from "./customLink";
import { Card, CardBody, Row, Spinner, Tooltip } from "reactstrap";
import CustomLinksAPI from "../../../services/http/customLinks";
import NotFound from "../../common/emptyScreens/notFound";
import { AuthContxt } from "../../../store/authContxt";

const CustomLinks = ({
  modalHandler,
  customLinks,
  loading,
  customLinksHandler,
  fetchCustomLinks,
}) => {
  const [refreshLoading, setRefreshLoading] = useState(false);

  const { quota } = useContext(AuthContxt);
  const isLimitReached = quota?.connectedDomain === 0;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => {
    if (isLimitReached) {
      setTooltipOpen(!tooltipOpen);
    }
  };

  const customLinksVerificationHandler = async () => {
    setRefreshLoading(true);
    const auth_token = localStorage.getItem("auth_token");
    CustomLinksAPI.verifyCustomLink(auth_token)
      .then(({ data }) => {
        fetchCustomLinks();
      })
      .catch((error) => {
        console.log("Error while fetching custom links: ", error);
      })
      .finally(() => {
        setRefreshLoading(false);
      });
  };

  const notLinksScreenMapper = {
    notFound: <NotFound handler={modalHandler} type={"customLink"} />,
  };

  return (
    <Card className="card card-flush rounded-sm-0">
      <div className="px-6 px-lg-10 py-lg-10 py-5 d-flex justify-content-between w-100 d-flex align-items-center w-100">
        <h2 className="me-15 fs-2x mb-0">Domains</h2>
        <div className="d-flex align-items-center card-toolbar">
          <div style={{ cursor: "pointer" }}>
            <i
              style={{
                animation: refreshLoading
                  ? "rotate 1s infinite linear"
                  : "none",
              }}
              className="fa-regular fa-sync-alt fs-lg-2x fs-1 me-0 me-lg-8"
              onClick={customLinksVerificationHandler}
            ></i>
          </div>

          <div onMouseOver={toggle} onMouseLeave={toggle} id="addDomain">
            <button
              disabled={isLimitReached}
              type="button"
              className="btn btn-info rounded-1 mobile-hide"
              onClick={() => {
                !isLimitReached && modalHandler()
              }}
            >
              Add New Domain
            </button>
          </div>
          <Tooltip
            placement={"top"}
            isOpen={tooltipOpen}
            target={"addDomain"}
            toggle={toggle}
            className="mobile-hide"
          >
            {`Your Domain Limit Reached. Please Upgrade your Plan`}
          </Tooltip>

          <div onMouseOver={toggle} onMouseLeave={toggle} id="addDomain">
            <button
              disabled={isLimitReached}
              type="button"
              onClick={() => {
                !isLimitReached && modalHandler();
              }}
              title="Add New Link"
              class="btn btn-icon btn-info rounded-circle mobile-add-link-btn desktop-hide"
              style={{ bottom: "20px" }}
            >
              <i class="fa-solid fa-plus fs-2 fs-3"></i>
            </button>
          </div>
          <Tooltip
            placement={"top"}
            isOpen={tooltipOpen}
            target={"addDomain"}
            toggle={toggle}
            className="desktop-hide"
          >
            {`Your Domain Limit Reached. Please Upgrade your Plan`}
          </Tooltip>
        </div>
      </div>
      <CardBody className="card-body px-6 px-lg-10 pt-0 overflow-x-auto">
        <Row>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "70vh" }}
            >
              <Spinner />
            </div>
          ) : customLinks && customLinks.length > 0 ? (
            customLinks?.map((link, index) => {
              return (
                <CustomLink
                  link={link}
                  key={index}
                  customLinksHandler={customLinksHandler}
                />
              );
            })
          ) : (
            <tr style={{ display: "flex" }}>
              <td className="border-0 position-relative w-100" colSpan={"8"}>
                {notLinksScreenMapper["notFound"]}
              </td>
            </tr>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default CustomLinks;
