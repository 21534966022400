import { useEffect, useState } from "react";
import Analytics from "../../common/analytics";
import SharedApi from "../../../services/http/shared";

const QrCodeDetailsAnalytics = ({ shortlink }) => {
  const [loading, setLoading] = useState(false);
  const [analytics, setAnalytics] = useState({});

  const fetchAnalytics = (
    startDate = new Date().setDate(new Date().getDate() - 30),
    endDate = new Date()
  ) => {
    setLoading(true);
    const auth_token = localStorage.getItem("auth_token");
    SharedApi.getAnalytics(auth_token, {
      shortlink,
      startDate,
      endDate,
    })
      .then(({ data }) => {
        setAnalytics(data?.resultData);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAnalytics();
  }, []);

  return (
    <Analytics
      type={"qr"}
      loading={loading}
      analytics={analytics}
      shortlink={shortlink}
      analyticsHandler={fetchAnalytics}
    />
  );
};

export default QrCodeDetailsAnalytics;
