import dayjs from "dayjs";
import SharedApi from "../services/http/shared";

export const notFoundTitle = `Untitled ${dayjs(new Date()).format('YYYY-MM-DD')}`;

const checkUrl = (url) => {
    try {
        new URL(url);
    } catch (error) {
        console.log("Invalid url: ", error);
        return false;
    }
    return true;
}

const titleFetcher = async (url = "") => {
    const modifiedUrl = url.trim();
    const isValidUrl = checkUrl(modifiedUrl);
    if (!isValidUrl) return;
    try {
        if (modifiedUrl) {
            const auth_token = localStorage.getItem("auth_token");
            const title = await SharedApi.fetchTitle(auth_token, !modifiedUrl.startsWith("https://") ? `https://${modifiedUrl}` : modifiedUrl);
            return title?.data;
        } else {
            return {title: '', metaDescription: ''}
        }
    } catch (error) {
        console.error("Error fetching title:", error.message);
        return notFoundTitle;
    }
};

export default titleFetcher;
