import SocialDropdown from "./socialDropdown";
import isValidHttpUrl from "../../../utils/isValidHttpUrl";
import { useContext, useState } from "react";
import { LinkInBioContxt } from "../linkInBioContxt";

const SocialIcons = () => {
  const { bioPageForm } = useContext(LinkInBioContxt);
  const { watch, setValue } = bioPageForm;
  const { socials = [] } = watch();
  const [error, setError] = useState({
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
  });

  const isValidLink = (platform, link) => {
    let regexMap = {
      facebook: /^(http(s)?:\/\/)?(www\.)?facebook.com\/.+/,
      youtube: /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/,
      twitter: /^(http(s)?:\/\/)?(www\.)?twitter.com\/.+/,
      instagram: /^(http(s)?:\/\/)?(www\.)?instagram.com\/.+/,
    };
    return regexMap[platform]?.test(link);
  };

  const validateLink = (platform, link) => {
    if (!link) {
      setError((preState) => ({
        ...preState,
        [platform]: ``,
      }));
      return;
    }
    if (!isValidLink(platform, link)) {
      setError((preState) => ({
        ...preState,
        [platform]: `Please enter a valid ${platform} URL.`,
      }));
    } else {
      setError((preState) => ({
        ...preState,
        [platform]: "",
      }));
    }
  };

  const socialHandler = (id, values) => {
    const updatedSocials = socials?.map((item) => {
      let updatedItem = {};
      if (item?.id === id) {
        updatedItem = { ...item, ...values };
      } else {
        updatedItem = item;
      }
      updatedItem = {
        ...updatedItem,
        isValid: isValidLink(updatedItem?.id, updatedItem?.url),
      };
      return updatedItem;
    });
    setValue("socials", updatedSocials);
  };

  const newSocialHandler = () => {
    setValue("socials", [...socials, { url: "", social: "" }]);
  };

  const deleteSocialHandler = (id) => {
    setValue(
      "socials",
      socials?.filter((item) => item?.id !== id)
    );
  };

  const isBtnDisable =
    socials?.length === 4 ||
    socials?.some(
      (item) =>
        item?.url === "" || item?.id === "" || !isValidHttpUrl(item?.url)
    );

  return (
    <div className="mt-10">
      <h3 className="fw-bolder fs-2 mb-lg-3 mb-7">Social Icons</h3>
      <div className="border-lg p-lg-5 rounded-1">
        <div id="kt_docs_repeater_advanced">
          <div className="form-group">
            <div data-repeater-list="kt_docs_repeater_advanced">
              <div data-repeater-item>
                {socials?.map((social) => {
                  return (
                    <div
                      key={social?.id}
                      className="form-group gap-lg-0 gap-3 border-bottom-sm pb-5 pb-lg-0 row mb-5"
                    >
                      <div className="col-md-3">
                        <SocialDropdown
                          socials={socials}
                          item={social}
                          handler={(value) =>
                            socialHandler(social?.id, {
                              id: value,
                              url: social.url,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-8">
                        <input
                          name="url"
                          className="form-control rounded-1 h-50px"
                          type="text"
                          placeholder="Enter social media URL"
                          value={social?.url}
                          onBlur={() => validateLink(social?.id, social?.url)}
                          onChange={(e) =>
                            socialHandler(social?.id, {
                              id: social?.id,
                              url: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div
                        onClick={() => deleteSocialHandler(social?.id)}
                        className="col-md-1"
                      >
                        <button
                          type="button"
                          className="btn btn-icon rounded-1 border bg-light w-50px h-50px"
                        >
                          <i className="far text-danger fa-trash-can fs-3"></i>
                        </button>
                      </div>
                      <div className="d-flex justify-content-start mb-3">
                        {error[social?.id] !== "" ? (
                          <span className="error-text text-danger">
                            {error[social?.id]}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="form-group">
            <button
              disabled={isBtnDisable}
              type="button"
              onClick={newSocialHandler}
              className="btn btn-flex btn-light-info border border-gray-200 bg-hover-light-info border-opacity-25 rounded-1"
            >
              <i className="fa-solid fa-plus fs-3 me-2"></i>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialIcons;
