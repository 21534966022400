import SharedApi from "../services/http/shared";

const imageCopyHandler = (qr) => {
    const auth_token = localStorage.getItem("auth_token");
    SharedApi.disableCors(auth_token, { payload: `https:${qr}` })
        .then(async ({ data }) => {
            const arrayBuffer = Uint8Array.from(data?.data?.data).buffer;
            const blob = new Blob([arrayBuffer], {
                type: "image/png",
            });
            try {
                navigator.clipboard
                    .write([new ClipboardItem({ "image/png": blob })])
                    .then(() => { 
                        console.log("Image copied")
                    })
                    .catch((error) => {
                        console.error("Unable to copy image to clipboard", error);
                    });
            } catch (error) {
                console.error(error);
            }
        })
        .catch((error) => {
            console.log("Error while creating qr code: ", error);
        });
};

export default imageCopyHandler;