import dayjs from "dayjs";

const dateFormate = (date) => {
    let newDate = date;
    newDate = !newDate ? new Date() : new Date(newDate);

    const currentDate = dayjs();
    const inputDate = dayjs(newDate);

    if (currentDate.isSame(inputDate, 'year')) {
        return inputDate.format('MMM DD');
    } else {
        return inputDate.format('MMM DD, YYYY');
    }
}

export default dateFormate;