import * as Yup from 'yup';

export const customLinkValidation = Yup.object().shape({
    domain: Yup.string().required("Domain is required."),
    spare: Yup.string().oneOf(["Yes", "No"]).required(),
    subDomain: Yup.string().when(
        'spare', (spare, schema) => {
            if (spare[0] === 'No') {
                return Yup.string().required("Sub domain is required");
            }
            return Yup.string().notRequired();
        }
    )
});

