import { useContext } from "react";
import Theme1 from "../../../assets/media/link-in-bio/theme1.png";
import Theme2 from "../../../assets/media/link-in-bio/theme2.png";
import Theme3 from "../../../assets/media/link-in-bio/theme3.png";
import Theme4 from "../../../assets/media/link-in-bio/theme4.png";
import { LinkInBioContxt } from "../linkInBioContxt";

const THEMES = [
  {
    media: Theme1,
    name: "Theme 1",
    id: "theme1",
  },
  {
    media: Theme2,
    name: "Theme 2",
    id: "theme2",
  },
  {
    media: Theme3,
    name: "Theme 3",
    id: "theme3",
  },
  {
    media: Theme4,
    name: "Theme 4",
    id: "theme4",
  },
];

const Theme = () => {
  const { bioPageForm } = useContext(LinkInBioContxt);
  const { setValue } = bioPageForm;

  const themeHandler = (id) => {
    setValue("btnClr", "");
    setValue("button", "");
    setValue("font", "");
    setValue("descriptionClr", "");
    setValue("titleClr", "");
    setValue("fontClr", "");
    setValue("bg", "");
    setValue("bgType", "");
    setValue("bottomBg", "");
    setValue("bottomBgType", "");
    setValue("bgClr", "");
    // setValue("template","")
    setValue("theme", id);
  };
  return (
    <div className="mt-10">
      <h3 className="fw-bolder fs-2 mb-lg-3 mb-7">Themes</h3>
      <div className="border-lg p-lg-5 rounded-1">
        <div
          className="gap-lg-10 gap-5 w-100 theme-grids"
          style={{
            display: "inline-grid",
          }}
        >
          <a href="#customTheme" className="rounded-1 border-0 bg-white" onClick={() => {themeHandler(0);}}>
            <div className="h-100 d-flex justify-content-center align-items-center flex-column">
              <div
                className="position-relative h-100 rounded-1 w-100 mb-3 border-dashed d-flex flex-column justify-content-center"
                style={{ border: "1px solid #e9e6f1" }}
              >
                <i className="fa-solid fa-plus fs-3 text-b text-center"></i>
                <h3 className="lh-2 fs-4 text-gray-600 text-center">
                  Create <br /> your <br /> Own
                </h3>
              </div>
              <p className="fs-5 fw-semibold mb-lg-1 mb-0 text-black">Custom</p>
            </div>
          </a>

          {THEMES?.map((item) => {
            const { media, name, id } = item;
            return (
              <button
                type="button"
                onClick={() => themeHandler(id)}
                key={id}
                className="rounded-1 border-0 bg-white"
              >
                <img
                  className="rounded-1 mb-3 border border-2px w-100"
                  style={{ borderColor: "rgb(224, 226, 217)" }}
                  src={media}
                  alt=""
                />
                <p className="fs-5 mb-0 mb-lg-1 fw-semibold">{name}</p>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Theme;
