import NoTemplateFound from "../../../assets/media/link-in-bio/email-link-in-bio-qr-code.png";

const NoTemplate = () => {
  return (
    <div className="rounded-2 pe-7 text-center w-100 mobile-hide" id="deactivate-template">
      <div>
        <h3 className="fs-4 mb-2">Select Layout</h3>
        <span className="text-gray-500 fs-6">Lorem ipsum dolor sit amet. </span>
      </div>
      <img
        className="pt-15 d-block mx-auto"
        src={NoTemplateFound}
        alt="email-link-in-bio-qr-code"
      />
    </div>
  );
};

export default NoTemplate;
