import { useContext } from "react";
import Profile from "./profile";
import BillingInfo from "./billing";
import Users from "./users";
import { UserSettingsContxt } from "./userSettingContxt";
import UserSettingsTabs from "./tabs";

const UserSettings = () => {
  const { tab } = useContext(UserSettingsContxt);

  const tabMapper = {
    0: <Profile />,
    1: <BillingInfo />,
    2: <Users />,
  };

  return (
    <div
      className="card-body px-6 px-lg-10 pt-0 overflow-x-auto card-body"
      style={{ minHeight: "200px" }}
    >
      <div className="w-100 shortlinktableouterdiv overflow-x-auto">
        <UserSettingsTabs />
        <div className="content mt-lg-10 mt-5">
          <div className={"d-block"}>{tabMapper[tab]}</div>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
