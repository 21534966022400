import { useContext, useState } from "react";
import { Spinner, Tooltip } from "reactstrap";
import { AuthContxt } from "../../../store/authContxt";

const CreateQrHeader = ({ loading, isEdit }) => {
  const { quota } = useContext(AuthContxt);
  const isLimitReached = (quota?.qrCode === 0 && !isEdit) || false;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => {
    if (isLimitReached) {
      setTooltipOpen(!tooltipOpen);
    }
  };

  return (
    <div className="border-bottom px-0 d-flex justify-content-between align-items-center py-lg-10 py-5 w-100">
      <h2 className="fs-2x mb-0">QR Codes</h2>
      <div
        onMouseOver={toggle}
        onMouseLeave={toggle}
        id="qrCodeForm"
        className="card-toolbar d-flex justify-content-center align-items-center"
      >
        <button
          type={isLimitReached ? "button" : "submit"}
          disabled={loading || isLimitReached}
          className="btn btn-info rounded-1 w-lg-200px"
        >
          {loading ? (
            <span>
              Please wait... <Spinner className="h-15px w-15px" />
            </span>
          ) : (
            `${isEdit ? "Update" : "Save"} QR Code`
          )}
        </button>
      </div>
      <Tooltip
        placement={"top"}
        isOpen={tooltipOpen}
        target={"qrCodeForm"}
        toggle={toggle}
      >
        {`Your Monthly Qr Code Limit Reached. Please Upgrade your Plan`}
      </Tooltip>
    </div>
  );
};

export default CreateQrHeader;
