import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Input } from "reactstrap";
import Sidebar from "../common/sidebar";
import EmailCampaignAPI from "../../services/http/emailCampaign";
import { EmailCampaignContxt } from "./emailCampaignContxt";
import { useNavigate, useParams } from "react-router-dom";

const EditEmailCampaign = () => {
  const navigate = useNavigate();
  const { id: emailCampaignId } = useParams();
  const [name, setName] = useState("");
  
  const getEmailCampaign = async () => {
    console.log("hello")
    try {
        const auth_token = localStorage.getItem("auth_token");
        EmailCampaignAPI.getEmailCampaignsById(auth_token, emailCampaignId)
        .then(({ data }) => {
            setName(data.resultData.name);
          })
          .catch((error) => {
            console.log("Error while fetching shortlinks", error);
          })
          .finally(() => {
            
          });
    }   catch (e) {
        console.log("Error while loading email campaign : ", e.message);
    }
}
useEffect(() => {
  getEmailCampaign();
}, []);

  const nameHandler = (name) => {
    setName(name);
  }

  const updateEmailCampaign = async () => {
    try {
      const auth_token = localStorage.getItem("auth_token");
      EmailCampaignAPI.updateEmailCampaign(auth_token, emailCampaignId, {name})
      .then(({ data }) => {
        console.log("updated data", data);
        navigate(`/emailcampaigns`);
      })
      .catch((error) => {
        console.log("Error while fetching email campaign", error);
      })
      .finally(() => {
        
      });
    } catch (e) {
    console.log("Error while creating email campaign : ", e.message);
  }
};

  return (
    <div>
      <div className="w-full d-flex">
        <div className="mt-md-10 w-[280px] me-md-7 mt-xs-0 me-xs-0">
          <Sidebar />
        </div>
        <div className="customDashboardSize">
          <div className="d-flex flex-column w-100 flex-root app-root">
            <div className="app-page flex-column flex-column-fluid">
              <div className="app-wrapper flex-column flex-row-fluid pe-3 pe-lg-0 me-lg-10 mt-lg-10 mt-0 position-relative">
                <div className="app-main flex-column flex-row-fluid">
                  <Card className="card card-flush">
                    <div
                      className="card-body d-flex pt-0 mx-auto w-50 mt-50percent"
                      style={{ flex: "0 auto", marginTop: "200px" }}
                    >
                      <div
                        className="px-15 py-20 border rounded-1 w-100"
                        style={{ borderColor: "#f3f0fb !important" }}
                      >
                        <h2 className="me-15 fs-2x">Create Email Campaign</h2>
                        <p>
                          Discover the power of engaging your audience with
                          personalized Email campaigns. Leverage our
                          comprehensive tools to craft messages that resonate
                          with your customers.{" "}
                        </p>
                        <div
                          className="separator border-bottom my-10"
                          style={{ borderColor: "#f3f0fb !important" }}
                        ></div>
                        <div>
                          <label className="fw-bold fs-5 mb-2">
                            Campaign Name{" "}
                            <span className="text-gray-500">
                              {" "}
                              (only you can see it)
                            </span>
                          </label>
                          <Input
                            id="url-input"
                            name="url-input"
                            className="form-control rounded-1 h-50px"
                            placeholder="Example : 50% off Campaign"
                            onChange={(e) => nameHandler(e.target.value)}
                            value={name}
                          >
                          </Input>
                        </div>
                        <div className="d-flex gap-5 mt-6 justify-content-end">
                          <Link to="/emailcampaigns">
                            <button className="btn btn-light border border-2 rounded-1 border hover-elevate-up">
                              Cancel
                            </button>
                          </Link>
                          <button className="btn btn-info rounded-1" onClick={() => updateEmailCampaign()}>
                            Update Campaign
                          </button>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEmailCampaign;
