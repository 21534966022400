import dayjs from "dayjs";
import BarChart from "../../common/analytics/barChart";

const Chart = ({ date = [], ip = [] }) => {
  date.sort((a, b) => new Date(a.date) - new Date(b.date));
  const labels = date?.map((item) =>
    item?.date === null ? "others" : dayjs(item?.date).format("MMM DD")
  );

  const values = date?.map((item) => item?._count?._all);
  return (
    <BarChart categories={labels} data={values} type={'Views'} />
  );
};

export default Chart;
