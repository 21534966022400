import React from "react";
import { Container, ModalBody, Modal, ModalHeader } from "reactstrap";
import Copy from "../../common/copy";
import dateFormate from "../../../utils/dateFormat";

const DnsConfiguration = ({ isOpen, modalHandler, customLink }) => {
  const { domain, spare, subDomain, createdAt, status } = customLink;

  const urlBuilder = () => {
    try {
      const url = new URL(domain);
      return spare === "No" ? `${subDomain}.${url.host}` : url.host;
    } catch (e) {
      console.log("Error while creating url : ", e);
    }
  };

  const StatusMapper = {
    Pending: "pending-status-modal",
    Connected: "connected-status-modal",
    Rejected: "rejected-status-modal",
  };

  const StatusTextMapper = {
    Pending: "DNS not yet verified",
    Connected: "Connected",
    Rejected: "DNS rejected due to mis-match",
  };

  return (
    <Modal
      className="add_new_link_body mw-1000px"
      size="lg"
      isOpen={isOpen}
      toggle={modalHandler}
    >
      <ModalHeader toggle={modalHandler}>
        <div>
          <h2 className="fw-bold px-lg-0">{urlBuilder()}</h2>
          <div className="d-flex mt-5">
            <i className="fa-regular fa-calendar fs-3 me-2"></i>
            <p className="mb-0 cursor-pointer">{dateFormate(createdAt)}</p>
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="modal-body m-5">
        <Container>
          <div className="modalBody pb-20">
            <div
              id="statusDiv"
              className={`notice d-flex border-1 rounded align-items-center p-6 mb-10 ${StatusMapper[status]}`}
            >
              <i id="modal_icon" className="fa-light fs-1 me-4"></i>

              <div className="d-flex flex-stack flexGrow-1">
                <div id="status" className="fw-semibold">
                  <p id="verified-text" className="fs-5 fw-bold mb-0">
                    {StatusTextMapper[status]}
                  </p>
                  {status !== "Rejected" ? (
                    <p className="mb-0" id="extra-text">
                      Propagation can take up to 24 hours, we'll email you once
                      it's complete. Please ensure you've entered the correct{" "}
                      <span className="fwBolder textDark">A</span> records.{" "}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="pb-8 pb-lg-10 my-5">
              <h2 className="fwBold textDark fs-4">
                {" "}
                DNS Configuration{" "}
                <span className="fs-6 fw-light">( Expert Only )</span>
              </h2>

              <div className="text-muted fs-6">
                {" "}
                Apply the following DNS configuration to turn any domain into
                your own link shortener. All you have to do is “point” your{" "}
                <span className="textDark">A</span> Records to Divsly, and make
                sure that all pre-existing records of type{" "}
                <span className="fw-semibold text-dark">A, </span>
                <span className="fw-semibold text-dark">AAAA</span> or{" "}
                <span className="fw-semibold text-dark">CNAME</span> for{" "}
                <span className="fw-semibold text-dark" id="modal_domain_span">
                  {urlBuilder()}
                </span>{" "}
                are removed
              </div>
            </div>
            <div className="table-responsive pb-10">
              <table
                className="table table-rounded table-rowBordered border borderDashed borderDark gy-7 gs-7"
                style={{ borderColor: "rgba(0, 0, 0, 0.35) !important" }}
              >
                <thead>
                  <tr className="fw-semibold fs-6 textGray-800 borderBottom-2 borderGray-200">
                    <th> Record type </th>
                    <th> hostname(or name) </th>
                    <th> Points to(or address) </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="table-active rounded-2">
                    <td className="rounded-2"> A </td>
                    <td id="table_domain d-flex justify-content-center">
                      {spare === "No" ? subDomain : "@"}
                    </td>
                    <td className="rounded-2">
                      {process.env.REACT_APP_TARGET_IP}
                      <Copy
                        copyData={process.env.REACT_APP_TARGET_IP}
                        includeProtocol={false}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="separator"
              style={{ borderColor: "rgba(0,0, 0,0.2 ) !important;" }}
            ></div>
            <div className="pt-10 row">
              <div className="col-12">
                <h2 className="fwBold textDark pb-3 fs-4">
                  {" "}
                  Nameservers{" "}
                  <span
                    dataBs-toggle="tooltip"
                    aria-label="Nameservers determine where your DNS is hosted and where you add, edit or delete your DNS records."
                    dataBs-original-title="Nameservers determine where your DNS is hosted and where you add, edit or delete your DNS records."
                    data-kt-initialized="1"
                  >
                    <i className="kiDuotone ki-information ms-1">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                    </i>
                  </span>
                </h2>
              </div>
              <div className="col-12">
                <div className="w-100">
                  <div className="pt-3 ps-2 borderBottom border-top">
                    <p> ns13.domaincontrol.com </p>
                  </div>
                  <div className="py-3 ps-2 borderBottom">
                    <p className="mb-0"> ns14.domaincontrol.com </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default DnsConfiguration;
