import CountCard from "./countCard";
import UsersChart from "./usersChart";
import SalesChart from "./salesChart";
import { useEffect, useState } from "react";
import UserSettingsApi from "../../services/http/userSettings";
import Subscriptions from "./subscriptions";
import RecentSubscriptions from "./recentSubscriptions";
import DateRangeSelector from "./dateRange";
import dayjs from "dayjs";
import AdminAPI from "../../services/http/admin";
import convertToK from "../../utils/convertToK";
const AdminDashboard = () => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date().setDate(new Date().getDate() - 30),
    endDate: new Date(),
    key: "selection",
  });

  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState();

  const fetchStats = async (
    startDate = new Date().setDate(new Date().getDate() - 30),
    endDate = new Date()
  ) => {
    setLoading(true);
    try {
      const statsResponse = await AdminAPI.getStats({ startDate, endDate });
      if (!statsResponse?.data?.error) {
        setStats(statsResponse?.data?.data || {});
      }
      setLoading(false);
    } catch (e) {
      console.log("Error while fetching stats: ", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  let qrCount = 0;
  let linksCount = 0;

  stats?.links?.forEach((link) => {
    if (link.type === "qr") {
      qrCount += link._count._all;
    } else {
      linksCount += link._count._all;
    }
  });

  return (
    <div className="card card-flush rounded-sm-0 card">
      <div className="px-10 py-10 d-flex justify-content-between w-100">
        <h2 className="me-15 fs-2x">Dashboard</h2>
        <div class="card-toolbar">
          <div className="mt-5 mt-lg-0">
            <div
              onClick={() => setOpenDatePicker(!openDatePicker)}
              class="form-control form-control-solid w-lg-250px justify-content-between"
              style={{
                position: "relative",
              }}
            >
              <span>
                {dayjs(selectedDateRange.startDate).format("MM/DD/YYYY")}
              </span>
              <span>{` - `}</span>
              <span>
                {dayjs(selectedDateRange.endDate).format("MM/DD/YYYY")}
              </span>
            </div>
            {openDatePicker ? (
              <div
                style={{
                  position: "absolute",
                  zIndex: 99999,
                  right: 0,
                }}
              >
                <DateRangeSelector
                  analyticsHandler={fetchStats}
                  toggleHandler={setOpenDatePicker}
                  selectedDateRange={selectedDateRange}
                  setSelectedDateRange={setSelectedDateRange}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div class="card-body border-top pt-10">
        <div class="row g-5 g-xl-10 mb-xl-10">
          <CountCard
            stats={{
              type: "user",
              count: convertToK(stats?.users?.totalUsers || 0),
            }}
          />
          <CountCard
            stats={{
              type: "account",
              count: convertToK(stats?.users?.totalAccounts || 0),
            }}
          />
          <CountCard
            stats={{ type: "links", count: convertToK(linksCount || 0) }}
          />
          <CountCard
            stats={{
              type: "qrCodes",
              count: convertToK(qrCount || 0),
            }}
          />
          <CountCard
            stats={{
              type: "revenue",
              count: `$${convertToK(+stats?.revenue || 0)}`,
            }}
          />

          <UsersChart />
          <div className="col-lg-6" style={{ height: "320px" }}>
            <Subscriptions />
            <RecentSubscriptions />
          </div>
          <SalesChart />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
