import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TermsImage from "../../assets/media/acceptTerms.jpg";
import TimelineComponent from './proileCompletion'; // Import your timeline component here

const AcceptTerms = () => {
    return (
        <Container fluid className='bg-white overflow-hidden px-0'>
            <Row className="d-flex align-items-center">
                {/* Left Side Image */}
                <Col md={6} className="text-center px-5">
                    <img src={TermsImage} alt="Left Side Image" className="img-fluid mb-5" />
                </Col>

                {/* Right Side Timeline Component */}
                <Col md={6}>
                    <div className='mx-auto w-75'>
                        <TimelineComponent /> {/* Insert TimelineComponent here */}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default AcceptTerms;
