import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Col,
  Row,
  InputGroup,
  InputGroupText,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";
import Sidebar from "../../components/common/sidebar";
import EmptyState from "../../assets/media/empty-state-link-list.png";
import IntegrationsTab from "./MyAllIntegrations"


const SORTS = [
  { label: "A - Z", value: "a-z" },
  { label: "Z - A", value: "z-a" },
  { label: "Oldest", value: "oldest" },
  { label: "Latest", value: "latest" },
  { label: "Most Clicked", value: "mostClicked" },
];

const Integrations = () => {
  const [isActive, setIsActive] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState({});

  // Function to toggle the search input visibility
  const toggleSearch = () => {
    setSearchVisible(!searchVisible);
  };

 

  // Handling filter changes
  const filtersHandler = (key, value) => {
    setFilters((prevState) => ({ ...prevState, [key]: value }));
  };

  return (
    <div>
      <div className="w-full d-flex">
        <div className="mt-md-10 w-[280px] me-md-7 mt-xs-0 me-xs-0">
          <Sidebar />
        </div>
        <div className="customDashboardSize">
          <div className="d-flex flex-column w-100 flex-root app-root">
            <div className="app-page flex-column flex-column-fluid">
              <div className="app-wrapper flex-column flex-row-fluid pe-3 pe-lg-0 me-lg-10 mt-lg-10 mt-0 position-relative">
                <div className="app-main flex-column flex-row-fluid">
                  <Card className="card card-flush">
                    <div className="px-6 px-lg-10 py-lg-10 py-5 d-flex justify-content-between align-items-center">
                      <h2 className="me-15 fs-2x">Integrations</h2>
                      <div className="card-toolbar d-flex align-items-center gap-5">
                        <InputGroup>
                          <Input
                            placeholder="Search..."
                            className={`fw-normal form-control ${searchVisible ? "" : "d-none"}`}
                          />
                          <InputGroupText
                            className="btn btn-icon rounded-1 bg-light-info me-2"
                            onClick={toggleSearch}
                          >
                            <i className="fa-solid fa-magnifying-glass fs-3"></i>
                          </InputGroupText>
                        </InputGroup>
                        <UncontrolledDropdown className="d-inline nospace">
                          <DropdownToggle
                            className="btn btn-icon bg-light-info rounded-1"
                            title="Sort By"
                            variant="bg-light"
                          >
                            <i className="fa-solid fa-arrow-up-wide-short text-info fs-3"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            {SORTS.map((item) => (
                              <DropdownItem
                                key={item.value}
                                onClick={() => filtersHandler("sort", item.value)}
                              >
                                {item.label}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                    <CardBody className="pt-0">
                      <IntegrationsTab/>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  );
};

export default Integrations;
