import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { Spinner } from "reactstrap";
import UserSettingsApi from "../../../services/http/userSettings";
import { FormGroup, Label, Input } from "reactstrap";
import { AccountType, EMAIL_REGEX, PASSWORD_REGEX } from "../../../utils/enum";

const updateEmailValidation = yup.object().shape({
  email: yup
    .string()
    .required("Please fill this field!")
    .matches(EMAIL_REGEX, "Email must be valid email!"),
  password: yup
    .string()
    .required("Please fill this field!")
    .matches(
      PASSWORD_REGEX,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
});

const Email = ({ edittedProfile = null, profileHandler }) => {
  const [showForm, setShowForm] = useState(0);

  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: yupResolver(updateEmailValidation),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const {
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
    trigger,
  } = form;

  const resetErrorMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 5 * 1000);
  };

  const changeEmailHandler = () => {
    if (edittedProfile?.method !== AccountType.GOOGLE) {
      edittedProfile && reset({ email: edittedProfile?.email, password: "" });
      setShowForm(true);
    }
  };

  const submitHandler = async (data) => {
    if (data) {
      try {
        setLoading(true);
        const response = await UserSettingsApi.updateEmail(data);
        if (response?.data?.error) {
          setMessage(response.data?.message || "Something went wrong");
          setLoading(false);
        } else {
          profileHandler(data);
          setLoading(false);
          reset();
          setShowForm(false);
        }
        resetErrorMessage();
      } catch (e) {
        setMessage(e?.response?.data?.message || "Something went wrong");
        setLoading(false);
        resetErrorMessage();
        console.log("Error while updating password: ", e);
      }
    }
  };

  return (
    <div className="d-flex flex-wrap align-items-center">
      <div
        className={
          showForm ? "flex-row-fluid d-none" : "flex-row-fluid d-block"
        }
      >
        <div className="fs-6 fw-bold mb-1">Email Address</div>
        <div className="fw-semibold text-gray-600">{edittedProfile?.email}</div>
      </div>

      <div
        className={
          showForm ? "flex-row-fluid d-block" : "flex-row-fluid d-none"
        }
      >
        <form
          id="kt_signin_change_email"
          className="form fv-plugins-bootstrap5 fv-plugins-framework"
          onSubmit={handleSubmit(submitHandler)}
        >
          <div className="row mb-6">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="fv-row mb-0 fv-plugins-icon-container">
                <FormGroup>
                  <Label className="fs-5 fw-bold mb-2">
                    Enter New Email Address
                  </Label>
                  <Controller
                    name={"email"}
                    control={control}
                    render={({ field: { onBlur, value } }) => (
                      <Input
                        value={value}
                        type="email"
                        className="form-control form-control-lg border rounded-1 form-control-solid "
                        placeholder="Email Address"
                        onChange={(e) => {
                          setValue("email", e.target.value);
                          trigger("email");
                        }}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </FormGroup>
                {errors.email ? (
                  <div style={{ textAlign: "end" }}>
                    <span className="error-text text-danger">
                      {errors.email.message}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="fv-row mb-0 fv-plugins-icon-container">
                <FormGroup>
                  <Label className="fs-5 fw-bold mb-2">Password</Label>
                  <Controller
                    name={"password"}
                    control={control}
                    render={({ field: { onBlur, value } }) => (
                      <Input
                        value={value}
                        type="password"
                        className="form-control form-control-lg border rounded-1 form-control-solid "
                        placeholder="Password"
                        onChange={(e) => {
                          setValue("password", e.target.value);
                          trigger("password");
                        }}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </FormGroup>
                {errors.password ? (
                  <div style={{ textAlign: "end" }}>
                    <span className="error-text text-danger">
                      {errors.password.message}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div
            style={{
              textAlign: "start",
            }}
          >
            {message ? (
              <span className="text-danger fs-6 fw-bold">{message}</span>
            ) : null}
          </div>

          <div className="d-flex">
            <button
              type="button"
              onClick={() => setShowForm(false)}
              className="btn btn-color-gray-500 border rounded-1 me-4 btn-active-light-info px-6"
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-info rounded-1 px-6">
              {loading ? <Spinner /> : "Update Email"}
            </button>
          </div>
        </form>
      </div>

      <div className="ms-auto">
        <button
          className={`btn btn-light btn-active-light-info rounded-1 ${
            showForm ? "d-none" : "d-block"
          }`}
          onClick={changeEmailHandler}
          disabled={edittedProfile?.method === AccountType.GOOGLE}
        >
          Change Email
        </button>
      </div>
    </div>
  );
};

export default Email;
