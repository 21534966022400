import httpMethods from "..";

const QrCodesAPI = {
  getQrCodes: async (token) => {
    return httpMethods
      .get(`/qr-code`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },
  getQrCode: async (token, params) => {
    return httpMethods
      .get(`/qr-code/${params}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },
  getQrCodesAnalytics: async (token, payload) => {
    return httpMethods
      .post(`/qr-code-analytics`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },
  getCustomLinks: async (token) => {
    return httpMethods
      .get(`/connected-custom-domain`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },

  filteredQrCodes: async (token, params) => {
    return httpMethods
      .get(`/filtered-qr-code`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },

  deleteQrCode: async (token, id) => {
    return httpMethods
      .delete(`/qr-code/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  createQrCode: async (token, values) => {
    return httpMethods
      .post(`/qr-code`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  updateQrCode: async (token, id, values) => {
    return httpMethods
      .put(`/qr-code/${id}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  updateQrCodeCustomization: async (token, id, values) => {
    return httpMethods
      .put(`/qr-code-update/${id}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },


  getDestinationUrl: async (payload) => {
    return httpMethods
      .post(`/qr-code-url`, payload)
      .then((res) => res);
  },

  isSlashTagValid: async (token, payload) => {
    return httpMethods
      .post(`/valid-qr-code`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  generateQrCode: async (token, payload) => {
    return httpMethods
      .post(`/generate-qr-code`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  downloadQrCode: async (token, payload) => {
    return httpMethods
      .post(`/download-image`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  uploadQrCodeLogo: async (token, payload) => {
    return httpMethods
      .post(`/qr-code-logo`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

};

export default QrCodesAPI;
