import { useContext, useState } from "react";
import example1 from "../../../assets/media/link-in-bio/examples/layout-1.svg";
import example2 from "../../../assets/media/link-in-bio/examples/layout-2.svg";
import example3 from "../../../assets/media/link-in-bio/examples/layout-3.svg";
import example4 from "../../../assets/media/link-in-bio/examples/layout-4.svg";

import CreatePageModal from "./create-page-modal";
import { LinkInBioContxt } from "../linkInBioContxt";
import createImageFromInitials, {
  getRandomColor,
} from "../../../utils/imageFromUsername";
import generateProfileImage from "../../../utils/generateProfileImage";

const EXAMPLES = [
  { id: 1, templateDemo: example1 },
  { id: 2, templateDemo: example2 },
  { id: 3, templateDemo: example3 },
  { id: 4, templateDemo: example4 },
];

const Templates = () => {
  const {
    bioPageForm: { setValue, watch },
    screenHandler,
  } = useContext(LinkInBioContxt);
  const { template } = watch();
  const [showModal, setShowModal] = useState(false);

  const templateHandler = (value) => {
    console.log(template)
    setValue("template", value);
  };

  const createPageDataHandler = (data) => {
    setValue("pageDomain", data?.pageDomain);
    setValue("pageLink", data?.pageLink);
    const color = getRandomColor();
    setValue("avatar", generateProfileImage(data?.pageLink, 200, color));
    screenHandler(2);
  };

  return (
    <>
      <div className="w-100 position-relative" id="activate-template">
        <div className="pb-15 pb-lg-0">
          <div>
            <h3 className="fs-4 ms-2 mt-lg-0 mt-8 mb-5">Select Layout</h3>
          </div>
          <div
            className="gap-lg-10 gap-6 w-100 position-relative"
            style={{
              display: "inline-grid",
              gridTemplateColumns: "repeat(auto-fit,minmax(120px,1fr))",
            }}
          >
            <div
              id="float-temp-button"
              className="position-fixed bg-white p-5 px-lg-10 px-6 bottom-0 justify-content-center floatsetupbutton"
              style={{
                display: template !== "" ? "flex" : "none",
              }}
            >
              <button
                type="buton"
                onClick={() => setShowModal(true)}
                className="btn btn-info h-50px rounded-1 w-100"
              >
                Setup Link in Bio
              </button>
            </div>

            <button
              className="rounded-4 border-0 bg-white"
              type="button"
              onClick={() => {
                templateHandler(0);
                setValue("bg", "")
                setShowModal(true);
              }}
            >
              <div className="h-100 d-flex flex-column">
                <div className="position-relative h-100 rounded-4 w-100 mb-lg-3 border-dark border-1 border-opacity-50 border-dashed d-flex flex-column justify-content-center">
                  <i className="fa-solid fa-plus text-gray-600 fs-1 mb-3"></i>
                  <h3 className="lh-2 fs-4 text-gray-600">
                    Create <br /> your <br /> Own
                  </h3>
                </div>
              </div>
            </button>

            {EXAMPLES?.map((item, index) => {
              return (
                <button key={index} className="border-0 bg-white p-0">
                  <img
                    className="mb-lg-3 w-100 activate-cat"
                    onClick={() => templateHandler(item?.id)}
                    style={{
                      border:
                        template === item?.id
                          ? "1px solid rgb(196 172 245)"
                          : "1px solid gainsboro",
                      padding: "5px",
                      borderRadius: "18px",
                    }}
                    src={item?.templateDemo}
                    alt=""
                  />
                </button>
              );
            })}
          </div>
        </div>
      </div>
      {showModal ? (
        <CreatePageModal
          toggleHandler={() => setShowModal(false)}
          valuesHandler={createPageDataHandler}
        />
      ) : null}
    </>
  );
};

export default Templates;
