import Info from "./billing-info/info";
import Activity from "./billing-info/activity";
import Billing from "./billing-info/billing";
import PaymentMethods from "./payment-methods";

const Billinginfo = () => {
  return (
    <>
      <Info />
      <Activity />
      <PaymentMethods />
      <Billing />
    </>
  );
};

export default Billinginfo;
