import React, { useContext, useEffect, useState } from 'react';
import imageclap from '../assets/media/onboarding/getting-starated-image.svg';
import shotlinkArrow from '../assets/media/onboarding/create-shortlink-arrow.svg';
import shotlinkImage from '../assets/media/onboarding/create-shortlink-image.svg';
import qrArrow from '../assets/media/onboarding/qr-arrow.svg';
import qrImage from '../assets/media/onboarding/qr-image.svg';
import bioArrow from '../assets/media/onboarding/link-in-bio-arrow.svg';
import bioImage from '../assets/media/onboarding/link-in-bio-image.svg';
import whileLogo from '../assets/media/onboarding/divsly-w-w.svg';
import { useNavigate } from 'react-router-dom';
import { AuthContxt } from '../store/authContxt';
import UserSettingsApi from '../services/http/userSettings';
import { extractNameFromEmail } from "../utils/extractName";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const CardComponent = () => {
  const { fetchProfile, profile } = useContext(AuthContxt);
  const navigate = useNavigate();
  const [Profile, setProfile] = useState();
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlideChange = (index) => {
    setCurrentIndex(index);
  };

  const { firstName = "", lastName = "", email = "" } = profile || {};
  const name = `${firstName ? firstName : ""} ${lastName ? lastName : ""}`.trim() || extractNameFromEmail(email);

  const SkipDemo = async (demo) => {
    handleSlideChange(currentIndex + 1);
    setLoading(true);
    try {
      const response = await UserSettingsApi.skipDemo({ skip: demo });
      setProfile(response?.data?.data || {});
      fetchProfile();

      // Move to the next slide after skipping
    } catch (e) {
      console.log('Error while skipping demo: ', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setProfile(profile);
  }, [profile]);
  console.log(Profile?.demoTour1)

  return (
    <div className="px-0">
      <div className="main-content d-flex align-items-center justify-content-start">
        <div className="left-cards me-auto mt-5 w-40" style={{ width: '23%' }}>
          <Carousel
            showArrows={false}
            showStatus={false}
            showIndicators={true}
            infiniteLoop
            showThumbs={false}
            selectedItem={Profile?.demoTour1==false ? 0: Profile?.demoTour2 == false ? 1: 2 }
            onChange={handleSlideChange}
          >
            <div className={`small-card bg-light p-5 mb-4 rounded ${Profile?.demoTour1 ? "active" : "inactive"}`}>
              <img src={shotlinkImage} alt="shortlink-image" className="shortlinkarrow_image" />
              <h2 className="mt-5 mb-4">Creating Shortlinks</h2>
              <p>Click the Create button to make your first shortlink. Experience the ease of generating shortlinks effortlessly!</p>
              <div className="button-group d-flex justify-content-between my-5">
                <button
                  className="btn btn-secondary bg-none me-4"
                  disabled={Profile?.demoTour1}
                  onClick={() => { SkipDemo("demoTour1"); }}
                >
                  Skip
                </button>
                <button
                  className="btn btn-info"
                  disabled={Profile?.demoTour1}
                  onClick={() => { navigate("/short-links",{state:{openModal:false}}); }}
                >
                  Create
                </button>
              </div>
            </div>

            <div className={`small-card bg-light p-5 mb-4 rounded ${Profile?.demoTour2 ? "active" : "inactive"}`}>
              <img src={qrImage} alt="shortlink-image" className="shortlinkarrow_image" />
              <h2 className="mt-5 mb-4">QR Codes</h2>
              <p>Create your first QR code easily with Divsly. Simplify your sharing process effortlessly today!</p>
              <div className="button-group d-flex justify-content-between my-5">
                <button
                  className="btn btn-secondary bg-none me-4"
                  disabled={Profile?.demoTour2}
                  onClick={() => { SkipDemo("demoTour2"); }}
                >
                  Skip
                </button>
                <button
                  className="btn btn-info"
                  disabled={Profile?.demoTour2}
                  onClick={() => { navigate("/qr-codes",{state:{openModal:false}}); }}
                >
                  Create
                </button>
              </div>
            </div>

            <div className={`small-card bg-light p-5 mb-4 rounded ${Profile?.demoTour3 ? "active" : "inactive"}`}>
              <img src={bioImage} alt="shortlink-image" className="shortlinkarrow_image" />
              <h2 className="mt-5 mb-4">Link in Bio</h2>
              <p>Create your first link in bio page with Divsly. Simplify your sharing process effortlessly today!</p>
              <div className="button-group d-flex justify-content-between my-5">
                <button
                  className="btn btn-secondary bg-none me-4"
                  disabled={Profile?.demoTour3}
                  onClick={() => { SkipDemo("demoTour3"); }}
                >
                  Skip
                </button>
                <button
                  className="btn btn-info"
                  disabled={Profile?.demoTour3}
                  onClick={() => { navigate("/link-in-bio",{state:{openModal:false}}); }}
                >
                  Create
                </button>
              </div>
            </div>
          </Carousel>

          {currentIndex === 0 && (
            <img src={shotlinkArrow} alt="shortlinarrow" className="shortlinkarrow_onboard" />
          )}
          {currentIndex === 1 && (
            <img src={qrArrow} alt="shortlinarrow" className="qrarrow_onboard" />
          )}
          {currentIndex === 2 && (
            <img src={bioArrow} alt="shortlinarrow" className="bioarrow_onboard" />
          )}
        </div>
        <div className="large-card ms-5 d-flex flex-column align-items-start mt-5 ms-auto p-5 bg-light rounded w-60" style={{ width: '30%', background: '#490aa8' }}>
          <div className="image-clap">
            <img src={whileLogo} alt="iamge clapping" className="w-100 p-4" />
          </div>
          <h5 style={{ fontSize: '20px', fontWeight: 'bold' }} className="ps-4">Welcome to <span style={{ color: 'red' }}>{name}</span></h5>
          <div className="image-clap">
            <img src={imageclap} alt="iamge clapping" className="w-100" />
          </div>
          <div className="ms-5">
            <h4 style={{ fontSize: '32px', marginTop: '-80px', fontWeight: 'bold' }}>Let's Set Up Initial Preferences</h4>
            <p style={{ fontSize: '18px', padding: '15px 0px 4px' }}>
              Start Using Divsly
            </p>
            <p style={{ fontSize: '18px', padding: '0px 0px 4px' }}>
              Creating Shortlinks
            </p>
            <p style={{ fontSize: '18px', padding: '0px 0px 4px' }}>
              Customized QR Codes
            </p>
            <p style={{ fontSize: '18px', padding: '0px 0px 4px' }}>
              Creating Link-in-bio Page
            </p>
            <p style={{ fontSize: '18px', padding: '0px 0px 4px' }}>
              Custom Domain Basics
            </p>
          </div>
          <a href="https://help.divsly.com/kb/" className="button-clas" target="_blank" rel="noopener noreferrer">
            Know more about Divsly
          </a>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
