import PaymentMethods from "./paymentMethods";

const Index = () => {
  return (
    <>
      <PaymentMethods />
    </>
  );
};

export default Index;
