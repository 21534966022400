import { useContext } from "react";
import { AuthContxt } from "../../../store/authContxt";
import { UserSettingsContxt } from "../userSettingContxt";

const Info = () => {
  const { tabHandler, setEdit } = useContext(UserSettingsContxt);
  const { profile } = useContext(AuthContxt);
  const {
    firstName = "",
    lastName = "",
    mobile = "",
    email = "",
    avatar,
    plan,
  } = profile || {};

  const name = `${firstName !== null ? firstName : ""} ${
    lastName !== null ? lastName : ""
  }`;

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-body py-lg-9 pt-5 pb-2 px-5 px-lg-10 position-relative">
        <div className="d-flex flex-wrap">
          <div className="me-7 pb-lg-0 pb-5">
            <div className="symbol symbol-100px symbol-lg-150px symbol-fixed position-relative">
              <img src={avatar || "/placeholder.png"} alt={name} />
            </div>
          </div>

          <div className="flex-grow-1 pe-lg-0">
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <span className="text-gray-900 fs-2 fw-bold me-1">
                    {name}
                  </span>
                  <span>
                    <i className="ki-duotone ki-verify fs-1 text-info">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                  </span>

                  {plan?.includes("starter") ? (
                    <span className="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3">
                      Upgrade to Pro
                    </span>
                  ) : null}
                </div>

                {mobile ? (
                  <div className="d-flex flex-wrap fw-semibold fs-6 pe-2">
                    <span className="d-flex align-items-center text-gray-500 mb-2">
                      <i className="fa-light fa-mobile fs-4 me-2"></i>
                      {mobile}
                    </span>
                  </div>
                ) : null}

                <div className="d-flex flex-wrap fw-semibold fs-6 mb-lg-4 mb-0 pe-2">
                  <span className="d-flex align-items-center text-gray-500 mb-lg-2">
                    <i className="fa-light fa-envelope fs-4 me-2"></i>
                    {email}
                  </span>
                </div>
              </div>

              <div
                className="d-flex my-4 mobile-hide-f"
                onClick={() => {tabHandler(0); setEdit(true)}}
              >
                <span className="btn btn-sm btn-light-info rounded-1 fw-semibold me-2">
                  Edit
                </span>
              </div>
              {/* 
              <div className="d-flex my-4 position-absolute desktop-hide-f" 
              style={{top:"3px",right:"10px"}}
              >
                <span className="btn btn-sm btn-light-info rounded-1 fw-semibold me-2">
                  Edit
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
