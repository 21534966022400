import httpMethods from "..";

const EmailCampaignAPI = {
  createEmailCampaign: async (token, values) => {
    return httpMethods
      .post(`/email-campaigns`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getAllEmailCampaigns: async (token, params) => {
    return httpMethods
      .get(`/email-campaigns`, {
        params,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },

  updateEmailCampaign: async (token, id, values) => {
    return httpMethods
      .put(`/email-campaigns/${id}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  deleteEmailCampaign: async (token, id) => {
    return httpMethods
      .delete(`/email-campaigns/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  getEmailCampaignsById: async (token, id) => {
    return httpMethods
      .get(`/email-campaigns/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },

  scheduleEmailCampaign: async (token, id, values) => {
    return httpMethods
      .post(`/email-campaigns/${id}/schedule`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res);
  },

  sendEmailCampaign: async (token, id) => {
    return httpMethods
      .post(
        `/email-campaigns/${id}/send`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => res);
  },

  sendEmailCampaign2: async (token, id, values) => {
    return httpMethods
      .post(
        `/email-campaigns/${id}/sendEmail`,
        values,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => res);
  },

  sendPreviewEmail: async (token, id, body) => {
    return httpMethods
      .post(
        `/email-campaigns/${id}/sendPreviewEmail`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => res);
  },

  getReports: async (token, id) => {
    return httpMethods
      .get(
        `/email-campaigns/reports/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => res);
  },

};

export default EmailCampaignAPI;
