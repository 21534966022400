import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import anaylytics from "../../assets/media/icons/analytics.svg";
import arrow from "../../assets/media/icons/arrow.svg";
import emailIcon from "../../assets/media/icons/email-icon.svg";
import ingegrationIcon from "../../assets/media/icons/ingegration-icon.svg";
import qrCode from "../../assets/media/icons/qr-code.svg";
import smsEmailIcon from "../../assets/media/icons/sms-email-icon.svg";
import tick from "../../assets/media/icons/tick.svg";
import urlIcon from "../../assets/media/icons/url-icon.svg";
import whatsapp from "../../assets/media/icons/whatsapp.svg";
import url from "../../assets/media/icons/url.svg";
import customDomain from "../../assets/media/icons/custom-domain.svg";
import loginEmptyState from "../../assets/media/sign-in-empty-state.png";
import whiteLogo from "../../assets/logos/divsly-w-icon.svg";
import colorlogo from "../../assets/logos/divsly-icon-c.svg";
import "react-toastify/dist/ReactToastify.css";
import elemntDot from "../../assets/media/element-dot.png";
import elemntDots from "../../assets/media/element-dots.png";
import elementCross from "../../assets/media/element-cross.png";
import elemntSquare from "../../assets/media/element-squre.png";
import elemntLines from "../../assets/media/element-lines.png";
import elemntCurve from "../../assets/media/element-curve.png";

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 1000,
  arrows: false,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const AuthPageWrapper = ({ children }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center w-100"
      style={{ height: "100vh" }}
    >
      <div className="floating-icons">
        <img src={anaylytics} alt="floating-icons" />
        <img src={arrow} alt="floating-icons" />
        <img src={emailIcon} alt="floating-icons" />
        <img src={ingegrationIcon} alt="floating-icons" />
        <img src={qrCode} alt="floating-icons" />
        <img src={smsEmailIcon} alt="floating-icons" />
        <img src={tick} alt="floating-icons" />
        <img src={urlIcon} alt="floating-icons" />
        <img src={whatsapp} alt="floating-icons" />
        <img src={url} alt="floating-icons" />
        <img src={customDomain} alt="floating-icons" />
      </div>
      <div className="d-flex flex-column h-fit-content m-lg-auto login_main_container">
        <a href="/login" className="desktop-hide-f ps-6 pt-6">
          <img alt="Logo" src={colorlogo} className="h-40px" />
        </a>
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-auto w-xl-50 position-xl-relative">
            <div
              className="d-flex flex-column justify-content-center bg-brand h-100 position-xl-relative mobile-hide-f"
              style={{ borderRadius: "25px 0px 0px 25px", width: "95%;" }}
            >
              <div className="floating-elements">
                <img src={elemntDot} alt="floating-dot" />
                <img src={elemntDots} alt="floating-element-dots" />
                <img src={elementCross} alt="floating-element-x-mark" />
                <img src={elemntSquare} alt="floating-element-square" />
                <img src={elemntLines} alt="floating-element-lines" />
                <img src={elemntDot} alt="floating-dot" />
                <img src={elementCross} alt="floating-element-x-mark" />
                <img src={elemntCurve} alt="floating-element-curve" />
              </div>
              <a href="/login" className="p-10 pt-0 mb-10">
                <img alt="Logo" src={whiteLogo} className="h-40px" />
              </a>

              <div className="d-flex justify-content-start h-50 flex-column text-left">
                <div>
                  <img
                    className="w-75 mx-auto d-block"
                    src={loginEmptyState}
                    alt="Sign in"
                  />
                </div>
              </div>
              <div className="my-10">
                <Slider {...settings}>
                  <div className="testimonial-container">
                    <h4 className="text-white fs-3x">Speady, Easy and Fast</h4>
                    <p className="fw-light text-white">
                      Divsly's link shortener, QR code generator, and link
                      management have redefined our strategies.
                    </p>
                  </div>
                  <div className="testimonial-container">
                    <h4 className="text-white fs-3x">Speady, Easy and Fast</h4>
                    <p className="fw-light text-white">
                      Divsly's link shortener, QR code generator, and link
                      management have redefined our strategies.
                    </p>
                  </div>
                  <div className="testimonial-container">
                    <h4 className="text-white fs-3x">Speady, Easy and Fast</h4>
                    <p className="fw-light text-white">
                      Divsly's link shortener, QR code generator, and link
                      management have redefined our strategies.
                    </p>
                  </div>
                  <div className="testimonial-container">
                    <h4 className="text-white fs-3x">Speady, Easy and Fast</h4>
                    <p className="fw-light text-white">
                      Divsly's link shortener, QR code generator, and link
                      management have redefined our strategies.
                    </p>
                  </div>
                  <div className="testimonial-container">
                    <h4 className="text-white fs-3x">Speady, Easy and Fast</h4>
                    <p className="fw-light text-white">
                      Divsly's link shortener, QR code generator, and link
                      management have redefined our strategies.
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
          </div>

          <div
            className="d-flex flex-column flex-lg-row-fluid py-lg-10 pb-15"
            style={{ borderRadius: "0px 25px 25px 0px" }}
          >
            <div className="d-flex flex-center flex-column flex-column-fluid">
              <div className="w-100 p-0 p-lg-15 ps-lg-20 pe-lg-20 mx-auto">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPageWrapper;
