import PageWrapper from "../../components/wrapper/pageWrapper";
import UserDashboard from "../../components/user-dashboard";
const Page = () => {
  return (
    <PageWrapper>
      <UserDashboard />
    </PageWrapper>
  );
};

export default Page;
