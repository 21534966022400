import PageWrapper from "../../components/wrapper/pageWrapper";
import LinkInBioForm from "../../components/link-in-bio/linkInBioForm";
import LinkInBioContxtProvider from "../../components/link-in-bio/linkInBioContxt";
import ShortLinkContxtProvider from "../../components/short-links/shortLinkContxt";
const CreateLinkInBio = () => {
  return (
    <PageWrapper>
      <LinkInBioContxtProvider>
        <ShortLinkContxtProvider>
          <LinkInBioForm screen={1} />
        </ShortLinkContxtProvider>
      </LinkInBioContxtProvider>
    </PageWrapper>
  );
};

export default CreateLinkInBio;
