const CardNote = ({ formHandler }) => {
  return (
    <>
      <div className="col-xl-6">
        <div className="notice d-flex rounded border-info border border-dashed flex-stack p-6" style={{backgroundColor:"#fcf8ff"}}>
          <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
            <div className="mb-3 mb-md-0 fw-semibold">
              <h4 className="text-gray-900 fw-bold">
                This is a very important note!
              </h4>

              <div className="fs-6 text-gray-700 pe-7">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Quibusdam, quasi!
              </div>
            </div>

            <button
              className="btn btn-info px-6 align-self-center rounded-1 text-nowrap"
              type="button"
              onClick={formHandler}
            >
              New Card
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardNote;
