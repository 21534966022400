const contentMapper = {
  user: {
    icon: "fa-user",
    heading: "Total Users",
    text: "text-info",
  },
  account: {
    icon: "fa-file-user",
    heading: "Total Accounts",
    text: "text-warning",
  },
  links: {
    icon: "fa-link",
    heading: "Total Links Created",
    text: "text-primary",
  },
  qrCodes: {
    icon: "fa-qrcode",
    heading: "Total QR Codes",
    text: "text-success",
  },
  revenue: {
    icon: "fa-dollar",
    heading: "Total Revenue",
    text: "text-success",
  },
};
const CountCard = ({ stats }) => {
  const { type, count } = stats;
  const { icon, heading, text } = contentMapper[type];
  return (
    <div class="col-md-6 col-lg-6 col-xl-6 col-xxl mb-md-5 mb-xl-10">
      <div class="card card-xxl-stretch-50 h-100 mb-5 mb-xl-8">
        <div class="card-body d-flex flex-column position-relative justify-content-between p-0">
          <div class="d-flex flex-column gap-3 px-9 pt-9">
            <div class="pb-5 border-bottom">
              <span>
                <i class={`fal ${icon} ${text} fs-1 me-2 ms-1`}></i>
              </span>
              <span class="text-gray-500 fw-semibold fs-4">{heading}</span>
            </div>
            <div class={`${text} fw-bolder mt-4 fs-2hx`}>
              {count}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountCard;
