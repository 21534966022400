import CreateEmailCampaign from "../components/email-campaigns/createEmailCampaign";
import EmailCampaignContxtProvider from "../components/email-campaigns/emailCampaignContxt";

const EmailCampaigns = () => {
  return (
    <EmailCampaignContxtProvider>
      <CreateEmailCampaign />
    </EmailCampaignContxtProvider>
  );
};

export default EmailCampaigns;
