import { useContext } from "react";
import FontDropdown from "./fontDropdown";
import { LinkInBioContxt } from "../linkInBioContxt";

const FONTS = ["Helvetica Neue", "Anton", "Oswald", "Roboto", "Itim"];

const Fonts = () => {
  const { bioPageForm } = useContext(LinkInBioContxt);
  const { setValue, watch } = bioPageForm;
  const { font } = watch();
  return (
    <div className="mt-10">
      <div className="border-lg p-lg-5 rounded-1">
        <p className="fw-semibold fs-4 mb-4">Font</p>
        <FontDropdown
          options={FONTS}
          value={font === "Helvetica Neue" ? "Default" : font}
          handler={(value) => setValue("font", value)}
        />
      </div>
    </div>
  );
};

export default Fonts;
