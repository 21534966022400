import { Spinner } from "reactstrap";
import BarDeviceChart from "./apex-charts/barDeviceChart";
import AnalyticsNotFound from "../common/emptyScreens/analyticsNotFound";

const DeviceChart = ({ device = [], loading }) => {
  const namesMapper = {
    smartphone: "Mobile",
  };

  const capitalizeFirstLetter = (item = "") => {
    return item?.charAt(0)?.toUpperCase() + item?.slice(1) || "";
  };

  const data = device?.map((item) => item?._count?._all);

  const categories = device?.map((item) =>
    item?.device === ""
      ? "Others"
      : `${namesMapper[item?.device] || capitalizeFirstLetter(item?.device)}`
  );

  return (
    <div class="col-lg-6">
      <div class="card card-flush" style={{ borderRadius: "15px" }}>
        <div class="card-header min-h-lg-70px min-h-50px px-5 px-lg-10">
          <div class="card-title flex-column">
            <h3 class="fw-bold mb-1">Clicks + Scan by Device</h3>
          </div>

          <div class="card-toolbar"></div>
        </div>

        <div class="card-body border-top p-lg-9 p-5 ps-0 py-0 pt-lg-5">
          {loading ? (
            <div
              className="min-h-auto h-300px"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Spinner />
            </div>
          ) : data && data?.length > 0 ? (
            <BarDeviceChart data={data} categories={categories} />
          ) : (
            <AnalyticsNotFound />
          )}
        </div>
      </div>
    </div>
  );
};

export default DeviceChart;
