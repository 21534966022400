import React from 'react'

const BulkActionList = (props) => {

    const {
        isActive,
        toggleTagModal,
        toggleDeleteModal,
    } = props;

    return (
        <div id="bulkactionmenu" className={`position-absolute bottom-0 mb-6 justify-content-center rounded-1 border border-light-grey-info w-fit-content ${isActive ? '' : 'd-none'}`} style={{left: '50%', transform: 'translateX(-50%)',background: '#433266'}}>
            <div className="d-flex gap-5 py-4 px-10">
                <button data-bs-toggle="tooltip" onClick={toggleTagModal} data-bs-original-title="Add To List" className="btn btn-icon rounded-1 btnbulkaction"><i className="far fa-list fs-3"></i></button>
                <button data-bs-toggle="tooltip" data-bs-original-title="Block" className="btn btn-icon rounded-1 btnbulkaction"><i className="far fa-envelope fs-3"></i></button>
                <button data-bs-toggle="tooltip" data-bs-original-title="Edit" className="btn btn-icon rounded-1 btnbulkaction"><i className="far fa-message fs-3"></i></button>

                <button data-bs-toggle="tooltip" data-bs-original-title="Export" className="btn btn-icon rounded-1 btnbulkaction"><i class="fa-brands fa-whatsapp fs-3"></i></button>
                <button data-bs-toggle="tooltip" onClick={toggleDeleteModal} data-bs-original-title="Delete" className="btn btn-icon rounded-1 btnbulkaction"><i className="far fa-trash-can text-danger fs-3"></i></button>
            </div>
        </div>
    )
}

export default BulkActionList
