import { createContext, useContext, useEffect, useState } from "react";
import EmailCampaignAPI from "../../services/http/emailCampaign";
import { AuthContxt } from "../../store/authContxt";

export const EmailCampaignContxt = createContext(null);

const EmailCampaignContxtProvider = (props) => {
    const { fetchQuota } = useContext(AuthContxt);
    const [loading, setLoading] = useState(true);
    const [emailCampaigns, setEmailCampaigns] = useState([]);
    const [notLinksScreen, setNotLinksScreen] = useState("");
    const [search, setSearch] = useState();
    const [status, setStatus] = useState("All Statuses");
    const [filters, setFilters] = useState({});
    const [currentCampaign, setCurrentCampaign] = useState({});
  

  
    const getEmailCampaigns = async (params) => {
      const auth_token = localStorage.getItem("auth_token");
        setLoading(true);
        EmailCampaignAPI.getAllEmailCampaigns(auth_token, params)
          .then(({ data }) => {
            setEmailCampaigns(data.resultData);
            data.resultData.length < 1 && setNotLinksScreen("notFound");
          })
          .catch((error) => {
            console.log("Error while fetching shortlinks", error);
          })
          .finally(() => {
            setLoading(false);
          });
      };
  
    useEffect(() => {
        getEmailCampaigns();
    }, []);
  
 
    return (
      <EmailCampaignContxt.Provider
        value={{
          emailCampaigns,
          setEmailCampaigns,
          getEmailCampaigns,
          loading,
          setLoading,
          notLinksScreen,
          setNotLinksScreen,
          search,
          setSearch,
          filters,
          setFilters,
          status,
          setStatus,
          currentCampaign,
          setCurrentCampaign
        }}
      >
        {props.children}
      </EmailCampaignContxt.Provider>
    );
  };
  
  export default EmailCampaignContxtProvider;