import analyticsempty from "../../assets/media/empty-state/analytics.svg";
const AnalyticsNotFound = () => {
  return (
    <div
      style={{ width: "100%" }}
      className="d-flex flex-column justify-content-center"
      id="no-analytics-message"
    >
      <img
        src={analyticsempty}
        className="w-lg-300px w-75 opacity-75 mx-auto mb-10"
        alt=""
      />
      <h2 className="mx-10 opacity-75 text-center">No analytics available !</h2>
      <p className="w-lg-75 mx-auto text-center">
        Share page on social media or other platforms to get insights
      </p>
    </div>
  );
};
export default AnalyticsNotFound;
