import { useContext, useState } from "react";
import { LinkInBioContxt } from "../linkInBioContxt";

const selectedCatgoryClasses =
  "bg-light-info text-dark border-info border-opacity-25";
const CATEGORIES = [
  "🏣 Business",
  "😎 Influencer",
  "❤️ Non-Profit",
  "📚 Education",
  "🎨 Creator",
  "📊 Marketing",
  "💼 Professional",
];

const Category = () => {
  const { bioPageForm } = useContext(LinkInBioContxt);
  const { watch, setValue } = bioPageForm;
  const { category } = watch();
  const [showInput, setShowInput] = useState(false);

  const categoryHandler = (value) => {
    setValue("category", value ? value : "");
    setShowInput(value === "" ? true : false);
  };

  return (
    <>
      <div>
        <h3 className="fs-4 ms-2 mb-5">Select Category</h3>
      </div>
      <div>
        {CATEGORIES.map((item, index) => {
          return (
            <button
              key={index}
              onClick={() => categoryHandler(item)}
              className={`btn btn-icon-gray-800 rounded-1 btn-text-gray-800 btn-clicked-dark border mb-3 ms-2 activate-cat ${
                category === item ? selectedCatgoryClasses : ""
              }`}
            >
              {item}
            </button>
          );
        })}
        <button
          onClick={() => categoryHandler("")}
          className={`btn btn-icon-gray-800 rounded-1 btn-text-gray-800 btn-clicked-dark border mb-3 ms-2 activate-cat ${
            showInput ? selectedCatgoryClasses : ""
          }`}
        >
          {"⚙️ Other"}
        </button>
      </div>
      <div>
        {showInput ? (
          <input
            type="text"
            value={category}
            onChange={(e) => setValue("category", e.target.value)}
            className="form-control h-50px rounded-1 w-lg-50 mt-1 ms-lg-2"
            placeholder="Write your Category (Optional)"
          />
        ) : null}
      </div>
    </>
  );
};

export default Category;
