import httpMethods from "..";


const UserSettingsApi = {
    fetchProfile: async () => {
        const token = localStorage?.getItem('auth_token');
        return token && httpMethods
            .get(`/profile`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    updateProfile: async (payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .put(`/profile`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    updatePassword: async (payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .put(`/update-password`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    updateEmail: async (payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .put(`/update-email`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    createUser: async (payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .post(`/create-user`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    deleteBulkUser: async (payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .post(`/delete-bulk-user`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    updateUser: async (id, payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .put(`/update-user/${id}`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    deleteUser: async (id) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .delete(`/delete-user/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    getUsers: async (params) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .get(`/users`, {
                params,
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },

    // Payment Cards
    createCard: async (payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .post(`/create-card`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    updateCard: async (id, payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .put(`/update-card/${id}`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    deleteCard: async (id) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .delete(`/delete-card/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    paymentCards: async () => {
        const token = localStorage?.getItem('auth_token');
        return token && httpMethods
            .get(`/payment-cards`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    setupIntent: async () => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .get(`/setup-intent`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },

    // Billing Address
    createAddress: async (payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .post(`/billing-address`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    updateAddress: async (id, payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .put(`/billing-address/${id}`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    markAddressPrimary: async (id, payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .put(`/billing-address-primary/${id}`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    deleteAddress: async (id) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .delete(`/billing-address/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    billingAddresses: async () => {
        const token = localStorage?.getItem('auth_token');
        return token && httpMethods
            .get(`/billing-address`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },

    // User Location
    getLocation: async () => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .get(`/location`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },

    // Dashboard
    dashboardAnalytics: async (payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .post(`/dashboard-analytics`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res)
            .catch((error) => {
                throw error;
            });
    },
    // Demo
    skipDemo: async (payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .put(`/skip-tour`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res)
            .catch((error) => {
                throw error;
            });
    },
}

export default UserSettingsApi;