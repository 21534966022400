import EmailCampaignMain from "../components/email-campaigns";
import EmailCampaignContxtProvider from "../components/email-campaigns/emailCampaignContxt";

const EmailCampaigns = () => {
  return (
    <EmailCampaignContxtProvider>
      <EmailCampaignMain />
    </EmailCampaignContxtProvider>
  );
};

export default EmailCampaigns;
