import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Col, Row, TabPane, Input } from "reactstrap";
import QrCodesAPI from "../../../services/http/qrCodes";
import StickyButton from "./stickyqrviewbtn";
import LogoCropper from "./logoCropper";
import { AuthContxt } from "../../../store/authContxt";
import { Tooltip } from "reactstrap";

const PRESET = [
  "#000000",
  "#de3121",
  "#ef8000",
  "#198639",
  "#229ce0",
  "#2a5bd7",
  "#6b52d1",
  "#d84280",
];

const PATTERNS = [
  {
    id: "classy",
    url: "https://divsly.com/app/assets/media/qrcode/pattern-1.png",
    title: "Classy",
  },
  {
    id: "rounded",
    url: "https://divsly.com/app/assets/media/qrcode/pattern-2.png",
    title: "Rounded",
  },
  {
    id: "extra-rounded",
    url: "https://divsly.com/app/assets/media/qrcode/pattern-3.png",
    title: "Extra Rounded",
  },
  {
    id: "classy-rounded",
    url: "https://divsly.com/app/assets/media/qrcode/pattern-4.png",
    title: "Classy Rounded",
  },
  {
    id: "square",
    url: "https://divsly.com/app/assets/media/qrcode/pattern-5.png",
    title: "Square",
  },
  {
    id: "dots",
    url: "https://divsly.com/app/assets/media/qrcode/pattern-6.png",
    title: "Dots",
  },
];

const CORNERS = [
  {
    id: "square",
    url: "https://divsly.com/app/assets/media/qrcode/corner-2.png",
    title: "Square",
  },
  {
    id: "extra-rounded",
    url: "https://divsly.com/app/assets/media/qrcode/corner-3.png",
    title: "Extra Rounded",
  },
  {
    id: "none",
    url: "https://divsly.com/app/assets/media/qrcode/corner-7.png",
    title: "None",
  },
  {
    id: "8",
    url: "https://divsly.com/app/assets/media/qrcode/corner-8.png",
    title: "Corner 8",
  },
  {
    id: "4",
    url: "https://divsly.com/app/assets/media/qrcode/corner-4.png",
    title: "Corner 4",
  },
  {
    id: "5",
    url: "https://divsly.com/app/assets/media/qrcode/corner-5.png",
    title: "Corner 5",
  },
  {
    id: "dot",
    url: "https://divsly.com/app/assets/media/qrcode/corner-6.png",
    title: "dot",
  },
  {
    id: "9",
    url: "https://divsly.com/app/assets/media/qrcode/corner-9.png",
    title: "Corner 9",
  },
];

const CustomizationQr = ({ activeId, form, isEdit }) => {
  const { quota } = useContext(AuthContxt);
  const { setValue, watch } = form;
  const { preset, isBgTransparent, pattern, corner, color, bgColor, logo } =
    watch();
  const [file, setFile] = useState(null);
  const [uploadedImage, setUploadedImage] = useState();
  const [cropperModal, setCropperModal] = useState(false);
  const [logoBackground, setLogoBackground] = useState(false);

  const [errorMsg, setErrorMsg] = useState({});
  const [activeClass, setActiveClass] = useState("");

  const arePatternsLimited = quota?.pattern === 0 || false;
  const isCustomLogo = quota?.customLogo === 1 || false;
  const notColorOptions = quota?.colorOptions === 0 || false;

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [cornerTooltipOpen, setCornerTooltipOpen] = useState(null);

  const colorHandler = (key, value) => {
    setValue(key, value);
  };

  const fileInputRef = useRef(null);

  const handlePenClick = () => {
    fileInputRef?.current?.click();
  };

  const resetLogoHandler = () => {
    setFile(null);
    setValue("logo", "");
    setValue("qrLogoId", process.env.REACT_APP_QR_LOGO_ID || "");
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop, background) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return null;
    }
    canvas.width = image.width;
    canvas.height = image.height;

    ctx.drawImage(image, 0, 0);
    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height
    );

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(data, 0, 0);

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        const reader = new FileReader();
        reader.onload = function () {
          if (background) {
            let img = new Image();
            img.onload = () => {
              const base64data = addLogobackground(img);
              resolve(base64data);
            };
            img.src = reader.result;
          } else {
            const base64data = reader.result;
            resolve(base64data);
          }
        };
        reader.readAsDataURL(file);
      }, "image/png");
    });
  };

  const uploadQrLogoHandler = (img) => {
    try {
      const auth_token = localStorage.getItem("auth_token");
      QrCodesAPI.uploadQrCodeLogo(auth_token, { payload: img })
        .then(({ data }) => {
          setValue("qrLogoId", data.data);
          setValue("logo", img);
        })
        .catch((error) => {
          console.log("Error while creating qr code: ", error);
        });
    } catch (e) {
      console.log("Error while uploading qr code: ", e);
    }
  };

  const showCroppedImage = useCallback(
    async (image, croppedAreaPixels, background) => {
      try {
        const croppedImage = await getCroppedImg(
          image,
          croppedAreaPixels,
          background
        );
        uploadQrLogoHandler(croppedImage);
      } catch (e) {
        console.error(e);
      }
    },
    []
  );

  const addLogobackground = (img) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const padding = 10;
    canvas.width = img.width + 2 * padding;
    canvas.height = img.height + 2 * padding;

    if (img.width === img.height) {
      const backgroundRadius = Math.min(canvas.width / 2, canvas.height / 2);
      ctx.beginPath();
      ctx.arc(
        canvas.width / 2,
        canvas.height / 2,
        backgroundRadius,
        0,
        2 * Math.PI
      );
      ctx.fillStyle = "white";
      ctx.fill();
      ctx.closePath();
    } else {
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    ctx.drawImage(img, padding, padding, img.width, img.height);
    const logoWithBackground = canvas.toDataURL(img.type);
    return logoWithBackground;
  };

  const handleFileChange = () => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        let img = new Image();
        img.onload = () => {
          const base64String = logoBackground
            ? addLogobackground(img)
            : reader.result;
          uploadQrLogoHandler(base64String);
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const resetErrorMsg = (type) => {
    setErrorMsg((preState) => ({
      ...preState,
      [type]: null,
    }));
  };

  const cropModalHandler = (event) => {
    const selectedFile = event.target.files[0];
    const maxAllowedSize = 2 * 1024 * 1024;
    if (event.target.files[0].size > maxAllowedSize) {
      event.target.value = "";
      setErrorMsg((preState) => ({
        ...preState,
        image: "Max allowed side is 2 MB",
      }));
      setTimeout(() => resetErrorMsg("image"), 4 * 1000);
      return;
    }

    resetErrorMsg("image");

    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        setUploadedImage(base64String);
        setCropperModal(true);
      };
      reader.readAsDataURL(selectedFile);
    }
    event.target.value = "";
  };

  useEffect(() => {
    const scrollHandler = () => {
      setActiveClass("scrolled_view_qr");
    };
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", scrollHandler);
    }
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  useEffect(() => {
    const scrollEndHandler = () => {
      setActiveClass("");
    };
    if (typeof window !== "undefined") {
      window.addEventListener("scrollend", scrollEndHandler);
    }
    return () => {
      window.removeEventListener("scrollend", scrollEndHandler);
    };
  }, []);

  const PATTERN_IDS = PATTERNS.map((item) => item?.id);
  const CORNERS_IDS = CORNERS.map((item) => item?.id);

  const toggle = (val) => {
    if (PATTERN_IDS.includes(val)) {
      setTooltipOpen(val);
    }
  };

  const cornerToggle = (val) => {
    if (CORNERS_IDS.includes(val)) {
      setCornerTooltipOpen(val);
    }
  };

  return (
    <>
      <TabPane tabId={activeId}>
        <div className="card-body px-0 mw-1000px pt-1">
          <div className="separator border-info mt-lg-5 mb-lg-8 mb-4 border-opacity-10 mobile-hide"></div>
          <Row>
            <Col sm="12">
              <div className="qr-app-container rounded-1">
                <div>
                  <StickyButton form={form} activeClass={activeClass} />
                  <div className="separator border-info mt-lg-5 mb-lg-8 mb-4 border-opacity-10 desktop-hide"></div>
                  <h2 className="fw-bolder fs-2">Choose QR Code color</h2>
                  <div className="separator my-5"></div>
                  <div className="mb-5">
                    <p className="fw-semibold fs-4 mb-4">Preset</p>
                    <div className="d-flex flex-wrap row-gap-5">
                      {PRESET.map((item, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              setValue("preset", item);
                              setValue("color", item);
                            }}
                            className={`p-1 cursor-pointer rounded-1 on-hvr-border bdr-qr-btn me-5 ${
                              item === preset ? " selected" : ""
                            }`}
                          >
                            <div
                              className="p-3 rounded-1 w-40px h-40px"
                              style={{
                                backgroundColor: item,
                              }}
                            ></div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="d-flex flex-wrap gap-lg-10 gap-5">
                    <div className="mt-5">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <p className="fw-semibold fs-4">Color</p>
                        {notColorOptions ? (
                          <i className="fas ms-2 fa-lock"></i>
                        ) : null}
                      </div>
                      <div className="color-picker-wrapper">
                        <Input
                          onChange={(e) =>
                            !notColorOptions && colorHandler("color", e.target.value)
                          }
                          disabled={notColorOptions}
                          className="color-picker cursor-pointer"
                          value={color}
                          type="color"
                        />
                        <Input
                          onChange={(e) => colorHandler("color", e.target.value)
                          }
                          className="hex-input form-control rounded-0"
                          type="text"
                          maxLength="7"
                          value={color}
                          // disabled
                          placeholder="#000000"
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                        }}
                      >
                        <p className="fw-semibold fs-4">Background Color</p>
                        {notColorOptions ? (
                          <i className="fas ms-2 fa-lock"></i>
                        ) : null}
                      </div>
                      <div className="color-picker-wrapper flex-wrap gap-5">
                        <div className="d-flex">
                          <Input
                            onChange={(e) =>
                              !notColorOptions && colorHandler("bgColor", e.target.value)
                            }
                            disabled={notColorOptions}
                            className="color-picker cursor-pointer"
                            value={bgColor}
                            type="color"
                          />
                          <Input
                            onChange={(e) => colorHandler("bgColor", e.target.value)
                            }
                            className="hex-input form-control rounded-0"
                            type="text"
                            maxlength="7"
                            value={bgColor}
                            // disabled
                            placeholder="#000000"
                          />
                        </div>
                        <div className="form-check ps-lg-5 ps-0">
                          <div className="form-check form-check-sm form-check-custom form-check-info form-check-solid rounded-1">
                            <Input
                              className="form-check-input rounded-1 cursor-pointer"
                              type="checkbox"
                              onChange={() =>
                                setValue("isBgTransparent", !isBgTransparent)
                              }
                            />
                            <label
                              className="form-check-label"
                              for="flexCheckDefault"
                            >
                              Transparent Background
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="separator my-10"></div>
                  <div>
                    <h2 className="fw-900 fs-2 mb-8">Choose the Style</h2>
                    <div>
                      <p className="fw-semibold fs-4">Patterns</p>
                      <div>
                        {PATTERNS?.map((item) => {
                          const filteredPatterns =
                            quota?.pattern === 0
                              ? ["classy", "rounded"]
                              : PATTERN_IDS;
                          const validPattern = filteredPatterns.includes(
                            item?.id
                          );

                          const isValid = arePatternsLimited && !validPattern;
                          return (
                            <div
                              key={item?.id}
                              className={`bg-white p-3 me-5 mb-lg-0 mb-5 rounded-1 btn ${
                                validPattern ? "on-hvr-border" : "opacity-50"
                              } bdr-qr-btn ${
                                validPattern && item.id === pattern
                                  ? "selected"
                                  : ""
                              }`}
                            >
                              <div
                                type="button"
                                onClick={() => {
                                  if (arePatternsLimited) {
                                    validPattern &&
                                      setValue("pattern", item.id);
                                  } else {
                                    setValue("pattern", item.id);
                                  }
                                }}
                                onMouseOver={(e) => {
                                  if (isValid) {
                                    toggle(item.id);
                                  }
                                }}
                                onMouseLeave={(e) => setTooltipOpen(null)}
                                id="pattern"
                              >
                                <img
                                  className="w-50px h-50px"
                                  src={item.url}
                                  alt={item.title}
                                />
                              </div>
                            </div>
                          );
                        })}
                        <Tooltip
                          placement={"top"}
                          isOpen={PATTERN_IDS.includes(tooltipOpen)}
                          target={"pattern"}
                          toggle={toggle}
                        >
                          {`Please Upgrade your Plan`}
                        </Tooltip>
                      </div>
                      <div className="pt-lg-10 pt-5">
                        <p className="fw-semibold fs-4 mb-4">Corners</p>
                        <div>
                          {CORNERS.map((item) => {
                            const filteredCorners =
                              quota?.pattern === 0
                                ? ["square", "extra-rounded", "none"]
                                : CORNERS_IDS;
                            const validPattern = filteredCorners.includes(
                              item?.id
                            );
                            const isValid = arePatternsLimited && !validPattern;

                            return (
                              <div
                                type="button"
                                className={`bg-white p-3 m-2 ms-0 me-5 rounded-1 btn ${
                                  validPattern ? "on-hvr-border" : "opacity-50"
                                } bdr-qr-btn ${
                                  validPattern && item.id === corner
                                    ? "selected"
                                    : ""
                                }`}
                                onClick={() => {
                                  if (arePatternsLimited) {
                                    validPattern && setValue("corner", item.id);
                                  } else {
                                    setValue("corner", item.id);
                                  }
                                }}
                                onMouseOver={(e) => {
                                  if (isValid) {
                                    cornerToggle(item.id);
                                  }
                                }}
                                onMouseLeave={(e) => setCornerTooltipOpen(null)}
                                id="corner"
                              >
                                <img
                                  className="w-30px h-30px"
                                  src={item.url}
                                  alt={item.title}
                                />
                              </div>
                            );
                          })}
                          <Tooltip
                            placement={"top"}
                            isOpen={CORNERS_IDS.includes(cornerTooltipOpen)}
                            target={"corner"}
                            toggle={cornerToggle}
                          >
                            {`Please Upgrade your Plan`}
                          </Tooltip>
                        </div>
                      </div>
                      <div className="separator my-10"></div>
                      <div>
                        <h2 className="fw-900 fs-2 mb-15">
                          Choose your Logo{" "}
                          {!isCustomLogo ? (
                            <i className="fas ms-2 fa-lock"></i>
                          ) : null}
                        </h2>
                        <div className="image-input image-input-outline">
                          <div className="image-input-wrapper w-125px h-125px p-3 d-flex justify-content-center align-items-center">
                            <img
                              src={logo || "/logo-with-white-bg.png"}
                              alt="Logo"
                              height={85}
                              width={85}
                            />
                          </div>

                          <div className="position-relative">
                            <input
                              ref={fileInputRef}
                              type="file"
                              name="logo"
                              accept="image/png, image/svg, image/jpg, image/jpeg"
                              style={{ display: "none" }}
                              onChange={(e) =>  isCustomLogo && cropModalHandler(e)}
                            />
                            <div
                              onClick={() => {
                                isCustomLogo && handlePenClick();
                              }}
                              style={{
                                right: "-8px",
                                bottom: "112px",
                              }}
                              className="position-absolute btn opacity-50 btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                            >
                              <span>
                                <i className="fa fa-pencil opacity-50"></i>
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              right: "-7px",
                              bottom: "-10px",
                            }}
                            onClick={() => {
                              isCustomLogo && resetLogoHandler();
                            }}
                            className="position-absolute opacity-50 btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                          >
                            <i className="fa fa-x opacity-50"></i>
                          </div>
                        </div>
                        {errorMsg?.image !== null ? (
                          <span className="error-text text-danger">
                            {errorMsg?.image}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </TabPane>

      {cropperModal ? (
        <LogoCropper
          image={uploadedImage || "/logo-with-white-bg.png"}
          backgroundHandler={setLogoBackground}
          logoBackground={logoBackground}
          toggleHandler={() => {
            setCropperModal(false);
            handleFileChange();
          }}
          cancelHandler={() => {
            setCropperModal(false);
          }}
          cropHandler={async (area = null, pixels = null) => {
            pixels
              ? await showCroppedImage(uploadedImage, pixels, logoBackground)
              : handleFileChange();
            setCropperModal(false);
          }}
        />
      ) : null}
    </>
  );
};

export default CustomizationQr;
