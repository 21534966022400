import { useContext, useState } from "react";
import AddressCard from "./addressCard";
import AddressNote from "./addressNote";
import AddressForm from "./addressForm";
import { UserSettingsContxt } from "../../userSettingContxt";

const BillingAddress = ({ page = "" }) => {
  const { billingAddresses, billingAddressHandler } =
    useContext(UserSettingsContxt);
  console.log(billingAddresses, 'billingAddressesbillingAddresses')
  const [showForm, setShowForm] = useState(false);
  const [edittedAddress, setEdittedAddress] = useState(null);

  const addressHandler = (action, data) => {
    if (action === "update") {
      setEdittedAddress(data);
      setShowForm(true);
    } else {
      billingAddressHandler(action, data);
    }
  };

  return (
    <>
      <div className="card  mb-5 mb-xl-10">
        <div className="card-header min-h-lg-70px min-h-50px px-5 px-lg-10">
          <div className="card-title m-0">
            <h3>Billing Address</h3>
          </div>
        </div>

        <div className="card-body p-lg-10 p-5">
          <div className="row gx-lg-9 g-5 gy-6">
            {billingAddresses &&
              billingAddresses?.length > 0 &&
              billingAddresses?.map((address, index) => (
                <AddressCard
                  key={address?.id}
                  addressHandler={addressHandler}
                  address={{ ...address, index: index + 1 }}
                  page={page}
                />
              ))}
            <AddressNote formHandler={() => setShowForm(true)} />
          </div>
        </div>
      </div>

      {showForm ? (
        <AddressForm
          addressHandler={addressHandler}
          edittedAddress={edittedAddress}
          toggleHandler={() => {
            setShowForm(false);
            setEdittedAddress(null);
          }}
        />
      ) : null}
    </>
  );
};

export default BillingAddress;
