import { useContext, useMemo, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap";
import { AuthContxt } from "../../../store/authContxt";

const FontDropdown = ({ options = [], value = "", handler }) => {
  const { quota } = useContext(AuthContxt);
  const [search, setSearch] = useState();
  const [selectedOption, setSelectedOption] = useState(value);

  const { plan = "starter", buttonFontStyles = 0 } = quota || {};
  let limitLevel = 1;
  if (plan === "standard" && buttonFontStyles === 0) {
    limitLevel = 2;
  } else if (plan === "super" && buttonFontStyles === 1) {
    limitLevel = 3;
  } else {
    limitLevel = 1;
  }

  const searchedResult = useMemo(() => {
    if (search) {
      const remainingOptions =
        limitLevel === 1
          ? options.slice(0, 1)
          : limitLevel === 2
          ? options.slice(0, 2)
          : options;
      return remainingOptions.filter((item) =>
        item.toLowerCase().includes(search.toLowerCase())
      );
    }
    return options;
  }, [search, options, limitLevel]);

  const searchHandler = (e) => {
    setSearch(e.target.value);
  };

  const optionHandler = (option) => {
    setSelectedOption(option);
    handler(option);
  };

  return (
    <UncontrolledDropdown direction="down">
      <DropdownToggle
        className="w-lg-auto pe-5 h-50px arrowdowndd d-flex justify-content-between bg-transparent w-100 rounded-1"
        style={{ border: "1px solid #e9e6f1" }}
      >
        {selectedOption || value ? (
          <div
            style={{
              fontFamily: selectedOption,
            }}
          >
            <span>{selectedOption || value}</span>
          </div>
        ) : (
          <span>{"Select an option"}</span>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <div
          style={{
            padding: "0.5rem 1.25rem",
            margin: "0 0 0.5rem 0",
            width: "100%",
          }}
        >
          <Input
            value={search}
            onChange={searchHandler}
            style={{
              backgroundColor: "#ffffff",
              padding: "0.55rem 0.75rem",
              color: "#5e6278",
              fontSize: "0.95rem",
              border: "1px solid #e1e3ea",
              borderRadius: "0.35rem",
              outline: "0 !important",
            }}
          />
        </div>
        {searchedResult?.map((item, index) => {
          const isDisable =
            limitLevel === 1 ? index > 0 : limitLevel === 2 ? index > 1 : false;
          return (
            <DropdownItem
              disabled={isDisable}
              onClick={() => optionHandler(item)}
              key={index}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <p
                  style={{
                    fontFamily: item,
                  }}
                >
                  {item === "Helvetica Neue" ? "Default" : item}
                </p>
                {isDisable ? <i className="fas ms-2 fa-lock"></i> : null}
              </div>
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default FontDropdown;
