import React, { useCallback, useContext, useEffect, useState } from "react";
import NewUser from "./users/newUser";
import User from "./users/user";
import UserSettingsApi from "../../services/http/userSettings";
import TableHeader from "./users/tableHeader";
import { Spinner, Tooltip } from "reactstrap";
import NotFound from "../common/emptyScreens/notFound";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import _debounce from "lodash/debounce";
import DeleteModal from "../../modals/deleteModal";
import { AuthContxt } from "../../store/authContxt";
import generateProfileImage from "../../utils/generateProfileImage";

const Users = () => {
  const { quota, profile, fetchQuota } = useContext(AuthContxt);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState();
  const [search, setSearch] = useState();
  const [showSearch, setShowSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [modal, setModal] = useState(false);
  console.log(quota?.user, 'quota?.user')
  const isLimitReached = quota?.user === 0 || false;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipToggle = () => {
    if (isLimitReached) {
      setTooltipOpen(!tooltipOpen);
    }
  };

  const toggle = () => setModal(!modal);

  const usersHandler = (action, data) => {
    fetchQuota()
    if (action === "delete") {
      setUsers((preState) => {
        return preState?.filter((item) => item?.id !== data?.id);
      });
    }
    if (action === "add") {
      if (data?.id) {
        setUsers((preState) => [...preState, data]);
      }
    }
    if (action === "update") {
      setUsers((preState) => {
        return preState?.map((item) =>
          item?.id === data?.id ? { ...preState, ...data } : item
        );
      });
    }
  };

  const userSelectorHandler = (id) => {
    setUsers((preState) => {
      const updatedUsers = preState?.map((item) => {
        const updatedItem =
          item?.id === id
            ? {
                ...item,
                isSelected: item?.isSelected ? !item?.isSelected : true,
              }
            : item;
        return updatedItem;
      });
      return updatedUsers;
    });
  };

  const allUserSelectorHandler = (isSelected) => {
    setUsers((preState) => {
      return preState?.map((item) => ({
        ...item,
        isSelected: isSelected,
      }));
    });
  };

  const deleteHandler = () => {
    setDeleteLoading(true);
    setDeleteModal(false);

    const ids = users
      ?.filter((item) => item?.isSelected && profile?.id !== item.id)
      .map((item) => item?.id);
    UserSettingsApi.deleteBulkUser({ ids })
      .then((data) => {
        setUsers((preState) => {
          return preState.filter((item) => !ids.includes(item.id));
        });
      })
      .catch((error) => {
        setDeleteLoading(false);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  const moveAccountAdminToFirst = (data) => {
    const index = data.findIndex((obj) => obj.parentUser === null);
    if (index !== -1) {
      const [accountAdmin] = data.splice(index, 1);
      data.unshift(accountAdmin);
    }
  };

  const fetchUsers = async (params = {}) => {
    try {
      setLoading(true);
      const { data } = await UserSettingsApi.getUsers(params);
      moveAccountAdminToFirst(data?.data);
      let userDetails = data?.data;
      userDetails = userDetails?.map((item) =>
        !item?.avatar
          ? { ...item, avatar: generateProfileImage(item?.email) }
          : item
      );
      setUsers(userDetails || []);
      setLoading(false);
    } catch (e) {
      console.log("Error while fetching users: ", e);
      setLoading(false);
    }
  };

  const handleSearch = useCallback(
    _debounce((filters) => fetchUsers(filters), 500),
    []
  );

  const searchHandler = (search) => {
    handleSearch({ search });
    setSearch(search);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const notUsersScreenMapper = {
    notFound: (
      <NotFound
        handler={toggle}
        type={"users"}
        isLimitReached={isLimitReached}
      />
    ),
  };

  // const showBulkAction = users?.some((item) => item?.isSelected);
  const isAllUserSelected = users?.every((item) => item?.isSelected);

  return (
    <>
      <div className=" d-flex justify-content-between mb-5 w-100">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 className="fs-1">Manage Users</h3>
          {/* {showBulkAction ? (
            <button
              type="button"
              className="btn px-0 py-0"
              onClick={() => setDeleteModal(true)}
              disabled={deleteLoading}
            >
              <i className="fa-regular fa-trash-can fs-2 ms-5 text-danger cursor-pointer" />
            </button>
          ) : null} */}
        </div>
        <div>
          <div className="card-toolbar d-flex justify-content-center align-items-center mb-5">
            <div className="search-bar bg-light-info me-5 rounded-1 mobile-hide">
              <InputGroup className="mb-0 w-auto">
                {showSearch ? (
                  <Form.Control
                    className="headerinputsearch fw-normal"
                    placeholder="Search here ..."
                    aria-label="Search here ..."
                    aria-describedby="basic-addon2"
                    onChange={(e) => {
                      searchHandler(e.target.value);
                    }}
                  ></Form.Control>
                ) : null}
                <InputGroup.Text
                  id="basic-addon2"
                  title="search"
                  className="cursor-pointer btn btn-icon rounded-1 bg-light-info"
                  onClick={() => {
                    setShowSearch(!showSearch);
                  }}
                >
                  <i className="fa-regular fa-magnifying-glass text-info fs-3"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>

            <div
              onMouseOver={tooltipToggle}
              onMouseLeave={tooltipToggle}
              id="newUser"
            >
              <button
                disabled={isLimitReached}
                type="button"
                className="btn rounded-1 btn-info"
                onClick={toggle}
              >
                <i className="ki-outline ki-plus fs-3"></i>
                Add User
              </button>
            </div>

            <Tooltip
              disabled={isLimitReached}
              placement={"top"}
              isOpen={tooltipOpen}
              target={"newUser"}
              toggle={tooltipToggle}
            >
              {`Your Monthly User Limit Reached. Please Upgrade your Plan`}
            </Tooltip>
          </div>
        </div>
      </div>

      <div className="card-body table-responsive p-0">
        <table className="table fs-6 gy-5 border-bottom">
          <TableHeader
            allSelected={isAllUserSelected}
            allUserSelectorHandler={allUserSelectorHandler}
          />
          <tbody className="fw-semibold text-gray-600">
            {loading ? (
              <Spinner />
            ) : users && users?.length > 0 ? (
              users?.map((user) => (
                <User
                  key={user?.id}
                  user={user}
                  usersHandler={usersHandler}
                  userSelectorHandler={userSelectorHandler}
                />
              ))
            ) : (
              <tr className="">
                <td className="border-0 position-relative w-100" colSpan={"8"}>
                  {notUsersScreenMapper["notFound"]}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {modal ? (
        <NewUser
          edittedUser={null}
          toggleHandler={toggle}
          usersHandler={usersHandler}
        />
      ) : null}

      <DeleteModal
        isOpen={deleteModal}
        modalHandler={() => setDeleteModal(false)}
        leftBtnHandler={deleteHandler}
        rightBtnHandler={() => setDeleteModal(false)}
        data={{
          heading: "Delete Users",
          description: "Are you sure you want to delete these selected Users ?",
          leftBtn: "Yes",
          rightBtn: "No",
        }}
      />
    </>
  );
};

export default Users;
