import { createContext, useContext, useEffect, useState } from "react";
import { AuthContxt } from "../../store/authContxt";
import QrCodesAPI from "../../services/http/qrCodes";

const filtersDefaultState = {
  starred: false,
  sort: "",
  tags: [],
};

export const QrCodeContxt = createContext(null);

const QrCodeContxtProvider = (props) => {
  const { fetchQuota } = useContext(AuthContxt);

  const [qrCodes, setQrCodes] = useState([]);
  const [tags, setTags] = useState([]);

  const [loading, setLoading] = useState(true);
  const [notQrScreen, setNotQrScreen] = useState("");
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState(filtersDefaultState);

  const tagsHandler = (links) => {
    const allTags =
      links &&
      links?.length > 0 &&
      links?.reduce((tags, item) => {
        if (item.tags) {
          const itemTags = item.tags
            .split(",")
            .map((tag) => tag.trim())
            .filter((tag) => tag !== "");
          tags.push(...itemTags);
        }
        let uniqueTagsSet = new Set(tags);
        let uniqueTagsArray = Array.from(uniqueTagsSet);
        return uniqueTagsArray;
      }, []);

    setTags(allTags);
  };

  const fetchFilteredData = (filters) => {
    try {
      const { starred, ...rest } = filters;
      const auth_token = localStorage.getItem("auth_token");
      QrCodesAPI.filteredQrCodes(auth_token, {
        ...rest,
        ...(starred ? { starred } : {}),
        tags: filters.tags.join(","),
      })
        .then(({ data }) => {
          if (data?.success) {
            setQrCodes(data.resultData);
            // tagsHandler(data.resultData);
            data.resultData.length < 1 && setNotQrScreen("searchFound");
          }
        })
        .catch((error) => {
          console.log("Error while fetching qr codes: ", error);
        });
    } catch (e) {
      console.log("Error while fetching filtered data : ", e.message);
    }
  };

  const deleteQrCode = (id) => {
    const auth_token = localStorage.getItem("auth_token");
    QrCodesAPI.deleteQrCode(auth_token, id)
      .then(async ({ data }) => {
        if (data?.success) {
          const filteredQrCodes = qrCodes.filter((item) => item.id !== id);
          if (
            filters?.tags?.length > 0 ||
            filters?.sort !== "" ||
            search !== ""
          ) {
            await getQrCodes();
            setSearch("");
            setFilters(filtersDefaultState);
          } else {
            tagsHandler(filteredQrCodes);
            filteredQrCodes.length === 0 && setNotQrScreen("notFound");
            setQrCodes(filteredQrCodes);
          }
          await fetchQuota();
        }
      })
      .catch(({ error }) => {
        console.log("Error while deleting qr code: ", error);
      });
  };

  const getQrCodes = async () => {
    setLoading(true);
    const auth_token = localStorage.getItem("auth_token");
    QrCodesAPI.getQrCodes(auth_token)
      .then(({ data }) => {
        if (data?.success) {
          setQrCodes(data.resultData);
          tagsHandler(data.resultData);
          data.resultData.length < 1 && setNotQrScreen("notFound");
        }
      })
      .catch((error) => {
        console.log("Error while fetching qr codes: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getQrCodes();
  }, []);

  return (
    <QrCodeContxt.Provider
      value={{
        loading,
        tags,
        tagsHandler,
        qrCodes,
        setQrCodes,
        notQrScreen,
        setNotQrScreen,
        deleteQrCode,
        filters,
        setFilters,
        fetchFilteredData,
        search,
        setSearch,
      }}
    >
      {props.children}
    </QrCodeContxt.Provider>
  );
};

export default QrCodeContxtProvider;
