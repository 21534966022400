import dayjs from "dayjs";
import { useContext } from "react";
import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { AuthContxt } from "../../../store/authContxt";

const DateRangeSelector = ({
  analyticsHandler,
  selectedDateRange,
  setSelectedDateRange,
  toggleHandler,
}) => {
  const { quota } = useContext(AuthContxt);
  const dateHandler = (ranges) => {
    setSelectedDateRange(ranges.selection);
  };

  const maxDate = new Date();
  const minDate = new Date(maxDate);
  minDate.setDate(minDate.getDate() - (quota?.linkAnalytics || 30));
  return (
    <>
      <div className="shadow d-inline-block">
        <DateRangePicker
          onChange={dateHandler}
          showSelectionPreview={false}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={[selectedDateRange]}
          direction="horizontal"
          showDateDisplay={false}
          maxDate={maxDate}
          minDate={minDate}
        />
        <div className="drp-buttons">
          <span className="drp-selected">
            <span>
              {dayjs(selectedDateRange.startDate).format("MM/DD/YYYY")}
            </span>
            <span>{ `-` }</span>
            <span>{dayjs(selectedDateRange.endDate).format("MM/DD/YYYY")}</span>
          </span>
          <button
            onClick={() => toggleHandler(false)}
            className="cancelBtn btn btn-sm btn-default"
            type="button"
          >
            Cancel
          </button>

          <button
            onClick={() => {
              if (analyticsHandler) {
                const adjustedStartDate = new Date(
                  selectedDateRange.startDate.setHours(0, 0, 0, 0)
                );
                const adjustedEndDate = new Date(
                  selectedDateRange.endDate.setHours(23, 59, 59, 999)
                );
                analyticsHandler(adjustedStartDate, adjustedEndDate);
                toggleHandler(false);
              }
            }}
            className="applyBtn btn btn-sm btn-primary"
            type="button"
          >
            Apply
          </button>
        </div>
      </div>
    </>
  );
};

export default DateRangeSelector;