import { useContext } from "react";
import { AuthContxt } from "../../../store/authContxt";
import { LinkInBioContxt } from "../linkInBioContxt";

const ColorPicker = ({ value, type, handler, title }) => {
  return (
    <div>
      <p className="fw-semibold fs-4">{`${title} Color`}</p>
      <div className="color-picker-wrapper">
        <input
          className="color-picker h-40px w-40px"
          value={value}
          onChange={(e) => handler(type, e.target.value)}
          type="color"
        />
        <input
          className="hex-input form-control h-40px rounded-0"
          type="text"
          maxLength="7"
          // disabled={true}
          value={value}
          onChange={(e) => {
            let value = e.target.value
            if (value.length === 6 && !value.startsWith('#')){
              value= `#${value}`
            }
            if(value.length <= 7){
              handler(type, value)
            }
          }}
          placeholder="#5c47e"
        />
        {/* handler(type, e.target.value) */}
      </div>
    </div>
  );
};

const Buttons = () => {
  const { bioPageForm } = useContext(LinkInBioContxt);
  const { quota } = useContext(AuthContxt);
  const { setValue, watch } = bioPageForm;

  const { plan = "starter", buttonFontStyles = 0 } = quota || {};
  let limitLevel = 1;
  if (plan === "standard" && buttonFontStyles === 0) {
    limitLevel = 3;
  } else if (plan === "super" && buttonFontStyles === 1) {
    limitLevel = 3;
  } else {
    limitLevel = 1;
  }

  const {
    btnClr = "",
    fontClr = "",
    shadowClr = "",
    descriptionClr = "",
    titleClr = "",
    button = "",
  } = watch();

  const buttonClickHandler = (type, value) => {
    setValue(type, value);
  };

  const btnSecnodLevel = limitLevel === 1;
  const btnThirdLevel = limitLevel === 1 || limitLevel === 2;

  return (
    <div className="mt-10">
      <h3 className="fw-bolder fs-2 mb-3">Buttons</h3>
      <div className="border-lg p-lg-5 pt-4 rounded-1">
        <p className="fw-semibold fs-4 mb-4">Fill</p>

        <div
          className="gap-lg-10 gap-5 w-100 btngrids"
          style={{
            display: "inline-grid",
          }}
        >
          <button
            onClick={() => buttonClickHandler("button", "type1")}
            type="button"
            className="rounded-0 border-0 bg-white selected"
          >
            <div className="h-40px bg-dark"></div>
          </button>

          <button
            onClick={() => buttonClickHandler("button", "type2")}
            type="button"
            className="border-0 bg-white"
          >
            <div className="h-40px bg-dark rounded-2"></div>
          </button>

          <button
            onClick={() => buttonClickHandler("button", "type3")}
            type="button"
            className="border-0 rounded-pill bg-white"
          >
            <div className="h-40px bg-dark rounded-pill"></div>
          </button>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "baseline",
          }}
        >
          <p className="fw-semibold mt-10 fs-4 mb-4">Outline</p>
          {btnSecnodLevel ? <i className="fas ms-2 fa-lock"></i> : null}
        </div>
        <div
          className="gap-lg-10 gap-5 w-100 btngrids"
          style={{
            display: "inline-grid",
          }}
        >
          <button
            disabled={btnSecnodLevel}
            onClick={() => buttonClickHandler("button", "type4")}
            type="button"
            className="rounded-0 bg-white"
          >
            <div className="h-40px border-1 border-dark"></div>
          </button>

          <button
            disabled={btnSecnodLevel}
            onClick={() => buttonClickHandler("button", "type5")}
            type="button"
            className="bg-white rounded-2"
          >
            <div className="h-40px border-1 border-dark rounded-2"></div>
          </button>

          <button
            disabled={btnSecnodLevel}
            onClick={() => buttonClickHandler("button", "type6")}
            type="button"
            className="rounded-pill bg-white"
          >
            <div className="h-40px border-1 border-dark rounded-pill"></div>
          </button>
        </div>

        {/* Soft Shadow */}

        <div
          style={{
            display: "flex",
            alignItems: "baseline",
          }}
        >
          <p className="fw-semibold mt-10 fs-4 mb-4">Soft Shadow</p>
          {btnThirdLevel ? <i className="fas ms-2 fa-lock"></i> : null}
        </div>

        <div
          className="gap-lg-10 gap-5 w-100 btngrids"
          style={{
            display: "inline-grid",
          }}
        >
          <button
            disabled={btnThirdLevel}
            onClick={() => buttonClickHandler("button", "type7")}
            type="button"
            style={{ boxShadow: "0 4px 4px 0 rgba(0,0,0,.16)" }}
            className="border-0 rounded-0 bg-white"
          >
            <div className="h-40px"></div>
          </button>

          <button
            disabled={btnThirdLevel}
            onClick={() => buttonClickHandler("button", "type8")}
            type="button"
            style={{ boxShadow: "0 4px 4px 0 rgba(0,0,0,.16)" }}
            className="border-0 bg-white rounded-2"
          >
            <div className="h-40px rounded-2"></div>
          </button>

          <button
            disabled={btnThirdLevel}
            onClick={() => buttonClickHandler("button", "type9")}
            type="button"
            style={{ boxShadow: "0 4px 4px 0 rgba(0,0,0,.16)" }}
            className="border-0 rounded-pill bg-white"
          >
            <div className="h-40px rounded-pill"></div>
          </button>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "baseline",
          }}
        >
          <p className="fw-semibold mt-10 fs-4 mb-4">Hard Shadow</p>
          {btnThirdLevel ? <i className="fas ms-2 fa-lock"></i> : null}
        </div>

        <div
          className="gap-lg-10 gap-5 w-100 btngrids"
          style={{
            display: "inline-grid",
          }}
        >
          <button
            disabled={btnThirdLevel}
            onClick={() => buttonClickHandler("button", "type10")}
            type="button"
            style={{ boxShadow: "4px 4px 0 0 black" }}
            className="rounded-0 bg-white"
          >
            <div className="h-40px border-1 border-dark"></div>
          </button>

          <button
            disabled={btnThirdLevel}
            onClick={() => buttonClickHandler("button", "type11")}
            type="button"
            style={{ boxShadow: "4px 4px 0 0 black" }}
            className="bg-white rounded-2"
          >
            <div className="h-40px border-1 border-dark rounded-2"></div>
          </button>

          <button
            disabled={btnThirdLevel}
            onClick={() => buttonClickHandler("button", "type12")}
            type="button"
            style={{ boxShadow: "4px 4px 0 0 black" }}
            className="rounded-pill bg-white"
          >
            <div className="h-40px border-1 border-dark rounded-pill"></div>
          </button>
        </div>

        <div className="separator my-3 mt-10 separator-dashed"></div>

        <div
          className="d-flex gap-lg-10 gap-6 mt-10"
          style={{
            flexFlow: "wrap",
          }}
        >
          <ColorPicker
            title="Title"
            value={titleClr}
            handler={buttonClickHandler}
            type={"titleClr"}
          />
          <ColorPicker
            title="Description"
            handler={buttonClickHandler}
            value={descriptionClr}
            type={"descriptionClr"}
          />
          <ColorPicker
            title="Button"
            value={btnClr}
            handler={(type, value) => {
              if (!button) {
                buttonClickHandler("button", "type2");
              }
              buttonClickHandler(type, value);
            }}
            type={"btnClr"}
          />
          <ColorPicker
            title="Font"
            handler={buttonClickHandler}
            value={fontClr}
            type={"fontClr"}
          />
          {!["type1", "type2", "type3", "type4", "type5", "type6", ""].includes(
            button
          ) ? (
            <ColorPicker
              title="Shadow"
              value={shadowClr}
              handler={buttonClickHandler}
              type={"shadowClr"}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Buttons;
