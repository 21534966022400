import { useContext } from "react";
import { MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { AuthContxt } from "../../store/authContxt";

const AdminSidebar = () => {
  const { profile } = useContext(AuthContxt);
  return profile?.isAdmin ? (
    <>
      <MenuItem
        active={window.location.pathname === "/admin-dashboard"}
        component={<Link to="/admin-dashboard" />}
      >
        <i class="fa-solid fa-a fs-1"></i>
        <span className="menu-title fs-4 fw-semibold text-gray-700">
          Admin Dashboard
        </span>
      </MenuItem>
      <MenuItem
        active={window.location.pathname === "/subscribers"}
        component={<Link to="/subscribers" />}
      >
        <i class="fa-solid fa-s fs-1"></i>
        <span className="menu-title fs-4 fw-semibold text-gray-700">
          Subscribers
        </span>
      </MenuItem>
    </>
  ) : (
    <></>
  );
};

export default AdminSidebar;
