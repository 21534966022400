import { useContext } from "react";
import DivslyLogo from "../../../assets/logos/divsly-c.svg";
import { AuthContxt } from "../../../store/authContxt";
import { LinkInBioContxt } from "../linkInBioContxt";

const Logo = () => {
  const { quota } = useContext(AuthContxt);
  const { bioPageForm } = useContext(LinkInBioContxt);
  const { watch, setValue } = bioPageForm;
  const { hideLogo } = watch();
  const { branding = 0 } = quota || {};

  const isPlanUpgraded = branding !== 0;

  return (
    <div className="mt-10 mb-5">
      <div className="border p-5 rounded-1">
        <div className="d-flex justify-content-between mb-5 ">
          <div className="d-flex">
            <p className="fw-semibold fs-4 mb-4">Hide the logo</p>
            {!isPlanUpgraded ? (
              <div
                className="alert alert-dark px-2 py-1 rounded-1 bg-dark text-light d-inline ms-3"
                title="Upgrade the plan to use this feature"
              >
                <i className="fa-solid fa-lock me-2"></i>Upgrade
              </div>
            ) : null}
          </div>
          <div className="d-flex justify-content-center align-items-center mb-3">
            <div className="form-check form-switch form-check-custom  form-check-success form-check-solid cursor-pointer">
              <input
                onChange={(e) => {
                  isPlanUpgraded && setValue("hideLogo", e.target.checked);
                }}
                className="form-check-input border border-dark border-opacity-10"
                style={{ width: "35px", height: "19px" }}
                type="checkbox"
                value={hideLogo}
                id="flexSwitchChecked"
                checked={hideLogo}
                disabled={!isPlanUpgraded}
              />
            </div>
          </div>
        </div>
        <img src={DivslyLogo} alt="" />
      </div>
    </div>
  );
};

export default Logo;
