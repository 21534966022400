
import React from "react";
import Sidebar from "../../components/common/sidebar";
import {Card} from 'reactstrap';

const createSmsCampaigns = () => {
  

 
  return (
    <div>
         <div className="w-full d-flex">
        <div className="mt-md-10 w-[280px] me-md-7 mt-xs-0 me-xs-0">
          <Sidebar />
        </div>
        <div className="customDashboardSize">
          <div className="d-flex flex-column w-100 flex-root app-root">
            <div className="app-page flex-column flex-column-fluid">
              <div className="app-wrapper flex-column flex-row-fluid pe-3 pe-lg-0 me-lg-10 mt-lg-10 mt-0 position-relative">
                <div className="app-main flex-column flex-row-fluid">
                <Card className="card card-flush overflow-auto">
<div className="card-body mx-auto w-75">
                                            <div className="px-15 py-20 pt-8 rounded-1 w-100" style={{borderColor: '#f3f0fb !important'}}>
												<h2 className="me-15 fs-2x">Setup SMS Campaign</h2>
												<p>Set up your SMS campaign to reach your customers directly and boost engagement. Follow our simple steps to create and launch an effective SMS campaign today.</p>
												<div className="separator border-bottom my-10" style={{borderColor: '#f3f0fb !important'}}></div>
											<div className="mt-15">
                                                <label className="fw-bold fs-5 mb-2">Campaign Name</label>
                                                <input type="text" id="url-input" name="url-input" className="form-control rounded-1 h-50px mb-2" placeholder="Example : 50% off Campaign"/>
                                                <span className="text-gray-800">Give your campaign an internal name to help organize and locate it easily within your account. For example: 'Sale_November's</span>
                                            </div>
                                            <div className="mt-15">
                                                <label className="fw-bold fs-5 mb-2">SMS Sender</label>
                                                <input type="text" id="url-input" name="url-input" className="form-control rounded-1 h-50px mb-2" placeholder="Example : 50% off Campaign"/>
                                                <span className="text-gray-800">Use this field to customize the SMS sender with up to 11 characters or 15 digits numeric. Recipients in some countries (including the U.S.) cannot see customizations - instead the sender will appear as a numerical short code.</span>
                                            </div>
                                            <div className="mt-15">
                                                <div className="form-check form-switch form-check-custom form-check-n-dark form-check-info form-check-solid mb-2">
													<input className="form-check-input" type="checkbox" value="" checked="" style={{height: '19px' , width: '35px'}}/>
                                                    <label className="form-check-label fs-5 fw-bold text-black" for="kt_flexSwitchCustomDefault_1_1">
                                                        Manage compliance for the United States
                                                    </label>
                                                </div>
                                                <span className="text-gray-800">If you are sending this SMS campaign to the United States recipients without following this compliance then your campaign will not be sent and will get suspended.</span>
                                            </div>
                                            <div className="mt-15">
                                                <label className="fw-bold fs-5 mb-2">Organization prefix</label>
                                                <input type="text" id="url-input" name="url-input" className="form-control rounded-1 h-50px mb-2" placeholder="Example : 50% off Campaign"/>
                                                <span className="text-gray-800">A recognizable prefix will ensure your audience knows who you are. Recommended by the U.S. carriers.</span>
                                            </div>
                                            <div className="mt-15">
                                                <label className="fw-bold fs-5 mb-2">Unsubscribe instructions</label>
                                                <input type="text" id="url-input" name="url-input" className="form-control rounded-1 h-50px mb-2" placeholder="Example : 50% off Campaign"/>
                                                <span className="text-gray-800">Opt-out language is required in certain jurisdictions and we strongly recommend including it in your messages. Your message must include STOP keyword.</span>
                                            </div>
                                            <div className="mt-15">
                                                <div className="d-flex justify-content-between w-100 mb-5 align-items-center">
												<p className="fw-bold fs-5 mb-0">SMS Message</p> 


												<div>
													<select className="form-select adlnkplaceholder border form-select-sm bg-light-info form-select-solid w-200px" data-control="select2" data-close-on-select="true" data-placeholder="Add Short Link" data-allow-clear="true">
													<option></option>
													<option value="1">Option 1</option>
													<option value="2">Option 2</option>
												</select>	
												</div>	
													
												</div>
                                                <textarea type="text" className="form-control rounded-1 mb-2" rows="7" cols="20" placeholder="Example : 50% off Campaign"></textarea>
                                                <div className="d-flex justify-content-between"><div> <span className="text-gray-800"><span className="me-2 fw-bold text-dark">169</span>characters remaining</span></div>
                                                <div><span className="text-gray-800"><span className="me-2 fw-bold text-dark">0</span>characters </span>
                                                <span className="text-dark fw-bold"><span className="me-2 fw-bold"> | 0</span>SMS</span></div></div>
                                            </div>
                                            <div className="mt-15">
                                                <p className="fw-bold fs-5 mb-2">Send a Test SMS</p>
                                                <p className="text-gray-800">Verify that you are sending the test SMS to an existing contact in your database with a mobile phone number saved in the 'SMS' field and who is not blacklisted for SMS campaigns.</p>
                                                <p className="text-gray-800">The cost of sending your test SMS will be deducted from your SMS credits.</p>
                                            </div>

                                            <div className="separator my-10"></div>

                                            <div className="border p-5 rounded-1 bg-light-info mb-15">
                                                <span className="fw-bold fs-3 mb-2 d-block">Choose your contact lists</span>
                                                <input className="form-control d-flex align-items-center rounded-1" value="" placeholder="Enter List Name" id="kt_tagify_users" />		    
												<p className="fs-5 text-gray-800 mt-3"><i className="fa-duotone fa-user-vneck opacity-50 me-2"></i><span className="fw-bold text-black">16108</span> Recipents</p>
                                             </div>

                                            <div className="d-flex gap-5 mt-10 justify-content-end">
                                                <button className="btn btn-light border border-2 rounded-1 border hover-elevate-up">Cancel</button>
                                                <a href="" className="btn btn-info rounded-1">Create Campaign</a>
                                            </div>

                                             </div>
										</div>
                                        </Card>
   </div>
   
   </div></div>
   </div>
   </div>
   </div>
   </div>
  );
};

export default createSmsCampaigns;


