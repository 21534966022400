import { useState } from "react";
import LinkInBioHeader from "../header";
import Links from "../links";
import LinkInBioTabs from "../tabs";
import Customization from "../customization";
import Analytics from "../analytics";
import FinalPageLook from "../final-page-look";

const LinkInBioDetails = () => {
  const [tab, setTab] = useState("links");
  const tabsMapper = {
    links: <Links />,
    customization: <Customization />,
    analytics: <Analytics />,
  };

  return (
    <div
      className="app-content flex-column-fluid rounded-3 pt-0 bg-transparent"
      style={{ paddingBottom: "27px !important", paddingTop: "0px" }}
    >
      <div
        className="app-container container-fluid position-relative bg-transparent"
        style={{
          paddingRight: "0 !important",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          className="link-in-bio-container position-absolute bg-white rounded-3 ps-10 pe-10"
          style={{ right: "0px", left: "0", top: "0" }}
        >
          <LinkInBioHeader tabsHandler={setTab} />

          <div class="d-lg-flex me-lg-1">
            <div class="col-lg-7 col-12">
              <div class="card card-flush border-0 ps-lg-0 px-6 pe-lg-10 h-xl-100">
                <LinkInBioTabs tabHandler={setTab} tab={tab} />
                <div class="card-body px-0 pt-1">
                  <div class="separator border-info mt-3 mb-6 border-opacity-10"></div>
                  {tabsMapper[tab]}
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-12 pt-1 pb-8 pe-0 moblie-hide position-relative">
              <FinalPageLook />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkInBioDetails;
