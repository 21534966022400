import { getCountryCode } from "countries-list";
import Map from "../common/analytics/map";
import { Spinner } from "reactstrap";

const LocationMap = ({ country = [], loading }) => {
  const values = country?.map((item) => item?._count?._all);

  const totalClicks = values?.reduce((total, val) => total + val, 0);

  const mappedCountries = country.map((item) => {
    return {
      id: getCountryCode(item?.country),
      name: item?.country,
      percent: `${
        ((item?._count?._all / totalClicks) * 100).toFixed(2) || 0
      }% <br/>`,
    };
  });

  return (
    <div class="col-lg-6">
      <div class="card card-flush" style={{ borderRadius: "15px" }}>
        <div class="card-header min-h-lg-70px min-h-50px px-5 px-lg-10">
          <div class="card-title flex-column">
            <h3 class="fw-bold mb-1">Clicks + Scan by Location</h3>
          </div>

          <div class="card-toolbar"></div>
        </div>

        <div class="card-body border-top p-0">
          <div id="click-scan-geo" class="min-h-100">
            {loading ? (
              <div
                className="min-h-auto h-300px"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Spinner />
              </div>
            ) : (
              <Map countries={mappedCountries} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationMap;
