import dayjs from "dayjs";
import BarChart from "./barChart";

const LinksAnalytics = ({ date = [], ip = [], content }) => {
  date.sort((a, b) => new Date(a.date) - new Date(b.date));
  const labels = date?.map((item) =>
    item?.date === null ? "others" : dayjs(item?.date).format("MMM DD")
  );

  const values = date?.map((item) => item?._count?._all);

  const totalClicks = values?.reduce((total, val) => total + val, 0);

  const uniqueClicks = (ip.length / totalClicks) * 100 || 0;

  return (
    <>
      <div className="card card-flush border border-1px border-gray-300 rounded-1 h-md-50 mb-5 mb-xl-10">
        <div className="card-header pt-5">
          <div className="card-title d-flex flex-column">
            <div className="d-flex align-items-center">
              <span
                className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2"
                id="totalclicks"
              >
                {totalClicks}
              </span>

              <span className="badge badge-light-success fs-base">
                <i class="fa-solid fa-caret-up fs-5 text-success ms-n1"></i>
                <span id="unique_data">{`${uniqueClicks.toFixed(2)}%`}</span>
              </span>
              <span className="text-gray-600 pt-1 fw-semibold fs-6">
                {`Unique ${content.type}`}
              </span>
            </div>
            <span className="text-gray-600 pt-1 fw-semibold fs-6">
              {content.type}
            </span>
          </div>
        </div>
        <div className="card-body d-flex align-items-end px-0 pb-0">
          <BarChart categories={labels} data={values} type={content.type} />
        </div>
      </div>
    </>
  );
};

export default LinksAnalytics;
