import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ProgressBar,
  Spinner,
} from "react-bootstrap";
import divslyIcon from "../../assets/media/divsly-icon.svg";
import { AuthContxt } from "../../store/authContxt";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import UserSettingsApi from "../../services/http/userSettings";

// Validation schemas for each step
const step1Validation = yup.object().shape({
  jobtitle: yup.string().required("Please fill this field!"),
  companysize: yup.string().required("Please fill this field!"),
});

const step2Validation = yup.object().shape({
  industry: yup.string().required("Please fill this field!"),
});

const TimelineComponent = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const { profile, profileHandler } = useContext(AuthContxt);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [skipForNow, setSkipForNow] = useState(false); // Track if 'skip for now' was clicked

  // Initialize form with step 1 validation
  const step1Methods = useForm({
    resolver: yupResolver(step1Validation),
    defaultValues: {
      jobtitle: profile?.jobtitle || "",
      companysize: profile?.companysize || "",
    },
  });

  // Initialize form with step 2 validation
  const step2Methods = useForm({
    resolver: yupResolver(step2Validation),
    defaultValues: {
      industry: profile?.industry || "",
    },
  });

  const {
    handleSubmit: handleSubmitStep1,
    trigger: triggerStep1,
    reset: resetStep1,
    formState: { errors: errorsStep1 },
    control: controlStep1,
    getValues: getValuesStep1,
  } = step1Methods;

  const {
    handleSubmit: handleSubmitStep2,
    trigger: triggerStep2,
    reset: resetStep2,
    formState: { errors: errorsStep2 },
    control: controlStep2,
    getValues: getValuesStep2,
  } = step2Methods;

  const handleNextStep = async () => {
    if (currentStep === 1) {
      // Validate step 1 fields
      const isValid = await triggerStep1(); // Validate all fields in step 1
      if (!isValid) return; // If validation fails, do not proceed

      // Proceed to next step
      setCurrentStep(currentStep + 1);

      // Prepare step 2 form
      resetStep2({
        industry: getValuesStep1("industry") || "", // Reset industry based on step 1 values
      });
    } else if (currentStep === 2) {
      // Validate step 2 fields and submit form
      const isValid = await triggerStep2(); // Validate industry field
      if (!isValid) return; // If validation fails, do not proceed

      submitHandler({
        ...getValuesStep1(),
        ...getValuesStep2(),
      });
    }
  };

  const submitHandler = async (formData) => {
    try {
      setLoading(true);
      const updatedPayload = {
        ...profile,
        ...formData,
      };

      const { data } = await UserSettingsApi.updateProfile(updatedPayload);
      // profileHandler(data?.data);
      resetStep1();
      resetStep2();
      setLoading(false);
      let localData = localStorage.getItem("user_details");
      localData = JSON.parse(localData);
      localData = { ...localData, ...formData };
      localStorage.setItem("user_details", JSON.stringify(localData));
      navigate("/");
    } catch (e) {
      console.log("Error while updating profile: ", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    async function skipCompanyProfile() {
      if (skipForNow) {
        setLoading(true);
        await submitHandler({ iscompanydetailsskipped: true }).then(() => {
          setLoading(false);
          let localData = localStorage.getItem("user_details");
          localData = JSON.parse(localData);
          localData.iscompanydetailsskipped = true;
          localStorage.setItem("user_details", JSON.stringify(localData));
        });
        return;
      }
    }

    skipCompanyProfile();
  }, [skipForNow]);

  return (
    <Container>
      {/* Timeline Progress Bar on Top */}
      <ProgressBar className="mb-4">
        <ProgressBar
          variant="success"
          now={currentStep >= 1 ? 50 : 0}
          key={1}
        />
        <ProgressBar variant="info" now={currentStep === 2 ? 50 : 0} key={2} />
      </ProgressBar>

      {/* Timeline Steps */}
      <Row className="justify-content-center">
        <Col
          md={12}
          className={`timeline-step ${
            currentStep === 1 ? "active" : "completed"
          }`}
        >
          <h2 className="mb-5 mt-5 pt-5 pb-5">
            Hi {profile?.username},
            <br />
            Welcome to Divsly!
          </h2>
          {/* Step 1: First Form */}
          {currentStep === 1 && (
            <FormProvider {...step1Methods}>
              <Form
                id="kt_account_profile_details_form"
                className="form"
                onSubmit={(e) => {
                  e.preventDefault(); // Prevent default form submission
                  handleNextStep(); // Trigger custom form handling
                }}
              >
                <Form.Group controlId="formFirstName" className="mb-5">
                  <Form.Label className="text-dark fs-3">
                    What is your Job Title?
                  </Form.Label>
                  <Controller
                    name="jobtitle"
                    control={controlStep1}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        className="form-control bg-white h-55px border border-gray-300 form-control-lg form-control-solid mb-2 pe-15 rounded-55rem"
                        placeholder="Select Your Job Title"
                        {...field} // Spread field properties here
                      />
                    )}
                  />
                  {errorsStep1.jobtitle && (
                    <div className="text-danger">
                      {errorsStep1.jobtitle.message}
                    </div>
                  )}
                </Form.Group>
                <Form.Group controlId="formLastName" className="mb-5">
                  <Form.Label className="text-dark fs-3">
                    How many employees do you have?
                  </Form.Label>
                  <div className="d-inline-block align-items-center">
                    {[
                      "1-9",
                      "10-49",
                      "50-199",
                      "200-1000",
                      "1001-4999",
                      "5000+",
                    ].map((range) => (
                      <div
                        key={range}
                        className="border rounded p-4 d-inline-block cursor-pointer fs-3 me-4 mb-4"
                      >
                        <Controller
                          name="companysize"
                          control={controlStep1}
                          render={({ field }) => (
                            <Form.Check
                              {...field}
                              type="radio"
                              label={range}
                              value={range}
                              checked={field.value === range}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </div>
                    ))}
                  </div>
                  {errorsStep1.companysize && (
                    <div className="text-danger">
                      {errorsStep1.companysize.message}
                    </div>
                  )}
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  className="mb-5 d-flex btn btn-info w-100 align-items-center justify-content-between"
                >
                  {loading ? (
                    <Spinner />
                  ) : (
                    <>
                      Continue Process
                      <img
                        src={divslyIcon}
                        alt="divsly-icon"
                        className="w-40px"
                      />
                    </>
                  )}
                </Button>
                <a
                  className="mb-5 cursor-pointer"
                  onClick={() => {
                    setSkipForNow(true);
                  }}
                >
                  skip for now
                </a>
              </Form>
            </FormProvider>
          )}
        </Col>

        <Col
          md={12}
          className={`timeline-step ${currentStep === 2 ? "active" : ""}`}
        >
          {/* Step 2: Second Form */}
          {currentStep === 2 && (
            <Form
              onSubmit={(e) => {
                e.preventDefault(); // Prevent default form submission
                handleNextStep(); // Trigger custom form handling
              }}
            >
              <Form.Group controlId="formEmail">
                <Form.Label className="fs-3 text-dark">
                  What does your company specialize in? <br />
                </Form.Label>
                <span className="fs-4 d-block italic mb-5">
                  This will help us better understand your needs
                </span>
                <Controller
                  name="industry"
                  control={controlStep2}
                  render={({ field }) => (
                    <Form.Control
                      as="select"
                      className="pb-5 mb-5"
                      {...field} // Spread field properties here
                    >
                      <option value="">Choose an industry...</option>
                      <option value="Agri and Allied Industries">
                        Agri and Allied Industries
                      </option>
                      <option value="Auto Components">Auto Components</option>
                      <option value="Aviation">Aviation</option>
                      <option value="Ayush">Ayush</option>
                      <option value="Banking">Banking</option>
                      <option value="Consumer Durables">
                        Consumer Durables
                      </option>
                    </Form.Control>
                  )}
                />
                {errorsStep2.industry && (
                  <div className="text-danger">
                    {errorsStep2.industry.message}
                  </div>
                )}
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                className="mb-5 d-flex btn btn-info w-100 align-items-center justify-content-between"
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    Continue Process
                    <img
                      src={divslyIcon}
                      alt="divsly-icon"
                      className="w-40px"
                    />
                  </>
                )}
              </Button>
              <a className="mb-5" href="#" onClick={() => navigate("/")}>
                skip for now
              </a>
            </Form>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default TimelineComponent;
