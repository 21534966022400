import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Input, Button, Spinner, Form } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import GoogleLoginButton from "../../pages/googleLogin";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AuthApi from "../../services/http/auth";
import AuthPageWrapper from "../wrapper/authPageWrapper";
import { PASSWORD_REGEX, EMAIL_REGEX } from "../../utils/enum";
import useCustomMessage from "../../hooks/useCustomMessage";
import { AuthContxt } from "../../store/authContxt";

// const PHONE_NO_REGEX = /^[0-9]{6,14}$/;

const signUpValidation = yup.object().shape({
  email: yup
    .string()
    .required("Please fill this field")
    .matches(EMAIL_REGEX, "Please enter a valid email"),
  // phone: yup
  //   .string()
  //   .nullable()
  //   .notRequired()
  //   .matches(PHONE_NO_REGEX, 'Enter a valid number')
  //   .test(
  //     'is-valid-phone',
  //     'Enter a valid number',
  //     (value) => !value || PHONE_NO_REGEX.test(value)
  //   ),
  password: yup
    .string()
    .required("Please Enter your password")
    .matches(
      PASSWORD_REGEX,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  repassword: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const SignupMain = () => {
  const { message, messageHandler } = useCustomMessage();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { profileHandler } = useContext(AuthContxt);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [disableResend, setDisableResend] = useState(false);
  const [userInvited, setUserInvited] = useState(false);

  const invite = searchParams.get("invite") || "";

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(signUpValidation),
  });

  const resetHandler = () => {
    reset({
      email: "",
      phone: "",
      password: "",
      repassword: "",
    });
  };

  const handlesignup = async (data) => {
    if (data) {
      setLoading(true);
      try {
        const requestData = {
          email: data.email,
          // phone: data.phone,
          password: data.password,
          repassword: data.repassword,
          invite,
        };

        const response = await AuthApi.signup(requestData);
        const responseData = response.data.data;
        responseData?.id && setUserId(responseData?.id);
        if (userInvited && response?.data.success) {
          profileHandler(responseData);
          localStorage.setItem("user_details", JSON.stringify(responseData));
          localStorage.setItem("auth_token", responseData.token);
          navigate("/");
        } else {
          messageHandler(responseData?.type, response?.data?.success);
        }
        resetHandler();
        setLoading(false);
      } catch (error) {
        messageHandler(
          error?.response?.data?.data?.type ||
            error?.response?.data?.data?.message ||
            "Something went wrong",
          false
        );
        resetHandler();
        setLoading(false);
        console.log("Error while sign up: ", error);
      }
    }
  };

  const resendEmailHandler = async () => {
    if (!disableResend) {
      try {
        const response = await AuthApi.resendVerifyEmail({ id: userId });
        const data = response.data.data;
        if (data?.id) {
          setUserId(data?.id);
          setDisableResend(true);
          setTimeout(() => {
            setDisableResend(false);
          }, 5 * 1000);
        }
        messageHandler(data?.type, response.data.success);
      } catch (e) {
        messageHandler(
          e?.response?.data?.data?.type ||
            e?.response?.data?.data?.message ||
            "Something went wrong",
          false
        );
        console.log("Error while sending resend email: ", e);
      }
    }
  };

  useEffect(() => {
    if (invite) {
      const invitedUserInfo = async () => {
        try {
          const response = await AuthApi.invitedUserInfo({ invite });
          if (response?.data?.success) {
            setUserInvited(true);
            reset({
              email: response?.data?.data?.email,
            });
          }
        } catch (e) {
          messageHandler(e.response.data.message, false);
        }
      };
      invitedUserInfo();
    }

    const email = searchParams.get("email");
    if (email) {
      reset({ email });
      // Remove email parameter from URL
      navigate("/signup", { replace: true });
    }
  }, [invite, reset, searchParams, navigate]);

  return (
    <AuthPageWrapper>
      <Form
        className="form px-6 fv-plugins-bootstrap5 fv-plugins-framework position-relative z-1"
        noValidate
        id="kt_sign_in_form"
        data-kt-redirect-url="/links-list.php"
        action="#"
        onSubmit={handleSubmit(handlesignup)}
      >
        <div className="text-lg-center mt-lg-0 mt-8 mb-7">
          <h1 className="text-dark mb-3 fs-3x fw-bolder">Get Started</h1>

          <div className="text-gray-400 fw-semibold fs-4 mb-5">
            Already have an account?&nbsp;
            <Link to="/" className="link-primary fw-bold">
              Log in
            </Link>
          </div>
          <div className="flex-center btn-transparent btn-lg w-100 h-55px mt-lg-10 mt-5">
            <GoogleLoginButton messageHandler={messageHandler} />
          </div>
        </div>

        <div className="d-flex align-items-center mb-7">
          <div className="border-bottom border-gray-300 mw-50 w-100"></div>
          <span className="fw-semibold text-gray-400 fs-7 mx-2">Or</span>
          <div className="border-bottom border-gray-300 mw-50 w-100"></div>
        </div>

        <div className="fv-row mb-7 fv-plugins-icon-container">
          <div className="position-relative">
            <Controller
              name={"email"}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  value={value}
                  className="form-control bg-white h-55px border border-gray-300 form-control-lg form-control-solid pe-15 rounded-55rem"
                  type="text"
                  placeholder="Enter your email"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  onBlur={onBlur}
                  autocomplete="email"
                />
              )}
            />
            <span
              className="btn btn-sm btn-icon  position-absolute translate-middle top-50 end-0 me-n2 opacity-25"
              data-kt-password-meter-control="visibility"
            >
              <i className="fa-regular fa-envelope fs-2"></i>
            </span>
          </div>
          {errors?.email && (
            <span className="error-text text-danger mt-1">
              {errors?.email?.message}
            </span>
          )}
        </div>
        {/* <div className="fv-row mb-7 fv-plugins-icon-container">
          <div className="position-relative">
            <Controller
              name={"phone"}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  value={value}
                  className="form-control bg-white h-55px border border-gray-300 form-control-lg form-control-solid pe-15 rounded-55rem"
                  type="text"
                  placeholder="Phone number (Optional)"
                  onChange={(e) => {
                    onChange(e);
                  }}
                  onBlur={onBlur}
                  autocomplete="phone"
                />
              )}
            />
            <span
              className="btn btn-sm btn-icon  position-absolute translate-middle top-50 end-0 me-n2 opacity-25"
              data-kt-password-meter-control="visibility"
            >
              <i className="fa-regular fa-phone fs-2"></i>
            </span>
          </div>
          {errors?.phone && (
            <span className="error-text text-danger mt-1">
              {errors?.phone?.message}
            </span>
          )}
        </div> */}
        <div className="fv-row mb-10">
          <div className="mb-7 fv-row fv-plugins-icon-container">
            <div className="mb-1">
              <div className="position-relative">
                <Controller
                  name={"password"}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      value={value}
                      className="form-control bg-white h-55px border border-gray-300 form-control-lg form-control-solid pe-15 rounded-55rem"
                      type="password"
                      placeholder="Enter your password"
                      onChange={(e) => {
                        onChange(e);
                      }}
                      onBlur={onBlur}
                      autocomplete="new-password"
                    />
                  )}
                />
                <span
                  className="btn btn-sm btn-icon  position-absolute translate-middle top-50 end-0 me-n2 opacity-25"
                  data-kt-password-meter-control="visibility"
                >
                  <i className="fa-regular fa-lock fs-2"></i>
                </span>
              </div>
              {errors?.password && (
                <span className="error-text text-danger mt-1">
                  {errors?.password?.message}
                </span>
              )}
            </div>
          </div>

          <div className="mb-7 fv-row fv-plugins-icon-container">
            <div className="mb-1">
              <div className="position-relative">
                <Controller
                  name={"repassword"}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      value={value}
                      className="form-control bg-white h-55px border border-gray-300 form-control-lg form-control-solid pe-15 rounded-55rem"
                      type="password"
                      placeholder="Confirm your password"
                      onChange={(e) => {
                        onChange(e);
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
                <span
                  className="btn btn-sm btn-icon  position-absolute translate-middle top-50 end-0 me-n2 opacity-25"
                  data-kt-password-meter-control="visibility"
                >
                  <i className="fa-regular fa-lock fs-2"></i>
                </span>
              </div>
              {errors?.repassword && (
                <span className="error-text text-danger mt-1">
                  {errors?.repassword?.message}
                </span>
              )}
            </div>

            {userId ? (
              <div
                style={disableResend ? { opacity: "0.5" } : {}}
                className="d-flex justify-content-end mb-3"
              >
                <p>{`Not received verification email ? `}</p>
                <span
                  onClick={resendEmailHandler}
                  className="ms-1 link-primary cursor-pointer"
                >{` Resend email`}</span>
              </div>
            ) : null}
          </div>

          <div class="fv-row mb-5">
            <span>
              I agree to Divsly's{" "}
              <Link href="#" class="ms-1 link-primary">
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link href="#" class="ms-1 link-primary">
                Privacy Policy
              </Link>
            </span>
          </div>

          <div className="d-flex justify-content-end mb-3">
            {message ? message : null}
          </div>

          <div className="text-center">
            <Button
              disabled={loading}
              color="info"
              className="btn btn-lg btn-info bg-brand w-100 h-55px rounded-55rem btn-hover-dark mb-5"
              type="submit"
            >
              {loading ? <Spinner /> : "Submit"}
            </Button>
          </div>
        </div>
      </Form>
    </AuthPageWrapper>
  );
};

export default SignupMain;
