import { createContext, useEffect, useState } from "react";
import UserSettingsApi from "../services/http/userSettings";
import SubscriptionApi from "../services/http/subscription";
import generateProfileImage from "../utils/generateProfileImage";

export const AuthContxt = createContext(null);

const AuthContxtProvider = (props) => {
    const [profile, setProfile] = useState({});
    const [quota, setQuota] = useState();

    const logout = () => {
        setProfile(null);
        localStorage.removeItem("user_details");
        localStorage.removeItem("auth_token");
    };

    const profileHandler = (data) => {
        setProfile(prevState => ({ ...prevState, ...data }));
    };

    const fetchProfile = async () => {
        try {
            const { data } = await UserSettingsApi.fetchProfile();
            const response = await UserSettingsApi.getLocation();
            let profileDetails = data?.data;
            profileDetails = { 
                ...profileDetails,
                mobile: profileDetails?.mobile || '',
                country: data?.country || response?.data?.data?.countryCode
            };
            if (!profileDetails?.avatar) {
                profileDetails = { 
                    ...profileDetails,
                    avatar: generateProfileImage(profileDetails.email)
                };
            }
            localStorage.setItem("user_details", JSON.stringify(profileDetails));
            setProfile(profileDetails);
        } catch (e) {
            console.log("Error while fetching profile: ", e);
        }
    };

    const fetchQuota = async () => {
        const token = localStorage?.getItem('auth_token');
        try {
            const { data } = await SubscriptionApi.getCurrentQuota();
            setQuota(data?.data);
        } catch (e) {
            console.log("Error while fetching quota: ", e);
        }
    };

    useEffect(() => {
        if (localStorage.getItem("auth_token")) {
            fetchProfile();
            fetchQuota();
        }
    }, []);

    return (
        <AuthContxt.Provider value={{
            profile,
            setProfile,
            profileHandler,
            fetchProfile,
            fetchQuota,
            logout,
            quota
        }}>
            {props.children}
        </AuthContxt.Provider>
    );
}

export default AuthContxtProvider;
