import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { InputGroup, InputGroupText, Input, Card, Spinner } from "reactstrap";
import Sidebar from "../../components/common/sidebar";
//import './EmailTemplateGallery.css'; // Include this for the styling
import EmailCampaignAPI from "../../services/http/emailCampaign";
import EmailTemplateAPI from "../../services/http/emailTemplate";
import { useNavigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import NotSearchFound from "../common/emptyScreens/notSearchFound";
import NotFound from "../common/emptyScreens/notFound";

const EmailTemplateGallery = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const emailCampaignId = searchParams.get('emailCampaignId');
    const currentPath = location.pathname;
  const [activeTab, setActiveTab] = useState("myTemplates");
  const [searchHide, setSearchHidee] = useState(false);
  const [userTemplates, setUserTemplates] = useState([]);
    const [galleryTemplates, setGalleryTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [campaignId, setCampaignId] = useState(0);
    const [loadingTemplateId, setLoadingTemplateId] = useState(null); // State to track loading status

  const toggleSearch = () => {
    setSearchHidee(!searchHide);
  };

  const updateEmailCampaign = async (id) => {
    try {
      setLoadingTemplateId(id);
      const auth_token = localStorage.getItem("auth_token");
      EmailCampaignAPI.updateEmailCampaign(auth_token, emailCampaignId, {emailTemplateId: id})
      .then(({ data }) => {
        console.log("created data", data);
        navigate(`/emailcampaignsettings/${emailCampaignId}`);
      })
      .catch((error) => {
        console.log("Error while fetching shortlinks", error);
      })
      .finally(() => {
        setLoadingTemplateId(null);
      });
    } catch (e) {
    console.log("Error while creating email campaign : ", e.message);
    setLoadingTemplateId(null);
  }
};

const getEmailTemplates = async () => {
    try {
        setLoading(true);
      const auth_token = localStorage.getItem("auth_token");
      EmailTemplateAPI.getAllEmailTemplates(auth_token, {})
      .then(({ data }) => {
        console.log("get email tempaltes", data)
        if(data.success) {
            setUserTemplates(data.resultData.userTemplates);
            setGalleryTemplates(data.resultData.templateGallery);
        }
      })
      .catch((error) => {
        console.log("Error while fetching shortlinks", error);
      })
      .finally(() => {
        setLoading(false);
      });
    } catch (e) {
    console.log("Error while creating email campaign : ", e.message);
  }
};

useEffect(() => {
    getEmailTemplates();
}, []);

useEffect(() => {
  if (location.state && location.state.from) {
    console.log("Redirected from:", location.state.from.pathname);
  }
}, [location]);

const handleClick = async (templateId) => {
    console.log("template id", templateId)
    
    await updateEmailCampaign(templateId);
    
}

const handleEdit = (templateId) => {
  console.log("template id", templateId)
    navigate(`/editemailtemplate/${templateId}?emailCampaignId=${emailCampaignId}`);
}

const handleAddNew = () => {
  navigate('/addEmailTemplate', { state: { from: location } });
};

const notLinksScreenMapper = {
  searchFound: <NotSearchFound type={"myTemplates"} />,
};


  const renderTemplates = () => (
    <div className="template-grid">
    <div className="template-item add-new-item" onClick={handleAddNew}>
      <div className="template-content">
        <i className="fa fa-plus fa-3x"></i>
      </div>
      <div className="template-actions">
            <button className="use-button">Add new template</button>
          </div>
    </div>
      {userTemplates.map((template) => (
        <div key={template.id} className="template-item">
          <div className="template-content image-content">
            <img
              src={template.imageUrl ? template.imageUrl : "https://divsly.com/appdemo/assets/media/email-templates/email-template-1.png"}
              alt=""
            />
            {console.log("template", template, loadingTemplateId)}
            {loadingTemplateId === template.id && (
              <div className="overlay">
              <Spinner/>
            </div>
            )}
          </div>
          <div className="template-actions">
            <button className="use-button" onClick={() => handleClick(template.id)}>Use Template</button>
            <button className="edit-button" onClick={() => handleEdit(template.id)}>Edit Template</button>
          </div>
        </div>
      ))}
    </div>
  );

  const renderTemplates1 = () => (
    <div className="template-grid">
      {galleryTemplates.length > 0 ?<>
      {galleryTemplates.map((template) => (
        <div key={template.id} className="template-item">
          <div className="template-content image-content">
            <img
              src={template.imageUrl ? template.imageUrl : "https://divsly.com/appdemo/assets/media/email-templates/email-template-1.png"}
              alt=""
            />
            {loadingTemplateId === template.id && (
              <div className="overlay">
              <Spinner/>
            </div>
            )}
          </div>
          <div className="template-actions">
            <button className="use-button" onClick={() => handleClick(template.id)}>Use Template</button>
            <button className="edit-button" onClick={() => handleEdit(template.id)}>Edit Template</button>
          </div>
        </div>
      ))}</>: <>
      {notLinksScreenMapper["searchFound"]}
      </>
    }
    </div>
  );
  return (
    <div>
      <div className="w-full d-flex">
        <div className="mt-md-10 w-[280px] me-md-7 mt-xs-0 me-xs-0">
          <Sidebar />
        </div>
        <div className="customDashboardSize">
          <div className="d-flex flex-column w-100 flex-root app-root">
            <div className="app-page flex-column flex-column-fluid">
              <div className="app-wrapper flex-column flex-row-fluid pe-3 pe-lg-0 me-lg-10 mt-lg-10 mt-0 position-relative">
                <div className="app-main flex-column flex-row-fluid">
                  <Card className="card card-flush" style={{height: "unset !important"}}>
                    <div className="ps-10 pt-10 d-flex flex-column justify-content-between w-100">
                      <h2 class="me-15 fs-2x mb-0">
                      <Link to={location.state && location.state.from ? location.state.from.pathname : "/emailcampaigns"}>
                        <a>
                          <i class="fal fa-circle-arrow-left fs-1 me-3 text-gray-600 text-hover-dark"></i>
                        </a>
                        </Link>
                        Select Email Template
                      </h2>
                      <div></div>
                    </div>
                    <div className="separator border-1 my-5"></div>
                    <div className="email-template-gallery tab-content mb-5 mx-5 px-5 w-100">
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div className="tabs">
                          <button
                            className={
                              activeTab === "myTemplates" ? "btn btn-info rounded-1 active" : ""
                            }
                            onClick={() => setActiveTab("myTemplates")}
                          >
                            <i class="fa fa-user-circle me-2"></i>{" "}
                            My Templates
                          </button>
                          <button
                            className={
                              activeTab === "templateGallery" ? "btn btn-info rounded-1 active" : ""
                            }
                            onClick={() => setActiveTab("templateGallery")}
                          >
                            <i class="fa fa-history me-2"></i>{" "}
                            Template Gallery
                          </button>
                        </div>
                        <div>
                          <InputGroup>
                            <Input
                              placeholder="Search..."
                              className={`headerinputsearch fw-normal form-control ${
                                searchHide ? "" : "d-none"
                              }`}
                            />
                            <InputGroupText
                              className="btn btn-icon rounded-1 bg-light-info me-2"
                              onClick={toggleSearch}
                            >
                              <i className="fa-solid fa-magnifying-glass fs-3"></i>
                            </InputGroupText>
                          </InputGroup>
                        </div>
                      </div>
                      <div className="template-content">
                        {loading ? (
                      <div
                      style={{
                        height: "70vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spinner />
                    </div>
                        ) : (<>
                          {activeTab == "myTemplates"
                            ? renderTemplates()
                            : renderTemplates1()}
                        </>)}

                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplateGallery;
