import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Dropdown,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Col,
  Row,
  FormGroup,
  Input,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classnames from "classnames";
import AbstractAPIImage from "../../assets/media/integrations/abstractapi.png";
import mailgunImage from "../../assets/media/integrations/mailgun.png";
import mailchimpImage from "../../assets/media/integrations/mailchimp.png";
import mailModoImage from "../../assets/media/integrations/mailmodo.png";
import amazonsesImage from "../../assets/media/integrations/amazonses.png";
import sendGridImage from "../../assets/media/integrations/sendgrid.png";
import sendinBlueImage from "../../assets/media/integrations/sendinblue.png";
import emailEngineImage from "../../assets/media/integrations/emailengine.png";
import twillioImage from "../../assets/media/integrations/twillio.png";
import smtpComImage from "../../assets/media/integrations/smtp.png";
import IntegrationsAPI from "../../services/http/integration";

const brands = [
  {
    name: "AbstractAPI",
    type: "email",
    image: AbstractAPIImage,
    description: "AbstractAPI is a robust API provider.",
    domain: true,
  },
  {
    name: "Mailgun",
    type: "email",
    image: mailgunImage,
    description: "Mailgun is an email automation service.",
    domain: true,
    apiKey: true,
  },
  {
    name: "SendGrid",
    type: "email",
    image: sendGridImage,
    description: "SendGrid provides cloud-based email services.",
    apiKey: true,
  },
  {
    name: "Amazon SES",
    type: "email",
    image: amazonsesImage,
    description: "Amazon SES is a cloud-based email service.",
    apiKey: true,
  },
  {
    name: "Twilio",
    type: "email",
    image: twillioImage,
    description: "Twilio is a communication platform for messaging.",
    apiKey: true,
  },
  {
    name: "SMTP.com",
    type: "email",
    image: smtpComImage,
    description: "SMTP.com offers email delivery services.",
    apiKey: true,
  },
  {
    name: "Email Engine",
    type: "email",
    image: emailEngineImage,
    description: "Email Engine helps manage your email workflows.",
    apiKey: true,
  },
  {
    name: "Brevo",
    type: "email",
    image: sendinBlueImage,
    description: "Brevo provides marketing automation.",
    apiKey: true,
  },
  {
    name: "Mailchimp",
    type: "email",
    image: mailchimpImage,
    description: "Mailchimp offers email marketing services.",
    apiKey: true,
  },
  {
    name: "Mailmodo",
    type: "email",
    image: mailModoImage,
    description: "Mailmodo is an email marketing platform.",
    apiKey: true,
  },
];

const brandMap = brands.reduce((acc, brand) => {
  acc[brand.name.toLowerCase()] = brand.name;
  return acc;
}, {});

console.log("brandMap", brandMap);

const IntegrationsCard = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [testSuccess, setTestSuccess] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [domain, setDomain] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [brandName, setBrandName] = useState({});
  const [integrations, setIntegrations] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentIntegrationId, setCurrentIntegrationId] = useState(null);

  useEffect(() => {
    if (activeTab === "1") {
      getAllIntegrations();
    }
  }, [activeTab]);

  const providerImages = {
    abstractapi: AbstractAPIImage,
    mailgun: mailgunImage,
    mailchimp: mailchimpImage,
    mailmodo: mailModoImage,
    amazonses: amazonsesImage,
    sendgrid: sendGridImage,
    brevo: sendinBlueImage,
    emailengine: emailEngineImage,
    twilio: twillioImage,
    smtpcom: smtpComImage,
  };

  const getAllIntegrations = async () => {
    try {
      const auth_token = localStorage.getItem("auth_token");
      const response = await IntegrationsAPI.getAllIntegrations(auth_token);
      let loadedIntegrations = [];
      if (
        response.data &&
        response.data.integrations &&
        response.data.integrations.length > 0
      ) {
        loadedIntegrations = response.data.integrations.map((integration) => {
          return { ...integration, image: providerImages[integration.name] };
        });
      }
      setIntegrations(loadedIntegrations || []);
    } catch (error) {
      console.error("There was an error fetching all integrations!", error);
    }
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleDropdown = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    setTestSuccess(false);
  };

  const handleActivateClick = (brand) => {
    setBrandName(brand);
    setApiKey("");
    setDomain("");
    setCurrentIntegrationId(null);
    setIsUpdate(false);
    toggleModal();
  };

  const handleUpdateClick = (integration) => {
    const brand = brands.find(
      (b) => b.name.toLowerCase() === integration.name.toLowerCase()
    );
    setBrandName(brand);
    setApiKey("");
    setDomain("");
    setIsUpdate(true);
    setCurrentIntegrationId(integration.id);
    toggleModal();
  };

  const handleDeleteClick = async (integrationId) => {
    console.log("delete clicked", integrationId);
    try {
      const auth_token = localStorage.getItem("auth_token");
      await IntegrationsAPI.deleteIntegration(auth_token, integrationId);
      setIntegrations(
        integrations.filter((integration) => integration.id !== integrationId)
      );
    } catch (error) {
      console.error("Error deleting integration:", error);
    }
  };

  const testConnection = async () => {
    if (
      (brandName.apiKey && apiKey === "") ||
      (brandName.domain && domain === "")
    ) {
      alert("Fill in all the fields");
      return;
    }
    setIsLoading(true);
    try {
      const auth_token = localStorage.getItem("auth_token");
      const response = await IntegrationsAPI.testIntegration(auth_token, {
        type: brandName.type,
        provider: brandName.name.toLowerCase(),
        credentials: { apiKey, domain },
      });
      console.log("response tset integrtation", response);
      if (response.status === 200) {
        setTestSuccess(true);
      } else {
        setTestSuccess(false);
      }
    } catch (error) {
      console.error("Error testing connection:", error);
      setTestSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      const auth_token = localStorage.getItem("auth_token");
      const response = await IntegrationsAPI.saveIntegration(auth_token, {
        type: brandName.type,
        provider: brandName.name.toLowerCase(),
        credentials: { apiKey, domain },
      });
      setIntegrations([
        ...integrations,
        { ...response.data, image: providerImages[response.data.name] },
      ]);
      toggleModal();
    } catch (error) {
      console.error("Error saving integration:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      const auth_token = localStorage.getItem("auth_token");
      const response = await IntegrationsAPI.updateIntegration(
        auth_token,
        currentIntegrationId,
        {
          type: brandName.type,
          name: brandName.name.toLowerCase(),
          credentials: { apiKey, domain },
        }
      );
      setIntegrations(
        integrations.map((integration) =>
          integration.id === currentIntegrationId
            ? { ...response.data, image: providerImages[response.data.name] }
            : integration
        )
      );
      toggleModal();
    } catch (error) {
      console.error("Error updating integration:", error);
    }
  };

  return (
    <div className="container mt-5">
      <Nav tabs>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggleTab("1");
            }}
          >
            My Integrations
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggleTab("2");
            }}
          >
            All Integrations
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent className="mt-5" activeTab={activeTab}>
        <TabPane tabId="1">
          <div className="row">
            {integrations &&
              integrations.map((integration, index) => (
                <div
                  key={integration.id}
                  className="col-md-4 col-12 rounded-2 pb-10 px-5"
                >
                  <Card className="border-gray-250 border rounded-2 p-8">
                    <div className="d-flex justify-content-between">
                      <div className="bg-light-info d-flex justify-content-center align-items-center h-50px w-50px rounded-1">
                        <img
                          className="w-100"
                          src={integration.image}
                          alt={brandMap[integration.name]}
                        />
                      </div>
                      <Dropdown
                        isOpen={dropdownOpen === integration.id}
                        toggle={() => toggleDropdown(integration.id)}
                      >
                        <DropdownToggle className="btn btn-icon rounded-1 bg-light-info">
                          <i className="fas fa-ellipsis text-gray-800 fs-2"></i>
                        </DropdownToggle>
                        <DropdownMenu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-info fw-semibold fs-7 w-150px py-4">
                          <DropdownItem
                            className="bg-hover-light-info"
                            onClick={() => handleUpdateClick(integration)}
                          >
                            Edit
                          </DropdownItem>
                          <DropdownItem
                            className="bg-hover-light-info"
                            onClick={() => handleDeleteClick(integration.id)}
                          >
                            Deactivate
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <div className="pt-lg-8 pt-4">
                      <CardTitle tag="h2" className="mb-5">
                      {brandMap[integration.name]}
                      </CardTitle>
                    </div>
                  </Card>
                </div>
              ))}
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div className="row">
            {brands.map((brand, index) => (
              <div
                className="col-md-4 col-12 rounded-2 pb-10 px-5"
                key={brand.name}
              >
                <Card className="border-gray-250 border rounded-2 p-8">
                  <div className="d-flex justify-content-between">
                    <div className="bg-light-info d-flex justify-content-center align-items-center h-50px w-50px rounded-1">
                      <img
                        className="w-100"
                        src={brand.image}
                        alt={brand.name}
                      />
                    </div>
                    <Dropdown
                      isOpen={dropdownOpen === index + 100}
                      toggle={() => toggleDropdown(index + 100)}
                    >
                      <DropdownToggle className="btn btn-icon rounded-1 bg-light-info">
                        <i className="fas fa-ellipsis text-gray-800 fs-2"></i>
                      </DropdownToggle>
                      <DropdownMenu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-info fw-semibold fs-7 w-150px py-4">
                        <DropdownItem
                          className="bg-hover-light-info"
                          onClick={() => handleActivateClick(brand)}
                        >
                          Activate
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="pt-lg-8 pt-4">
                    <CardTitle tag="h2" className="mb-5">
                      {brand.name}
                    </CardTitle>
                    <CardText className="fw-semibold fs-5 text-gray-700">
                      {brand.description}
                    </CardText>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </TabPane>
      </TabContent>

      {/* Modal for API Key Form */}
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        className="custom-modal-width"
      >
        <ModalHeader
          className="mb-4 mx-4 mt-2 border-bottom"
          toggle={toggleModal}
        >
          <h3 className="font-weight-bold">
            {isUpdate ? "Update API KEY" : "Add API KEY"}
          </h3>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <FormGroup>
                {brandName.apiKey && (
                  <>
                    <Label className="fs-5 fw-bold mb-2">API KEY</Label>
                    <Input
                      type="text"
                      className="form-control w-100 rounded-1 h-50px mb-5"
                      value={apiKey}
                      onChange={(e) => setApiKey(e.target.value)}
                    />
                  </>
                )}
                {brandName.domain && (
                  <>
                    <Label className="fs-5 fw-bold mb-2">Domain</Label>
                    <Input
                      type="text"
                      className="form-control w-100 rounded-1 h-50px mb-5"
                      value={domain}
                      onChange={(e) => setDomain(e.target.value)}
                    />
                  </>
                )}
              </FormGroup>
            </Col>
            <Col md={12} className="text-end">
              <Button
                color="secondary"
                className="rounded-1 me-2"
                onClick={testConnection}
                disabled={isLoading}
              >
                {isLoading ? "Testing..." : "Test Connection"}
              </Button>
              <Button
                color="info"
                className="rounded-1"
                disabled={!testSuccess}
                onClick={isUpdate ? handleUpdate : handleSave}
              >
                {isUpdate ? "Update Sender" : "Save Sender"}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default IntegrationsCard;
