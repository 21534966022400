import { useState } from "react";

const Copy = ({ copyData, handler = null, includeProtocol = true }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyHandler = async () => {
    try {
      setIsCopied(true);
      let data = copyData;
      if (handler) {
        data = handler();
      }
      if (!data.includes("https://") && includeProtocol) {
        data = `https://${data}`;
      }
      await navigator.clipboard.writeText(data);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    } catch (error) {
      console.error("Unable to copy to clipboard", error);
    }
  };

  return (
    <>
      {isCopied ? (
        <i class="fa-solid fa-check fs-2 ms-5 text-green-800 cursor-pointer"></i>
      ) : (
        <i
          className="fa-regular fa-clone fs-2 ms-5 text-gray-700 cursor-pointer"
          title="Copy"
          onClick={copyHandler}
        ></i>
      )}
    </>
  );
};

export default Copy;
