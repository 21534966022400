
import httpMethods from "..";


const SubscriptionApi = {
    createSubscription: async (payload) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .post(`/subscription`, payload, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    getCustomer: async () => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .get(`/customer`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    getSubscription: async () => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .get(`/subscription`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    resourceCreated: async () => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .get(`/resource-created`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    billingHistory: async (params) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .get(`/billing-history`, {
                params,
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
    getInvoice: async (params) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .get(`/invoice`, {
                params,
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },

    getDefaultPlan: async (param) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .get(`/default-plan/${param}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },

    getPlansPrices: async () => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .get(`/plans-prices`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },

    getPlanPrice: async (param) => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .get(`/plan-price/${param}`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },

    getCurrentQuota: async () => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .get(`/quota`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },

    getLatestBill: async () => {
        const token = localStorage?.getItem('auth_token');
        return httpMethods
            .get(`/latest-bill`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => res);
    },
}

export default SubscriptionApi;