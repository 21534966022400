const ProfileInfo = ({ values }) => {
  const {
    firstName = "",
    lastName = "",
    mobile = "",
    email = "",
    company = "",
    vatId = "",
  } = values || {};

  const userName = `${firstName !== null ? firstName : ""} ${
    lastName !== null ? lastName : ""
  }`;

  return (
    <div className={"d-block"}>
      <div className="row mb-7">
        <label className="col-lg-4 fw-semibold text-muted">Full Name</label>

        <div className="col-lg-8">
          <span className="fw-bold fs-6 text-gray-800">{userName}</span>
        </div>
      </div>

      <div className="row mb-7">
        <label className="col-lg-4 fw-semibold text-muted">Email</label>

        <div className="col-lg-8 fv-row">
          <span className="fw-semibold text-gray-800 fs-6">{email}</span>
        </div>
      </div>

      {mobile ? (
        <div className="row mb-7">
          <label className="col-lg-4 fw-semibold text-muted">Phone</label>

          <div className="col-lg-8 d-flex align-items-center">
            <span className="fw-bold fs-6 text-gray-800 me-2">{mobile}</span>
          </div>
        </div>
      ) : null}

      {company !== "" ? (
        <div className="row mb-7">
          <label className="col-lg-4 fw-semibold text-muted">Company</label>

          <div className="col-lg-8 d-flex align-items-center">
            <span className="fw-bold fs-6 text-gray-800 me-2">{company}</span>
          </div>
        </div>
      ) : null}

      {vatId !== "" ? (
        <div className="row mb-7">
          <label className="col-lg-4 fw-semibold text-muted">VAT ID</label>

          <div className="col-lg-8 d-flex align-items-center">
            <span className="fw-bold fs-6 text-gray-800 me-2">{vatId}</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProfileInfo;
