import { PROFILE_IMAGE_COLOR } from "./enum";

const generateProfileImage = (name, size = 200, color = PROFILE_IMAGE_COLOR) => {

    const initials = name.split(' ').map(word => word[0]).join('').toLowerCase();
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = canvas.height = size

    // Draw a background
    context.fillStyle = color;
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Draw initials
    context.fillStyle = '#ffffff';
    context.font = `bold ${size/2}px Arial`;
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText(initials, canvas.width / 2, canvas.height / 2);

    return canvas.toDataURL()
};

export default generateProfileImage;