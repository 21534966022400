import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";

const ModalWrapper = ({
  isOpen,
  modalHandler,
  data,
  rightBtnHandler,
  leftBtnHandler,
}) => {
  const { heading, description, leftBtn, rightBtn } = data;

  return (
    <Modal
      className="add_new_link_body rounded-3 modal-dialog-centered mw-500px"
      size="lg"
      isOpen={isOpen}
      toggle={modalHandler}
    >
      <ModalBody className="modal-body m-5 py-10">
        <div>
          <div className="d-flex justify-content-center">
            <div className="bg-light-danger rounded-circle p-3 w-50px h-50px d-flex justify-content-center align-items-center mb-5">
              <i className="fa-light fa-triangle-exclamation text-danger fs-1"></i>
            </div>
          </div>
          <div>
            <h2 className="fw-bold px-lg-0 mb-0 text-center mb-3">{heading}</h2>
            <p className="fw-bold px-lg-0 text-center mb-5">{description}</p>
          </div>
          <div className="d-flex justify-content-center mt-10">
            <Button
              color="danger"
              className="me-3 w-50 rounded-1"
              style={{ cursor: "pointer" }}
              onClick={leftBtnHandler}
            >
              {leftBtn}
            </Button>
            <Button
              color="light"
              className="border w-50 rounded-1"
              onClick={rightBtnHandler}
            >
              {rightBtn}
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalWrapper;
