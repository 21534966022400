import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
const mediaUrl = "https://divsly.com/assets/img/home-banner/short-links-sms-whatsapp.png";
const Share = ({ link }) => {
  const shortlink = link;
  const [isCopied, setIsCopied] = useState(false);
  const copyHandler = async () => {
    try {
      setIsCopied(true);
      await navigator.clipboard.writeText(shortlink);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    } catch (error) {
      console.error("Unable to copy to clipboard", error);
    }
  };

  return (
    <Dropdown className="d-inline nospce">
      <Dropdown.Toggle
        id="dropdown-autoclose-true"
        className="p-0"
        variant="bg-none"
      >
        <i className="fa-light fa-share-from-square text-gray-700 pb-1 fs-3"></i>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="menu-item px-3 w-300px d-flex gap-2 mb-2 pb-2">
          <input
            type="text"
            value={link}
            disabled
            className="form-control rounded-1"
          />
          <button type="button" onClick={copyHandler} className="btn btn-primary rounded-1">
            {isCopied ? "Copied" : "Copy"}
          </button>
        </div>
        <Dropdown.Item>
          <EmailShareButton url={shortlink}>
            <i className="fas fa-envelope me-2"></i>
            Email
          </EmailShareButton>
        </Dropdown.Item>
        <Dropdown.Item>
          <FacebookShareButton url={shortlink}>
            <i className="fab fa-facebook me-2"></i> Facebook
          </FacebookShareButton>
        </Dropdown.Item>
        <Dropdown.Item>
          <LinkedinShareButton url={shortlink}>
            {(shareCount) => (
              <span className="myShareCountWrapper">{shareCount}</span>
            )}
            <i className="fab fa-linkedin me-2"></i> Linkedin
          </LinkedinShareButton>
        </Dropdown.Item>
        {/* <Dropdown.Item>
          <PinterestShareButton url={shortlink} media={mediaUrl}>
            <i className="fab fa-pinterest me-2"></i> Pinterest
          </PinterestShareButton>
        </Dropdown.Item> */}
        <Dropdown.Item>
          <TwitterShareButton url={shortlink}>
            <i className="fab fa-twitter me-2"></i> Twitter
          </TwitterShareButton>
        </Dropdown.Item>
        <Dropdown.Item>
          <WhatsappShareButton url={shortlink}>
            <i className="fab fa-whatsapp me-2"></i>
            Whatsapp
          </WhatsappShareButton>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Share;
