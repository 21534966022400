import React, { useCallback, useContext, useState } from "react";
import {
  Col,
  Row,
  TabPane,
  Input,
  FormGroup,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Controller } from "react-hook-form";
import { generateRandomString } from "../../../utils/generateRandomString";
import titleFetcher from "../../../utils/titleFetcher";
import _debounce from "lodash/debounce";
import useCustomLinks from "../../../hooks/useCustomLinks";
import { AuthContxt } from "../../../store/authContxt";
import { PLAN } from "../../../utils/enum";

const CreateQr = ({ activeId, form, isEdit }) => {
  const { quota } = useContext(AuthContxt);
  const { control, setValue, formState, trigger, watch } = form;
  const { errors } = formState;
  const customLinks = useCustomLinks();
  const { edit } = watch();
  const [destinationValue, setDestinationValue] = useState('')
  const BASE_URL = process.env.REACT_APP_QR_BASE_URL;
  const getCustomDomainHost = (customLink) => {
    const { domain, subDomain, spare } = customLink;
    try {
      const url = new URL(domain);
      return spare === "No" ? `${subDomain}.${url.host}` : url.host;
    } catch (e) {
      console.log("Error while creating url : ", e);
      return domain;
    }
  };

  const customLinkHandler = (customLink) => {
    const brandedDomain = getCustomDomainHost(customLink);
    setValue("brandedDomain", brandedDomain);
  };

  const handleTitle = useCallback(
    _debounce(async (url) => {
      try {
        const title = await titleFetcher(url);
        setValue("title", title.title);
        setValue("metaDescription", title.metaDescription);
        trigger('destinationUrl')
      } catch (e) {
        console.log("Error while fetching title: ", e.message);
      }
    }, 500),
    []
  );

  const destinationUrlHandler = async (e) => {
    trigger("destinationUrl");
    const url = e.target.value;
    handleTitle(url);
    if (isEdit) return;

    const uniqueTag = generateRandomString(url);
    setValue("slashTag", uniqueTag);
    trigger("slashTag");
  };

  const isPlanStarter = isEdit && quota?.plan === PLAN.STARTER;
  const isPlanStandard = quota?.plan === PLAN.STANDARD;

  return (
    <TabPane tabId={activeId}>
      <div className="card-body px-0 mw-1000px pt-1">
        <div className="separator border-info mt-lg-5 mb-lg-8 mb-4 border-opacity-10"></div>
        <Row className="">
          <Col sm="12">
            <div className="rounded-1">
              <div className=" mb-lg-8 mb-5">
                <h3 className="fs-2">Enter Your Destination URL</h3>
              </div>
              <div>
                <FormGroup>
                  <div>
                    <Label className="fw-bold fs-5 mb-2">Destination URL</Label>
                    {isPlanStarter ||
                    (isPlanStandard && edit && edit >= quota?.linkEdit) ? (
                      <i className="fas ms-2 fa-lock"></i>
                    ) : null}
                  </div>
                  <Controller
                    className="w-100"
                    name="destinationUrl"
                    control={control}
                    render={({ field, fieldState }) => (
                      <div className="w-100">
                        <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                          <Input
                            {...field}
                            type="destinationUrl"
                            disabled={
                              isPlanStarter ||
                              (isPlanStandard && edit && edit >= quota?.linkEdit)
                            }
                            placeholder="https://www.example.com/enter-long-url"
                            onChange={(e) => {
                              if (!isPlanStarter) {
                                field.onChange(e);
                                destinationUrlHandler(e);
                                setDestinationValue(e.target.value)
                              }
                            }}
                            className="w-100 h-50px"
                          />
                          {destinationValue && <i
                            className={`fa ${fieldState.error ? 'fa-times' : 'fa-check'}`}
                            style={{
                              position: 'absolute',
                              right: '10px', 
                              top: '50%',
                              transform: 'translateY(-50%)',
                              fontSize: '16px', 
                              color: fieldState.error ? 'red' : 'green', 
                            }}
                          ></i>}
                        </div>
                        {fieldState.error ? (
                          <span className="error-text text-danger mt-1">
                            {fieldState.error.message}
                          </span>
                        ) : null}
                      </div>
                    )}
                  />
                  <span className="text-gray-400 mt-3">
                    {"Example ( https://www.example.com/enter-long-url )"}
                  </span>

                  {isPlanStandard ? (
                    <div className="d-flex justify-content-end mb-3 link-info">
                      {`Remaining edit limit for destination url is ${
                        quota?.linkEdit - edit < 0 ? 0 : quota?.linkEdit - edit
                      }`}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
              <div className="mt-lg-10 mt-7">
                <h3 className="fs-2">Short Link</h3>
                <p className="text-gray-700 w-75 fs-5">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui
                  ex labore consequuntur est voluptatibus, quisquam molestias
                  nostrum reprehenderi
                </p>
              </div>
              <div className="d-flex ">
                <label className="fw-bold fs-5 mb-2 mt-3 w-50">Domain</label>
                <label className="fw-bold fs-5 mb-2 mt-3 ps-3">
                  Custom Slug
                </label>
              </div>
              <div className="input-group justify-content-between mb-3 h-50px">
                <div className="custom-dropdown form-control p-0">
                  <Controller
                    name="brandedDomain"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <div>
                          <UncontrolledDropdown direction="down">
                            <DropdownToggle
                              disabled={isEdit}
                              caret
                              color="transparent"
                              className="p-3 w-100 text-start text-wrap-oneline h-50px"
                            >
                              {field.value || BASE_URL}
                            </DropdownToggle>
                            <DropdownMenu>
                              {customLinks &&
                                customLinks.length > 0 &&
                                customLinks?.map((dom) => (
                                  <DropdownItem
                                    key={dom.id}
                                    onClick={() => {
                                      !isEdit && customLinkHandler(dom);
                                    }}
                                  >
                                    {getCustomDomainHost(dom)}
                                  </DropdownItem>
                                ))}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                          {fieldState.error ? (
                            <span className="error-text text-danger">
                              {fieldState.error.message}
                            </span>
                          ) : null}
                        </div>
                      );
                    }}
                  />
                </div>
                <span className="input-group-text">/</span>
                <Controller
                  name="slashTag"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        disabled={isEdit}
                        onChange={(e) => {
                          if (!isEdit) {
                            field.onChange(e);
                            trigger("slashTag");
                          }
                        }}
                        placeholder="Generated Slash Tag"
                      />
                    </>
                  )}
                />
              </div>
              {errors.slashTag ? (
                <div style={{ textAlign: "end" }}>
                  <span className="error-text text-danger">
                    {errors.slashTag.message}
                  </span>
                </div>
              ) : null}

              <div className="mt-8">
                <FormGroup>
                  <Label className="fw-bold fs-5 mb-2">
                    Enter Title{" "}
                    <span className="text-gray-400">(Optional)</span>
                  </Label>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <Input
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          placeholder="Example : My Website QR Code"
                          className="h-50px"
                        />
                        {fieldState.error && (
                          <span className="error-text text-danger">
                            {fieldState.error.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </FormGroup>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </TabPane>
  );
};

export default CreateQr;
