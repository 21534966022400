import React from "react";
import EditForm from "./editForm";
import DrawerWrapper from "../../wrapper/drawerWrapper";

const EditShortLink = ({
  edittedData,
  edittedDataHandler,
  editShortLink,
  setEditShortLink,
}) => {
  return (
    <DrawerWrapper open={editShortLink} toggle={() => setEditShortLink(false)}>
      <EditForm
        edittedData={edittedData}
        edittedDataHandler={edittedDataHandler}
        setEditShortLink={setEditShortLink}
      />
    </DrawerWrapper>
  );
};

export default EditShortLink;
