import QrCodes from "./qrCodes";
import Sidebar from "../common/sidebar";
import { useContext, useEffect, useState } from "react";
import { AuthContxt } from "../../store/authContxt";
import { Modal, ModalBody } from "reactstrap";
import Onboarding from '../../pages/onBoarding';
import { useLocation } from "react-router-dom";


const Index = () => {
  const { profile } = useContext(AuthContxt);
  const location = useLocation()
  useEffect(()=>{
    setIsModalOpen(Object.keys(profile).length  != 0 && (!profile.demoTour2)  && (location?.state?.openModal == false ? false : true))
  },[profile])
  const toggleModal = () => setIsModalOpen(prev=>!prev);
  const [isModalOpen, setIsModalOpen] = useState(  Object.keys(profile).length != 0 && (!profile.demoTour2 )  && (location?.state?.openModal == false ? false : true)); 
  return (

    <div className="w-full d-flex ">
      <div className="mt-10 w-[280px] me-lg-7">
        <Sidebar />
      </div>
      <div className="customDashboardSize">
        <QrCodes />
      </div>
      <div className='onboarding-styles'>
      <Modal isOpen={isModalOpen} toggle={toggleModal} className="custom-modal">
       
       <ModalBody>
         <Onboarding closeModal = {()=>{setIsModalOpen(false)}}/>
       </ModalBody>
      
     </Modal>
     </div>
    </div>
  );
};

export default Index;
