import { useContext, useState } from "react";
import { LinkInBioContxt } from "../linkInBioContxt";

export const GOALS = [
  "Generate Revenue/Leads",
  "Increase traffic/leads",
  "Promote a cause",
  "Share Content",
  "Other",
];

const Goal = () => {
  const {
    bioPageForm: { watch, setValue },
  } = useContext(LinkInBioContxt);
  const { goal } = watch();
  const [customGoal, setCustomGoal] = useState("");

  const goalHandler = (value) => {
    const isGoalFound = goal?.some((item) => item === value);

    const updatedGoals = isGoalFound
      ? goal?.filter((item) => item !== value)
      : [...goal, value];

    setValue("goal", updatedGoals);
  };

  return (
    <>
      <div className="mt-10">
        <h3 className="fs-4 ms-2 mb-5">Select Your Goal</h3>
      </div>
      <div>
        {GOALS?.map((item) => {
          return (
            <label className="d-flex w-lg-fit-content border rounded-1 text-start me-lg-3 p-4 mb-3">
              <div className="d-flex align-items-center me-2">
                <div className="form-check form-check-custom form-check-info form-check-solid form-check-primary me-4">
                  <input
                    onClick={() => goalHandler(item)}
                    className="form-check-input activate-checked"
                    type="checkbox"
                    name="plan"
                    checked={goal?.includes(item)}
                  />
                </div>
                <div>
                  <p
                    className="d-flex align-items-center mb-0 fs-5 flex-wrap"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {item}
                  </p>
                </div>
              </div>
            </label>
          );
        })}
      </div>
      <div>
        {goal.includes("Other") ? (
          <input
            type="text"
            value={customGoal}
            onChange={(e) => setCustomGoal(e.target.value)}
            className="form-control h-50px rounded-1 w-lg-50 mt-1 ms-lg-2"
            placeholder="Write your goal (Optional)"
          />
        ) : null}
      </div>
    </>
  );
};

export default Goal;
