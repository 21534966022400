import { useEffect, useState } from "react";
import CustomLinksAPI from "../services/http/customLinks";

const useCustomLinks = () => {
    const BASE_URL = process.env.REACT_APP_QR_BASE_URL;
    const [customLinks, setCustomLinks] = useState([]);

    useEffect(() => {
        const getCustomLinks = async () => {
            const auth_token = localStorage.getItem("auth_token");
            CustomLinksAPI.getConnectedCustomLinks(auth_token)
                .then(({ data }) => {
                    if (data?.data?.length > 0) {
                        setCustomLinks(data?.data || []);
                    }
                })
                .catch((error) => {
                    console.log("Error: ", error);
                })
        };
        getCustomLinks();
    }, []);

    return [{ domain: `https://${BASE_URL}`, spare: "Yes" }, ...customLinks];
}

export default useCustomLinks;