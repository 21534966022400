import { useContext } from "react";
import example1 from "../../../assets/media/link-in-bio/examples/layout-1.svg";
import example2 from "../../../assets/media/link-in-bio/examples/layout-2.svg";
import example3 from "../../../assets/media/link-in-bio/examples/layout-3.svg";
import example4 from "../../../assets/media/link-in-bio/examples/layout-4.svg";
import { LinkInBioContxt } from "../linkInBioContxt";

const Layouts = [
  { id: 1, src: example1 },
  { id: 2, src: example2 },
  { id: 3, src: example3 },
  { id: 4, src: example4 },
];

const Layout = () => {
  const { bioPageForm } = useContext(LinkInBioContxt);
  const { setValue, watch } = bioPageForm;
  const { template } = watch();

  return (
    <div className="mt-lg-10 mt-6">
      <h3 className="fw-bolder fs-2 mb-lg-3 mb-7">Layouts</h3>
      <div className="border p-5 rounded-1">
        <div id="kt_docs_repeater_advanced">
          <div className="form-group">
            <div data-repeater-list="kt_docs_repeater_advanced">
              <div className="row">
                {Layouts?.map((item, index) => {
                  return (
                    <div
                      className="col-3 col-lg-2"
                      onClick={() => {
                        setValue("template", item.id);
                      }}
                      key={index}
                    >
                      <img
                        src={item?.src}
                        alt={item?.id}
                        className="me-2 w-100 cursor-pointer"
                        style={
                          +template === item.id
                            ? {
                                backgroundColor: "#e9e6f1",
                                padding: "5px",
                              }
                            : {}
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
