import UserSettings from "../../components/user-settings";
import PageWrapper from "../../components/wrapper/pageWrapper";

const Page = () => {
  return (
    <PageWrapper>
      <div className="px-6 px-lg-10 py-lg-10 py-5 d-flex items-center justify-content-between w-100 align-items-center">
        <h2 className="pl-4 mb-0 fs-2x">User Settings</h2>
      </div>
      <UserSettings />
    </PageWrapper>
  );
};

export default Page;
