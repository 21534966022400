import React, { useRef, useState, useEffect, useCallback } from "react";
import html2canvas from "html2canvas";
import {
  Modal,
  Button,
  Input,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import axios from "axios";
import ShortlinksDropdown from "./ShortlinksDropDown";
import EmailTemplateAPI from "../../services/http/emailTemplate";
import MergeTagsDropdown from "./MergeTagsDropdown";

import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";

let defaultModern = {
  body: {
    id: "lZMC9IR861",
    rows: [
      {
        id: "ErgX9Ygi-9",
        cells: [1],
        values: {
          _meta: { htmlID: "u_row_1", htmlClassNames: "u_row" },
          anchor: "",
          columns: false,
          padding: "0px",
          hideable: true,
          deletable: true,
          draggable: true,
          selectable: true,
          hideDesktop: false,
          duplicatable: true,
          backgroundColor: "",
          backgroundImage: {
            url: "",
            size: "custom",
            repeat: "no-repeat",
            position: "center",
            fullWidth: true,
            customPosition: ["50%", "50%"],
          },
          displayCondition: null,
          columnsBackgroundColor: "",
        },
        columns: [
          {
            id: "Tv0uWPpv_j",
            values: {
              _meta: { htmlID: "u_column_1", htmlClassNames: "u_column" },
              border: {},
              padding: "0px",
              borderRadius: "0px",
              backgroundColor: "",
            },
            contents: [],
          },
        ],
      },
    ],
    values: {
      _meta: { htmlID: "u_body", htmlClassNames: "u_body" },
      language: {},
      linkStyle: {
        body: true,
        linkColor: "#0000ee",
        linkUnderline: true,
        linkHoverColor: "#0000ee",
        linkHoverUnderline: true,
      },
      textColor: "#000000",
      fontFamily: { label: "Arial", value: "arial,helvetica,sans-serif" },
      popupWidth: "600px",
      popupHeight: "auto",
      borderRadius: "10px",
      contentAlign: "center",
      contentWidth: "500px",
      popupPosition: "center",
      preheaderText: "",
      backgroundColor: "#F7F8F9",
      backgroundImage: {
        url: "",
        size: "custom",
        repeat: "no-repeat",
        position: "center",
        fullWidth: true,
      },
      contentVerticalAlign: "center",
      popupBackgroundColor: "#FFFFFF",
      popupBackgroundImage: {
        url: "",
        size: "cover",
        repeat: "no-repeat",
        position: "center",
        fullWidth: true,
      },
      popupCloseButton_action: {
        name: "close_popup",
        attrs: {
          onClick:
            "document.querySelector('.u-popup-container').style.display = 'none';",
        },
      },
      popupCloseButton_margin: "0px",
      popupCloseButton_position: "top-right",
      popupCloseButton_iconColor: "#000000",
      popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
      popupCloseButton_borderRadius: "0px",
      popupCloseButton_backgroundColor: "#DDDDDD",
    },
    footers: [],
    headers: [],
  },
  counters: {
    u_row: 1,
    u_column: 1,
    u_content_text: 1,
    u_content_image: 1,
    u_content_button: 1,
    u_content_heading: 2,
  },
  schemaVersion: 16,
};

let mergeTags = {
  first_name: {
    name: "First Name",
    value: "{{first_name}}",
    sample: "{{first_name}}",
  },
  last_name: {
    name: "Last Name",
    value: "{{last_name}}",
    sample: "{{last_name}}",
  },
  email: {
    name: "Email",
    value: "{{email}}",
    sample: "{{email}}",
  },
  sms: {
    name: "SMS",
    value: "{{sms}}",
    sample: "{{sms}}",
  },
  date: {
    name: "Date",
    value: "{{date}}",
    sample: "{{date}}",
  },
};

const DragAndDropEmailEditor = (props) => {
  const emailEditorRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isJsonModalOpen, setIsJsonModalOpen] = useState(false);
  const [doneCallback, setDoneCallback] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  const [modernContent, setModernContent] = useState(
    JSON.stringify(defaultModern)
  );
  const [classicContent, setClassicContent] = useState(
    "<html><body><div>This is a legacy HTML template.</div></body></html>"
  );
  const [switched, setSwitched] = useState(false);
  const [isPasteModalOpen, setIsPasteModalOpen] = useState(false);
  const [htmlContent, setHtmlContent] = useState("");
  const [jsonContent, setJsonContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [lastCursorPosition, setLastCursorPosition] = useState(null);
  const [existingHtml, setExistingHtml] = useState(
    "<div>This is a legacy HTML template.</div>"
  );

  const toggleEditorMode = (e) => {
    e.preventDefault();
    const unlayer = emailEditorRef.current?.editor;
    if (unlayer) {
      const newMode = props.editorMode === "modern" ? "classic" : "modern";
      if (newMode == "classic") {
        unlayer.exportHtml((data) => {
          const { design, html } = data;
          setModernContent(JSON.stringify(design));
          unlayer.loadDesign({
            html: classicContent,
            classic: true,
          });
        });
        props.setEditorMode("classic");
      } else if (newMode == "modern") {
        unlayer.exportHtml((data) => {
          const { design, html } = data;
          setClassicContent(html);
          unlayer.loadDesign(JSON.parse(modernContent));
        });
        props.setEditorMode("modern");
      }
    }
  };

  const insertShortlink = (link) => {
    const unlayer = emailEditorRef.current?.editor;
    if (unlayer) {
      if (props.editorMode === "modern") {
        unlayer.exportHtml((data) => {
          const { design } = data;
          const updatedDesign = { ...design };

          // Ensure the body and rows structure exists
          if (!updatedDesign.body) {
            updatedDesign.body = { rows: [] };
          }
          if (!updatedDesign.body.rows) {
            updatedDesign.body.rows = [];
          }

          let customShortLink = link.slashTag
            ? `https://${link.brandedDomain}/${link.slashTag}`
            : `https://${link.pageDomain}/${link.pageLink}`;

          console.log("customShortLink", customShortLink);

          const newTag =
            link.type === "qr"
              ? "img"
              : `<a rel="noopener" href="${customShortLink}" target="_blank">${customShortLink}</a>`;

          // Add a new row with the QR image or link as a content element
          const newContent =
            link.type === "qr"
              ? {
                  id: `content_${Date.now()}`,
                  type: "image",
                  values: {
                    containerPadding: "10px",
                    anchor: "",
                    src: {
                      url: `https:${link.qr}`,
                      width: 150,
                      height: 150,
                      size: 150, // You can set the actual size if known
                    },
                    textAlign: "center",
                    altText: "QR Code",
                    action: {
                      name: "web",
                      values: {
                        href: "",
                        target: "_blank",
                      },
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _meta: {
                      htmlID: `u_content_image_${Date.now()}`,
                      htmlClassNames: "u_content_image",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    pending: false,
                  },
                }
              : {
                  id: `content_${Date.now()}`,
                  type: "text",
                  values: {
                    containerPadding: "10px",
                    anchor: "",
                    fontSize: "16px",
                    textAlign: "left",
                    lineHeight: "140%",
                    linkStyle: {
                      inherit: true,
                      linkColor: "#0000ee",
                      linkHoverColor: "#0000ee",
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _meta: {
                      htmlID: `u_content_text_${Date.now()}`,
                      htmlClassNames: "u_content_text",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: `<p style="line-height: 140%;"><a rel="noopener" href="${customShortLink}" target="_blank">${customShortLink}</a></p>`,
                    _languages: {},
                  },
                };

          if (lastCursorPosition) {
            const { position, contentId, text } = lastCursorPosition;

            // Find the content in the design JSON
            let found = false;
            for (const row of updatedDesign.body.rows) {
              for (const column of row.columns) {
                const content = column.contents.find(
                  (content) => content.id === contentId
                );
                if (content) {
                  if (link.type !== "qr") {
                    if (content.values && content.values.text) {
                      // Insert the new content near the last cursor position
                      const updatedText =
                        content.values.text.slice(0, position) +
                        newTag +
                        content.values.text.slice(position);
                      content.values.text = updatedText;
                      found = true;
                      break;
                    }
                  } else if (link.type === "qr") {
                    const contentIndex = column.contents.findIndex(
                      (content) => content.id === contentId
                    );
                    if (contentIndex !== -1) {
                      column.contents.splice(contentIndex + 1, 0, newContent);
                      found = true;
                      break;
                    }
                  }
                }
              }
              if (found) break;
            }
            if (!found) {
              updatedDesign.body.rows.push({
                id: `row_${Date.now()}`,
                cells: [1],
                columns: [
                  {
                    id: `column_${Date.now()}`,
                    contents: [newContent],
                    values: {
                      _meta: {
                        htmlID: `u_column_${Date.now()}`,
                        htmlClassNames: "u_column",
                      },
                      border: {},
                      padding: "0px",
                      borderRadius: "0px",
                      backgroundColor: "",
                    },
                  },
                ],
                values: {
                  displayCondition: null,
                  columns: false,
                  backgroundColor: "",
                  columnsBackgroundColor: "",
                  backgroundImage: {
                    url: "",
                    fullWidth: true,
                    repeat: "no-repeat",
                    size: "custom",
                    position: "center",
                    customPosition: ["50%", "50%"],
                  },
                  padding: "0px",
                  anchor: "",
                  hideDesktop: false,
                  _meta: {
                    htmlID: `u_row_${Date.now()}`,
                    htmlClassNames: "u_row",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              });
            }
          } else {
            // Add the new content as a new row if no cursor position is found
            updatedDesign.body.rows.push({
              id: `row_${Date.now()}`,
              cells: [1],
              columns: [
                {
                  id: `column_${Date.now()}`,
                  contents: [newContent],
                  values: {
                    _meta: {
                      htmlID: `u_column_${Date.now()}`,
                      htmlClassNames: "u_column",
                    },
                    border: {},
                    padding: "0px",
                    borderRadius: "0px",
                    backgroundColor: "",
                  },
                },
              ],
              values: {
                displayCondition: null,
                columns: false,
                backgroundColor: "",
                columnsBackgroundColor: "",
                backgroundImage: {
                  url: "",
                  fullWidth: true,
                  repeat: "no-repeat",
                  size: "custom",
                  position: "center",
                  customPosition: ["50%", "50%"],
                },
                padding: "0px",
                anchor: "",
                hideDesktop: false,
                _meta: {
                  htmlID: `u_row_${Date.now()}`,
                  htmlClassNames: "u_row",
                },
                selectable: true,
                draggable: true,
                duplicatable: true,
                deletable: true,
                hideable: true,
              },
            });
          }

          unlayer.loadDesign(updatedDesign);
        });
      } else if (props.editorMode === "classic") {
        console.log("inserting short link, classic mode");
        unlayer.exportHtml((data) => {
          const { html } = data;
          let shortlink = link.slashTag
            ? `https://${link.brandedDomain}/${link.slashTag}`
            : `https://${link.pageDomain}/${link.pageLink}`;
          let qrlink = `https:${link.qr}`;
          const htmlContent =
            link.type === "qr"
              ? `<img src="${qrlink}" alt="QR Code" style="width: 150px; height: 150px;" />`
              : `<a href="${shortlink}">${shortlink}</a>`;

          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");
          const bodyText = doc.body.innerHTML;

          if (lastCursorPosition) {
            const { position, text } = lastCursorPosition;

            const updatedText =
              bodyText.slice(0, position) +
              htmlContent +
              bodyText.slice(position);
            doc.body.innerHTML = updatedText;
          } else {
            doc.body.innerHTML += htmlContent;
          }
          setExistingHtml(String.valueOf(doc.body.innerHTML));

          const updatedHtml = new XMLSerializer().serializeToString(doc);

          unlayer.loadDesign({
            html: updatedHtml,
            classic: true,
          });
        });
      }
    }
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const exportHtml = async () => {
    const unlayer = emailEditorRef.current?.editor;
    if (!unlayer) {
      console.error("Unlayer editor is not initialized");
      props.setShouldExport(false);
      return;
    }

    unlayer.exportHtml((data) => {
      const { design, html } = data;

      // Create a temporary container to hold the HTML content
      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = html;
      document.body.appendChild(tempContainer);

      // Convert the HTML content to an image
      html2canvas(tempContainer, {
        useCORS: true,
        allowTaint: true,
        logging: true,
        backgroundColor: null,
      })
        .then((canvas) => {
          canvas.toBlob(async (blob) => {
            const formData = new FormData();
            formData.append("file", blob);
            const auth_token = localStorage.getItem("auth_token");
            const response = await EmailTemplateAPI.fileUpload(
              auth_token,
              formData
            );
            console.log("response", response);
            const imageUrl = response.data.url;
            props.handleClick({
              name: "Sample Template 1",
              json: design,
              content: html,
              imageUrl,
            });
          }, "image/png");
        })
        .catch((err) => {
          console.error("Error generating image:", err);
          document.body.removeChild(tempContainer);
          props.setShouldExport(false);
        });
    });
  };

  const onLoad = (unlayer) => {
    if (switched) return;
    if (props.emailTemplate && props.emailTemplate.json) {
      unlayer.loadDesign(props.emailTemplate.json);
    } else if (props.emailTemplate && props.emailTemplate.content) {
      unlayer.loadDesign({
        html: props.emailTemplate.content,
        classic: true,
      });
      props.setEditorMode("classic");
    }
    setSwitched(true);
  };

  const handleContentChange = useCallback(
    (data) => {
      const unlayer = emailEditorRef.current?.editor;
      const { item, changes, type } = data;
      if (type === "content:modified") {
        if (item.type === "text" || item.type === "heading") {
          const originalText = item.values.text;
          const newText = changes.value;

          let diffText = "";
          let diffPosition = 0;
          let i = 0,
            j = 0;
          while (i < originalText.length && j < newText.length) {
            if (originalText[i] !== newText[j]) {
              diffText = newText.slice(i);
              diffPosition = i;
              break;
            }
            i++;
            j++;
          }

          let diffEndPosition = newText.length;
          let x = originalText.length - 1,
            y = newText.length - 1;
          while (x >= 0 && y >= 0) {
            if (originalText[x] !== newText[y]) {
              diffEndPosition = y + 1;
              break;
            }
            x--;
            y--;
          }
          if (j === newText.length) {
            diffEndPosition = newText.length;
          }

          if (diffText) {
            setLastCursorPosition({
              position:
                diffEndPosition >= diffPosition
                  ? diffEndPosition
                  : diffPosition,
              contentId: item.id,
              text: diffText,
            });
          } else {
            setLastCursorPosition({
              position: newText.length - 4,
              contentId: item.id,
              text: newText,
            });
          }
        } else {
          setLastCursorPosition(null);
        }
      } else if (type === "html:updated") {
        unlayer.exportHtml((dataClassic) => {
          const { html } = dataClassic;
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");
          const bodyText = doc.body.innerHTML;

          const originalText = existingHtml.trim();
          const newText = bodyText.trim();
          setExistingHtml(newText);


          let diffText = "";
          let diffPosition = 0;
          let i = 0,
            j = 0;
          while (i < originalText.length && j < newText.length) {
            if (originalText[i] !== newText[j]) {
              console.log("not match", originalText[i], newText[j]);
              diffText = newText.slice(i);
              diffPosition = i;
              break;
            }
            i++;
            j++;
          }
          let diffEndPosition = newText.length;
          let x = originalText.length - 1,
            y = newText.length - 1;
          while (x >= 0 && y >= 0) {
            if (originalText[x] !== newText[y]) {
              diffEndPosition = y + 1;
              break;
            }
            x--;
            y--;
          }
          if (j === newText.length) {
            diffEndPosition = newText.length;
          }
          if (diffText) {
            setLastCursorPosition({
              position:
                diffEndPosition >= diffPosition
                  ? diffEndPosition
                  : diffPosition,
              text: diffText,
            });
          } else {
            setLastCursorPosition({
              position: newText.length,
              text: newText,
            });
          }
        });
      }
    },
    [existingHtml, props.editorMode]
  );

  useEffect(() => {
    const unlayer = emailEditorRef.current?.editor;
    if (unlayer) {
      unlayer.addEventListener("design:updated", handleContentChange);
    }
  }, [handleContentChange]);

  const onReady = (unlayer) => {
    // unlayer.setMergeTags(mergeTags);

    unlayer.registerCallback("selectImage", function (data, done) {
      setDoneCallback(() => (args) => done(args));
      toggleModal();
    });
    unlayer.addEventListener("design:updated", function (data) {
      handleContentChange(data);
    });
    setLoading(false);
  };

  const handleImageSelect = (url) => {
    console.log("url", url);
    if (doneCallback) {
      console.log("done call back", doneCallback({ url }));
      doneCallback({ url });
      setDoneCallback(null);
    }
    toggleModal();
  };

  const handleUrlChange = (e) => {
    setImageUrl(e.target.value);
  };

  const handleUrlSubmit = () => {
    handleImageSelect(imageUrl);
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploading(true);
      try {
        const formData = new FormData();
        formData.append("file", file);
        const auth_token = localStorage.getItem("auth_token");
        const response = await EmailTemplateAPI.fileUpload(
          auth_token,
          formData
        );
        console.log("response", response);
        handleImageSelect(response.data.url);
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setUploading(false);
      }
    }
  };

  const handlePaste = async (e) => {
    const items = e.clipboardData.items;
    for (const item of items) {
      if (item.type.indexOf("image") !== -1) {
        const file = item.getAsFile();
        if (file) {
          setUploading(true);
          try {
            const formData = new FormData();
            formData.append("file", file);

            const response = await axios.post("/api/upload", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });

            handleImageSelect(response.data.url);
          } catch (error) {
            console.error("Error uploading file:", error);
          } finally {
            setUploading(false);
          }
        }
      }
    }
  };

  const togglePasteModal = () => setIsPasteModalOpen(!isPasteModalOpen);

  const toggleJsonModal = () => setIsJsonModalOpen(!isJsonModalOpen);

  const handleHtmlChange = (e) => {
    setHtmlContent(e.target.value);
  };

  const handleJsonChange = (e) => {
    console.log("e.target.value", e.target.value);
    setJsonContent(e.target.value);
  };

  const handleLoadHtml = () => {
    const unlayer = emailEditorRef.current?.editor;
    if (unlayer) {
      unlayer.loadDesign({
        html: htmlContent,
        classic: true,
      });
      setIsPasteModalOpen(false);
      setHtmlContent("");
    }
  };

  const handleLoadJson = () => {
    const unlayer = emailEditorRef.current?.editor;
    console.log("jsonContent");
    try {
      // Assuming jsonContent is a string representation of the jsonb data
      let parsedJson = JSON.parse(jsonContent);
      if (unlayer) {
        if (parsedJson && typeof parsedJson === 'object') {
          unlayer.loadDesign(parsedJson);
          setIsJsonModalOpen(false);
          setJsonContent("");
        } else {
          throw new Error("Invalid JSON content");
        }
      }
    } catch (error) {
      console.error("Error loading JSON:", error);
      alert("Invalid JSON");
      unlayer.loadDesign({});
      setIsJsonModalOpen(false);
    }
  };

  const handleMergeTagSelect = (mergeTag) => {
    console.log("mergetag", mergeTag, mergeTag.value);
    const unlayer = emailEditorRef.current?.editor;
    if (unlayer) {
      if (props.editorMode === "modern") {
        unlayer.exportHtml((data) => {
          const { design } = data;
          const updatedDesign = { ...design };

          // Ensure the body and rows structure exists
          if (!updatedDesign.body) {
            updatedDesign.body = { rows: [] };
          }
          if (!updatedDesign.body.rows) {
            updatedDesign.body.rows = [];
          }

          const newTag = mergeTag.value;

          if (lastCursorPosition) {
            const { position, contentId, text } = lastCursorPosition;

            // Find the content in the design JSON
            let found = false;
            for (const row of updatedDesign.body.rows) {
              for (const column of row.columns) {
                const content = column.contents.find(
                  (content) => content.id === contentId
                );
                if (content) {
                  if (content.values && content.values.text) {
                    // Insert the new content near the last cursor position
                    const updatedText =
                      content.values.text.slice(0, position) +
                      newTag +
                      content.values.text.slice(position);
                    content.values.text = updatedText;
                    found = true;
                    break;
                  }
                }
              }
              if (found) break;
            }
            if (!found) {
              const newContent = {
                id: `content_${Date.now()}`,
                type: "text",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  fontSize: "16px",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: `u_content_text_${Date.now()}`,
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: `<p style="line-height: 140%;">${newTag}</p>`,
                  _languages: {},
                },
              };

              updatedDesign.body.rows.push({
                id: `row_${Date.now()}`,
                cells: [1],
                columns: [
                  {
                    id: `column_${Date.now()}`,
                    contents: [newContent],
                    values: {
                      _meta: {
                        htmlID: `u_column_${Date.now()}`,
                        htmlClassNames: "u_column",
                      },
                      border: {},
                      padding: "0px",
                      borderRadius: "0px",
                      backgroundColor: "",
                    },
                  },
                ],
                values: {
                  displayCondition: null,
                  columns: false,
                  backgroundColor: "",
                  columnsBackgroundColor: "",
                  backgroundImage: {
                    url: "",
                    fullWidth: true,
                    repeat: "no-repeat",
                    size: "custom",
                    position: "center",
                    customPosition: ["50%", "50%"],
                  },
                  padding: "0px",
                  anchor: "",
                  hideDesktop: false,
                  _meta: {
                    htmlID: `u_row_${Date.now()}`,
                    htmlClassNames: "u_row",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              });
            }
          } else {
            // Add the new content as a new row if no cursor position is found
            const newContent = {
              id: `content_${Date.now()}`,
              type: "text",
              values: {
                containerPadding: "10px",
                anchor: "",
                fontSize: "16px",
                textAlign: "left",
                lineHeight: "140%",
                linkStyle: {
                  inherit: true,
                  linkColor: "#0000ee",
                  linkHoverColor: "#0000ee",
                  linkUnderline: true,
                  linkHoverUnderline: true,
                },
                hideDesktop: false,
                displayCondition: null,
                _meta: {
                  htmlID: `u_content_text_${Date.now()}`,
                  htmlClassNames: "u_content_text",
                },
                selectable: true,
                draggable: true,
                duplicatable: true,
                deletable: true,
                hideable: true,
                text: `<p style="line-height: 140%;">${newTag}</p>`,
                _languages: {},
              },
            };

            updatedDesign.body.rows.push({
              id: `row_${Date.now()}`,
              cells: [1],
              columns: [
                {
                  id: `column_${Date.now()}`,
                  contents: [newContent],
                  values: {
                    _meta: {
                      htmlID: `u_column_${Date.now()}`,
                      htmlClassNames: "u_column",
                    },
                    border: {},
                    padding: "0px",
                    borderRadius: "0px",
                    backgroundColor: "",
                  },
                },
              ],
              values: {
                displayCondition: null,
                columns: false,
                backgroundColor: "",
                columnsBackgroundColor: "",
                backgroundImage: {
                  url: "",
                  fullWidth: true,
                  repeat: "no-repeat",
                  size: "custom",
                  position: "center",
                  customPosition: ["50%", "50%"],
                },
                padding: "0px",
                anchor: "",
                hideDesktop: false,
                _meta: {
                  htmlID: `u_row_${Date.now()}`,
                  htmlClassNames: "u_row",
                },
                selectable: true,
                draggable: true,
                duplicatable: true,
                deletable: true,
                hideable: true,
              },
            });
          }

          unlayer.loadDesign(updatedDesign);
        });
      } else if (props.editorMode === "classic") {
        unlayer.exportHtml((data) => {
          const { html } = data;

          // Parse the HTML content
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");
          const bodyText = doc.body.innerHTML;

          if (lastCursorPosition) {
            const { position, text } = lastCursorPosition;

            // Insert the new content near the last cursor position
            const updatedText =
              bodyText.slice(0, position) +
              mergeTag.value +
              bodyText.slice(position);
            doc.body.innerHTML = updatedText;
          } else {
            // Add the new content at the end if no cursor position is found
            doc.body.innerHTML += mergeTag.value;
          }
          setExistingHtml(String.valueOf(doc.body.innerHTML));

          // Serialize the updated HTML back to a string
          const updatedHtml = new XMLSerializer().serializeToString(doc);

          unlayer.loadDesign({
            html: updatedHtml,
            classic: true,
          });
        });
      }
    }
  };

  useEffect(() => {
    if (props.shouldExport) {
      exportHtml();
    }
  }, [props.shouldExport]);

  return (
    <div>
      {loading && (
        <div className="spinner-container">
          <Spinner color="primary" />
        </div>
      )}
      <div className="d-flex justify-content-end gap-3 mb-5">
        {props.editorMode === "classic" && (
          <Button
            onClick={togglePasteModal}
            className="btn text-nowrap rounded-1 btn-info text-white"
            disabled={loading}
          >
            Paste HTML
          </Button>
        )}
        {props.noClassic && (
          <Button
            onClick={toggleJsonModal}
            className="btn text-nowrap rounded-1 btn-info text-white"
            disabled={loading}
          >
            Import Unlayer JSON
          </Button>
        )}
        <MergeTagsDropdown
          mergeTags={mergeTags}
          onSelect={handleMergeTagSelect}
          disabled={loading}
        />
        {!props.noClassic && (
          <ShortlinksDropdown onSelect={insertShortlink} disabled={loading} />
        )}
        {!props.noClassic && (
          <button
            type="button"
            disabled={loading}
            className="btn text-nowrap rounded-1 btn-secondary"
            onClick={(e) => toggleEditorMode(e)}
          >
            {props.editorMode === "modern"
              ? "Switch to Classic"
              : "Switch to Modern"}
          </button>
        )}
      </div>

      <EmailEditor ref={emailEditorRef} onReady={onReady} onLoad={onLoad} minHeight="665px" options={{
          tools: {
            form: {
              enabled: true
            }
          }
        }}/>

      <Modal isOpen={isModalOpen} toggle={toggleModal} onPaste={handlePaste}>
        <div className="modal-header">
          <h5 className="modal-title">Select Image</h5>
          <button type="button" className="close" onClick={toggleModal}>
            <span>&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label htmlFor="imageUrl">Enter Image URL</label>
            <Input
              type="text"
              id="imageUrl"
              value={imageUrl}
              onChange={handleUrlChange}
              placeholder="https://example.com/image.jpg"
            />
            <Button
              color="primary"
              onClick={handleUrlSubmit}
              disabled={!imageUrl}
            >
              Add Image
            </Button>
          </div>
          <div className="form-group">
            <label htmlFor="fileUpload">Upload Image</label>
            <Input type="file" id="fileUpload" onChange={handleFileUpload} />
            {uploading && <p>Uploading...</p>}
          </div>
        </div>
        <div className="modal-footer">
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </div>
      </Modal>

      <Modal isOpen={isJsonModalOpen} toggle={toggleJsonModal}>
        <ModalHeader toggle={toggleJsonModal}>Paste Unayer JSON</ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            value={jsonContent}
            onChange={handleJsonChange}
            rows="10"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleLoadJson}>
            OK
          </Button>
          <Button color="secondary" onClick={toggleJsonModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={isPasteModalOpen} toggle={togglePasteModal}>
        <ModalHeader toggle={togglePasteModal}>Paste HTML</ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            value={htmlContent}
            onChange={handleHtmlChange}
            rows="10"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleLoadHtml}>
            OK
          </Button>
          <Button color="secondary" onClick={togglePasteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DragAndDropEmailEditor;
