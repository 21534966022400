import { countries } from "countries-list";

const getMappedCountriesData = () => {

    const mappedData = Object.keys(countries || {})?.map((code) => {
        const { name } = countries[code];
        return ({ name, code });
    })

    return mappedData;
}

export default getMappedCountriesData

