import { useEffect, useState } from "react";
import AdminAPI from "../../services/http/admin";
import capitalizeFirstLetter from "../../utils/captilizeFirstLetter";
import { Spinner } from "reactstrap";
import dayjs from "dayjs";

const RecentSubscriptions = () => {
  const [loading, setLoading] = useState(false);
  const [recentSubscriptions, setRecentSubscription] = useState(0);

  const fetchRecentSubscriptions = async (
    startDate = new Date().setDate(new Date().getDate() - 30),
    endDate = new Date()
  ) => {
    try {
      setLoading(true);
      const response = await AdminAPI.getRecentSubscriptions({
        startDate,
        endDate,
      });
      if (!response?.data?.error) {
        setRecentSubscription(response?.data?.data);
      }
      setLoading(false);
    } catch (e) {
      console.log("Error while fetching sales: ", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRecentSubscriptions();
  }, []);

  return (
    <div
      className="card card-flush"
      style={{ borderRadius: "0px 0px 15px 15px" }}
    >
      <div className="card-header mt-6">
        <div className="card-title flex-column">
          <h3 className="fw-bold mb-1">Recent Subscription</h3>
        </div>
      </div>
      <div className="card-body p-9 pt-5">
        <div>
          <table className="table table-row-bordered">
            <thead>
              <tr>
                <td className="fw-bold">Plan Type</td>
                <td className="fw-bold">Name</td>
                <td className="fw-bold">Date &amp; Time</td>
                <td className="fw-bold">Payment Mode</td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <Spinner />
              ) : (
                recentSubscriptions.length > 0 &&
                recentSubscriptions?.map((item, index) => {
                  const { plan, planDuration, createdAt, name } = item || {};
                  return (
                    <tr key={index}>
                      <td>{`${capitalizeFirstLetter(plan)} Plan`}</td>
                      <td>{name}</td>
                      <td>{dayjs(createdAt).format("MMM DD, YYYY - HH:MM")}</td>
                      <td className="text-info">{planDuration}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RecentSubscriptions;
