import React, { useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';

// Node width
const nodeWidth = 120;

const nodeStyle = {
  padding: 10,
  border: '1px solid #ddd',
  borderRadius: 5,
  minWidth: nodeWidth,
  maxWidth: 'content',
  boxSizing: 'border-box',
  background: '#faf3ff',
  color: 'purple'
};

const handleStyle = {
  background: 'purple',
  width: 10,
  height: 10,
  borderRadius: '50%'
};

const EditableNode = ({ data, id, isSource, onDeleteEdge }) => {
  const [isEditingLabel, setIsEditingLabel] = useState(false);
  const [label, setLabel] = useState(data.label);

  const handleClick = () => {
    if (data.onEdit) {
      setIsEditingLabel(true);
      data.onEdit(id); // Notify parent to set the current node being edited
    }
  };

  const handleChange = (e) => {
    setLabel(e.target.value);
  };

  const handleBlur = () => {
    if (data.onUpdateLabel) {
      setIsEditingLabel(false);
      data.onUpdateLabel(id, label); // Update the label in the parent component
    }
  };

  const handleEdgeRemoval = () => {
    console.log(data)
    if (data.edgeId && data.onDeleteEdge) {
      console.log(data)
      data.onDeleteEdge(data.edgeId);
    }
  };

  return (
    <div style={nodeStyle} onClick={handleClick}>
      {isEditingLabel ? (
        <input
          type="text"
          value={label}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          style={{
            width: '100%',
            border: 'none',
            background: 'transparent',
            textAlign: 'center',
          }}
        />
      ) : (
        label
      )}
      <Handle
        onClick={handleEdgeRemoval}
        type={isSource ? "source" : "target"}
        position={isSource ? Position.Right : Position.Left}
        style={handleStyle}
      />
    </div>
  );
};

const SourceNode = (props) => <EditableNode {...props} isSource={true} onDeleteEdge={props.onDeleteEdge} />;
const TargetNode = (props) => <EditableNode {...props} isSource={false} onDeleteEdge={props.onDeleteEdge} />;

export { SourceNode, TargetNode };
