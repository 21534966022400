import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select/creatable';
import { Button, FormGroup, Label } from 'reactstrap';
import TagsApi from "../../services/http/tags";
import { AuthContxt } from "../../store/authContxt";

const MultiSelectCreatableComponent = (props) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [userTags, setUserTags] = useState([]);
  const { profile } = useContext(AuthContxt);
  const { id } = profile || {};

  useEffect(() => {
    const getUserTags = async () => {
      try {
        const payload = {
          userId: id,
          search: '',
          order: 'id',
        };

        const response = await TagsApi.getTags(payload);

        if (response.status === 200) {
          const tags = response?.data || [];
          const tagOptions = tags.map(tag => ({
            value: tag.name,
            label: tag.name,
          }));
          setUserTags(tagOptions);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (id) {
      getUserTags();
    }
  }, [id]);

  useEffect(() => {
    if (props.toAddress && Array.isArray(props.toAddress)) {
      const initialSelectedOptions = props.toAddress.map(value => ({
        value: value,
        label: value,
      }));
      setSelectedOptions(initialSelectedOptions);
    }
  }, [props.toAddress]);

  const handleChange = (selected) => {
    setSelectedOptions(selected, () => {
      console.log(selectedOptions);
      props.setToAddress(selectedOptions.map(option => option.value));
    });
  };

  const handleSubmit = () => {
    const selectedAndCreatedValues = selectedOptions.map(option => option.value);
    props.handleTo(selectedAndCreatedValues);
  };

  return (
    <div>
      <FormGroup>
        <Label for="multiSelect">Select or Add Options</Label>
        <Select
          id="multiSelect"
          options={userTags} // Use userTags for the options
          isMulti
          value={selectedOptions}
          onChange={handleChange}
          placeholder="Select or add options..."
          onCreateOption={(inputValue) => {
            const newOption = { value: inputValue.toLowerCase(), label: inputValue };
            setSelectedOptions([...selectedOptions, newOption]);
          }}
        />
      </FormGroup>

      <Button color="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </div>
  );
};

export default MultiSelectCreatableComponent;