import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import TermsImage from "../../assets/media/acceptTerms.jpg";
import divslyLogo from "../../assets/media/divsly.svg";
import divslyIcon from "../../assets/media/divsly-icon.svg";
import * as yup from "yup";
import { PHONE_REGEX } from "../../utils/enum";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, Label } from "reactstrap";
import { AuthContxt } from "../../store/authContxt";
import UserSettingsApi from "../../services/http/userSettings";
import { useNavigate } from "react-router-dom";

const AcceptTerms = () => {
  const { profile, profileHandler } = useContext(AuthContxt);
  const navigate = useNavigate();
  const [phoneRegex, setPhoneRegex] = useState("");
  const updateProfileValidation = yup.object().shape({
    useracceptedterms: yup.bool().oneOf([true], "Please accept the terms"),
    mobile: yup
      .string()
      .required()
      .matches(phoneRegex, `Please enter valid phone no.`),
  });
  const form = useForm({
    resolver: yupResolver(updateProfileValidation),
    defaultValues: {
      mobile: "",
      useracceptedterms: false,
    },
  });

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
    control,
  } = form;

  const [phone, setPhone] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);

  const { mobile } = watch();

  const getCountryCode = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      setCountryCode(response.data.country_code.toLowerCase());
    } catch (error) {
      console.error("Error fetching country code:", error);
    }
  };

  useState(() => {
    getCountryCode();
  }, []);

  const submitHandler = async (formData) => {
    try {
      setLoading(true);
      const updatedPayload = {
        ...profile,
        ...formData,
        mobile: formData?.mobile + "",
      };
      await UserSettingsApi.updateProfile(updatedPayload);
      let localData = localStorage.getItem("user_details");
      localData = JSON.parse(localData);
      localData = { ...localData, ...updatedPayload };
      localStorage.setItem("user_details", JSON.stringify(localData));
      reset();
      setLoading(false);
      return navigate("/complete-profile");
    } catch (e) {
      console.log("Error while updating profile: ", e);
      setLoading(false);
    }
  };

  return (
    <Container fluid className="bg-white overflow-hidden px-0">
      <Row className="d-flex align-items-center">
        <Col md={6} className="text-center px-5">
          <img
            src={TermsImage}
            alt="Left Side Image"
            className="img-fluid mb-5"
          />
        </Col>
        <Col md={6}>
          <div className="mx-auto w-75">
            <img src={divslyLogo} alt="divsly-logo" className="mb-5 pb-5" />
            <h2 className="mb-5 pb-5">
              Hi {userName},
              <br />
              You are just about to finish.
            </h2>
            <p className="mb-5 pb-5">
              You must agree to the terms and conditions before proceding
              further.
            </p>
            <form
              id="kt_account_profile_details_form"
              className="form"
              onSubmit={handleSubmit(submitHandler)}
            >
              <Form.Group controlId="phoneNumber" className="mb-5">
                <Form.Label>
                  Mobile Number<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <PhoneInput
                  className="form-control bg-white h-55px w-75 border border-gray-300 form-control-lg form-control-solid mb-2 pe-15 rounded-55rem"
                  country={countryCode}
                  value={mobile}
                  name="mobile"
                  onChange={(phone, data, event, formattedValue) => {
                    const counrtyCode = data?.countryCode?.toUpperCase();
                    const countryPhoneRegex = PHONE_REGEX[counrtyCode];
                    setPhoneRegex(countryPhoneRegex);
                    setValue("mobile", phone);
                    setValue("country", counrtyCode);
                  }}
                />
                {errors.mobile ? (
                  <div style={{ textAlign: "start" }}>
                    <span className="error-text text-danger">
                      {errors.mobile.message}
                    </span>
                  </div>
                ) : null}
              </Form.Group>

              {/* <Form.Group
                controlId="acceptTerms"
                className="w-75 my-5 p-4 border rounded"
              >
                <Form.Check
                  type="checkbox"
                  label="I agree with terms of service, Privacy Policy application use and data processing aggrement"
                  checked={termsAccepted}
                  onChange={() => setTermsAccepted(!termsAccepted)}
                  required
                />
              </Form.Group> */}
              <Label className="w-75 my-5 p-4 border rounded form-check-label">
                <Controller
                  name="useracceptedterms"
                  control={control}
                  render={({ field }) => (
                    <div className="me-2">
                      <Input
                        {...field}
                        className="w-full"
                        type="checkbox"
                        value={field.value}
                        onChange={async (e) => {
                          field.onChange(e);
                        }}
                      />
                    </div>
                  )}
                />
                I agree with terms of service, Privacy Policy application use
                and data processing aggrement
              </Label>

              {errors.useracceptedterms ? (
                <div style={{ textAlign: "start" }}>
                  <span className="error-text text-danger">
                    {errors.useracceptedterms.message}
                  </span>
                </div>
              ) : null}

              <Button
                variant="primary"
                type="submit"
                className="d-flex btn btn-info w-75 align-items-center justify-content-between"
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <>
                    Continue Process
                    <img
                      src={divslyIcon}
                      alt="divsly-icon"
                      className="w-40px"
                    />
                  </>
                )}
              </Button>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AcceptTerms;
