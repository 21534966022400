import Offcanvas from "react-bootstrap/Offcanvas";
import { FormGroup, Label, Input, Col, Row } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import React, { useEffect } from "react";
import { getUtmQuery } from "../../../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DrawerWrapper from "../../wrapper/drawerWrapper";

const AddNewUTM = ({
  isURLParamBuilderOpen,
  setIsURLParamBuilderOpen,
  form,
}) => {
  const { getValues, setValue: setFormValue, watch } = form;

  const createUtmValidation = yup.object().shape({
    utm_source: yup.string().required("Source is required"),
    utm_medium: yup.string().required("Medium is required"),
    utm_campaign: yup.string().required("Name is required"),
  });

  const utmForm = useForm({
    resolver: yupResolver(createUtmValidation),
    defaultValues: {
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
      utm_id: "",
      utm_term: "",
      utm_content: "",
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = utmForm;

  // const submitHandler = (data) => {
  //   const { destinationUrl } = getValues();
  //   const utmQuery = getUtmQuery(data);
  //   setFormValue("url", destinationUrl + utmQuery);
  //   setFormValue("utm_source", data.utm_source);
  //   setFormValue("utm_medium", data.utm_medium);
  //   setFormValue("utm_campaign", data.utm_campaign);
  //   setFormValue("utm_id", data.utm_id);
  //   setFormValue("utm_term", data.utm_term);
  //   setFormValue("utm_content", data.utm_content);
  //   setIsURLParamBuilderOpen(false);
  // };

  const submitHandler = (data) => {
    const { destinationUrl } = getValues();
    const url = new URL(destinationUrl);

    const urlParams = new URLSearchParams(url.search);

    const setUTMParam = (param, value) => {
      if (value) {
        urlParams.set(param, value);
      }
    };

    setUTMParam("utm_source", data.utm_source);
    setUTMParam("utm_medium", data.utm_medium);
    setUTMParam("utm_campaign", data.utm_campaign);
    setUTMParam("utm_id", data.utm_id);
    setUTMParam("utm_term", data.utm_term);
    setUTMParam("utm_content", data.utm_content);

    url.search = urlParams.toString();

    setFormValue("url", url.toString());
    setFormValue("utm_source", urlParams.get("utm_source"));
    setFormValue("utm_medium", urlParams.get("utm_medium"));
    setFormValue("utm_campaign", urlParams.get("utm_campaign"));
    setFormValue("utm_id", urlParams.get("utm_id"));
    setFormValue("utm_term", urlParams.get("utm_term"));
    setFormValue("utm_content", urlParams.get("utm_content"));
    setIsURLParamBuilderOpen(false);
  };

  const {
    utm_campaign,
    utm_content,
    utm_id,
    utm_medium,
    utm_source,
    utm_term,
  } = watch();

  useEffect(() => {
    reset({
      utm_campaign,
      utm_content,
      utm_id,
      utm_medium,
      utm_source,
      utm_term,
    });
  }, [
    utm_campaign,
    utm_content,
    utm_id,
    utm_medium,
    utm_source,
    utm_term,
    reset,
  ]);

  const header = (
    <Offcanvas.Header className="px-lg-20 border-bottom" closeButton>
      <h2 className="fw-bold">URL Parameters Builder</h2>
    </Offcanvas.Header>
  );

  return (
    <DrawerWrapper
      header={header}
      open={isURLParamBuilderOpen}
      toggle={() => setIsURLParamBuilderOpen(false)}
    >
      <form onSubmit={handleSubmit(submitHandler)}>
        <Row>
          <Col md={6} className="px-5">
            <FormGroup>
              <Label className="fs-5 fw-bold mb-2">Campaign ID</Label>
              <Controller
                name={"utm_id"}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    value={value}
                    type="text"
                    className="h-50px"
                    placeholder="e.g: abc.123"
                    onChange={(e) => {
                      onChange(e);
                      setValue("utm_id", e.target.value);
                    }}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col md={6} className="px-5">
            <FormGroup>
              <Label className="required fs-5 fw-bold mb-2">Source</Label>
              <div className="mb-5">
                <Controller
                  name={"utm_source"}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      value={value}
                      className="h-50px"
                      type="text"
                      placeholder="e.g: Instagram, Facebook"
                      onChange={(e) => {
                        onChange(e);
                        setValue("utm_source", e.target.value);
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
              {errors.utm_source ? (
                <div style={{ textAlign: "end" }}>
                  <span className="error-text text-danger">
                    {errors.utm_source.message}
                  </span>
                </div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6} className="px-5">
            <FormGroup>
              <Label className="required fs-5 fw-bold mb-2">Medium</Label>
              <div className="mb-5">
                <Controller
                  name={"utm_medium"}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      value={value}
                      type="text"
                      className="h-50px"
                      placeholder="e.g: cpc, banner, email"
                      onChange={(e) => {
                        onChange(e);
                        setValue("utm_medium", e.target.value);
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
              {errors.utm_medium ? (
                <div style={{ textAlign: "end" }}>
                  <span className="error-text text-danger">
                    {errors.utm_medium.message}
                  </span>
                </div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6} className="px-5">
            <FormGroup>
              <Label className="required fs-5 fw-bold mb-2">Name</Label>
              <div className="mb-5">
                <Controller
                  name={"utm_campaign"}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      value={value}
                      type="text"
                      className="h-50px"
                      placeholder="e.g: summer_sale"
                      onChange={(e) => {
                        onChange(e);
                        setValue("utm_campaign", e.target.value);
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
              {errors.utm_campaign ? (
                <div style={{ textAlign: "end" }}>
                  <span className="error-text text-danger">
                    {errors.utm_campaign.message}
                  </span>
                </div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6} className="px-5">
            <FormGroup>
              <Label className="fs-5 fw-bold mb-2">Term</Label>
              <div className="mb-5">
                <Controller
                  name={"utm_term"}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      value={value}
                      type="text"
                      className="h-50px"
                      placeholder="eg: paid keywords"
                      onChange={(e) => {
                        onChange(e);
                        setValue("utm_term", e.target.value);
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
            </FormGroup>
          </Col>
          <Col md={6} className="px-5">
            <FormGroup>
              <Label className="fs-5 fw-bold mb-2">Content</Label>
              <div className="mb-5">
                <Controller
                  name={"utm_content"}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      value={value}
                      type="text"
                      className="h-50px"
                      placeholder="e.g: AD Name"
                      onChange={(e) => {
                        onChange(e);
                        setValue("utm_content", e.target.value);
                      }}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              color="primary"
              type="submit"
              className="btn btn-info mt-10 w-100"
              style={{ height: "50px", borderRadius: "5px" }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </form>
    </DrawerWrapper>
  );
};

export default AddNewUTM;
