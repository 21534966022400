import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import convertToK from "../../../utils/convertToK";

const ChartColors = {
  labelColor: "#a1a5b7",
  borderColor: "#e1e3ea",
  baseColor: "#50cd89",
  lightColor: "#50cd89",
};

const UsersChart = ({ stats, type }) => {
  const { data, categories } = stats || {};
  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) return;

    const element = chartRef.current;

    const height = "450px";
    const { labelColor, borderColor, baseColor, lightColor } = ChartColors;

    const options = {
      series: [
        {
          name: "Clicks",
          data,
        },
      ],
      chart: {
        fontFamily: "inherit",
        type: "area",
        height: height,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 80, 100],
        },
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [baseColor],
      },
      xaxis: {
        categories,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 6,
        labels: {
          rotate: 0,
          rotateAlways: true,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        crosshairs: {
          position: "front",
          stroke: {
            color: baseColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        tickAmount: 4,
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
          formatter: function (val) {
            return convertToK(val);
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return convertToK(val);
          },
        },
      },
      colors: [lightColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeColor: baseColor,
        strokeWidth: 3,
      },
    };

    const chart = new ApexCharts(element, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data, categories, type]);

  return <div ref={chartRef}></div>;
};

export default UsersChart;
