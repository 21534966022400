
export const getBackground = (background) => {
    return {
        flatColor: { backgroundColor: background },
        gradient: { background },
        image: {
            backgroundImage: `url(${background})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
        },
    };
}

export const getShadow = (btnShadowColor) => {
    return {
        type1: {
            boxShadow: `${btnShadowColor} 0px 4px 4px 0px`,
            borderRadius: "0",
        },
        type2: {
            boxShadow: `${btnShadowColor} 0px 4px 4px 0px`,
            borderRadius: "0.75rem",
        },
        type3: {
            boxShadow: `${btnShadowColor} 0px 4px 4px 0px`,
            borderRadius: "50rem",
        },
        type4: {
            boxShadow: `${btnShadowColor} 4px 4px 0px 0px`,
            borderRadius: "0",
        },
        type5: {
            boxShadow: `${btnShadowColor} 4px 4px 0px 0px`,
            borderRadius: "0.75rem",
        },
        type6: {
            boxShadow: `${btnShadowColor} 4px 4px 0px 0px`,
            borderRadius: "50rem",
        },
    };

}

export const getButton = (btnColor, btnShadowColor) => {
    return {
        type1: {
            backgroundColor: btnColor,
            borderRadius: "0",
        },
        type2: {
            backgroundColor: btnColor,
            borderRadius: "0.75rem",
        },
        type3: {
            backgroundColor: btnColor,
            borderRadius: "50rem",
        },
        type4: {
            outline: `${btnColor} 2px solid`,
            backgroundColor: 'transparent',
            borderRadius: "0",
        },
        type5: {
            outline: `${btnColor} 2px solid`,
            backgroundColor: "transparent",
            borderRadius: "0.75rem",
        },
        type6: {
            outline: `${btnColor} 2px solid`,
            backgroundColor: "transparent",
            borderRadius: "50rem",
        },
        type7: {
            backgroundColor: btnColor,
            boxShadow: `${btnShadowColor} 0px 4px 4px 0px`,
            borderRadius: "0",
        },
        type8: {
            backgroundColor: btnColor,
            boxShadow: `${btnShadowColor} 0px 4px 4px 0px`,
            borderRadius: "0.75rem",
        },
        type9: {
            backgroundColor: btnColor,
            boxShadow: `${btnShadowColor} 0px 4px 4px 0px`,
            borderRadius: "50rem",
        },
        type10: {
            backgroundColor: btnColor,
            boxShadow: `${btnShadowColor} 4px 4px 0px 0px`,
            borderRadius: "0",
            border: `1px solid ${btnShadowColor}`
        },
        type11: {
            backgroundColor: btnColor,
            boxShadow: `${btnShadowColor} 4px 4px 0px 0px`,
            borderRadius: "0.75rem",
            border: `1px solid ${btnShadowColor}`
        },
        type12: {
            backgroundColor: btnColor,
            boxShadow: `${btnShadowColor} 4px 4px 0px 0px`,
            borderRadius: "50rem",
            border: `1px solid ${btnShadowColor}`
        },
    };
}

