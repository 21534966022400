import {
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import Copy from "../../common/copy";
import QrCode from "../../common/qrCode";
import imageDownloadHandler from "../../../utils/imageDownLoadHandler";
import imageCopyHandler from "../../../utils/imageCopyHandler";
import { useState } from "react";
import convertToURLFriendly from "../../../utils/convertToURLFriendly";

export const copyQRHandler = (id) => {
  try {
    const canvas = document.querySelector(id).querySelector("canvas");
    canvas.toBlob((blob) => {
      navigator.clipboard
        .write([new ClipboardItem({ "image/png": blob })])
        .then(() => {})
        .catch((error) => {
          console.error("Unable to copy image to clipboard", error);
        });
    }, "image/png");
  } catch (e) {
    console.log("Error while copy: ", e);
  }
};

const FinelResultedQr = ({ form, tabsHandler }) => {
  const { watch, setValue } = form;
  const { brandedDomain, slashTag, qr } = watch();
  const [isCopied, setIsCopied] = useState(false);

  const getShortLink = () => {
    return `${brandedDomain}${
      convertToURLFriendly(slashTag) ? `/${convertToURLFriendly(slashTag)}` : ""
    }`;
  };

  const copyHandler = () => {
    setIsCopied(true);
    imageCopyHandler(qr);
    setTimeout(() => {
      setIsCopied(false);
    }, [1 * 1000]);
  };

  return (
    <Col
      md={5}
      className="mt-9 d-flex flex-column pe-4 ps-13 position-relative mobile-hide-f"
      style={{ borderLeft: "1px solid rgb(232, 228, 255)" }}
    >
      <div
        className="py-10 rounded-1 position-sticky"
        style={{
          border: "1px solid #e9e6f1",
          top: "50px",
          backgroundColor: "#F7F5FD",
        }}
      >
        <div
          className="d-flex py-3 w-75 mx-auto justify-content-between align-items-center border-bottom position-sticky mb-5"
          style={{ top: "0px" }}
        >
          <h5 className="fs-3 mb-0 fw-bold ">Your QR Code</h5>
          <Button
            disabled={isCopied}
            onClick={copyHandler}
            type="button"
            className="btn btn-light-info rounded-1 border px-3 py-1 border-info border-opacity-25"
          >
            {isCopied ? "Copied!" : "Copy"}
          </Button>
        </div>
        <div className="w-75 mx-auto">
          <div
            className="d-flex justify-content-center"
            style={{ position: "relative" }}
          >
            <QrCode values={watch()} valueHandler={setValue} />
          </div>
        </div>
        <div className="pt-2 border-top">
          <p className="fs-4 fw-bold mt-3 mb-5 text-center">
            {getShortLink()}
            <span className="ms-1 cursor-pointer">
              <Copy copyData={getShortLink()} />
            </span>
          </p>
        </div>
        <div className="d-flex gap-3 w-75 mx-auto justify-content-center">
          <UncontrolledDropdown
            className="dropdown-header flex-grow-1"
            direction="bottom"
          >
            <DropdownToggle className="rounded-1 btn btn-light-info border border-info border-opacity-25 w-100">
              Download<i className="fa-solid fa-chevron-down ms-3"></i>
            </DropdownToggle>
            <DropdownMenu className="w-100">
              <DropdownItem
                onClick={() => imageDownloadHandler(watch(), "png")}
              >
                Download PNG
              </DropdownItem>
              <DropdownItem
                onClick={() => imageDownloadHandler(watch(), "jpg")}
              >
                Download JPG
              </DropdownItem>
              <DropdownItem
                onClick={() => imageDownloadHandler(watch(), "svg")}
              >
                Download SVG
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <Button
            type="button"
            onClick={() => {
              tabsHandler(2);
              var custcategory = document.getElementById("custcategory");
              var qrcategory = document.getElementById("qrcategory");
              qrcategory.classList.remove("activeqrtab");
              custcategory.classList.add("activeqrtab");
            }}
            className="btn btn-light-info rounded-1 border border-info border-opacity-25 flex-grow-1 bg-transparent"
          >
            <i className="fa-regular fa-palette"></i> Customize
          </Button>
        </div>
      </div>
    </Col>
  );
};

export default FinelResultedQr;
