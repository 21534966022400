import { useContext } from "react";
import Category from "./category";
import Goal from "./goal";
import NoTemplate from "./no-template";
import Templates from "./templates";
import { LinkInBioContxt } from "../linkInBioContxt";

const CreatePageIntro = () => {
  const {
    bioPageForm: { watch },
  } = useContext(LinkInBioContxt);
  const { goal } = watch();
  return (
    <div
      className="create-link-in-bio bg-white rounded-2 px-6 px-lg-10 py-10 align-content-start position-absolute"
      data-kt-scroll-max-height="auto"
      data-kt-scroll-offset="100px"
      style={{
        right: "0px",
        height: "100%",
        left: "0",
        marginLeft: "0",
        top: "0",
      }}
    >
      <div className="row">
        <div className="col-12">
          <div className="mb-6 mt-2">
            <h2 className="fs-2x">Create your Link in Bio Page</h2>
            <p className="text-gray-600 fw-semibold fs-5 mx-auto">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam,
              nihil.
            </p>
          </div>
          <div className="separator mb-10 border-gray-250"></div>
        </div>
        <div className="col-12 col-lg-6 h-100 pe-lg-8 border-right">
          <Category />
          <div className="separator mt-10 border-gray-250"></div>
          <Goal />
        </div>
        <div className="col-lg-6 col-12 h-100 d-flex align-items-center ps-lg-10 borderleftlib">
          {goal?.length > 0 ? <Templates /> : <NoTemplate />}
        </div>
      </div>
    </div>
  );
};

export default CreatePageIntro;
