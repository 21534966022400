import { Spinner } from "reactstrap";
import AnalyticsNotFound from "../common/emptyScreens/analyticsNotFound";
import LineClicksChart from "./apex-charts/lineClicksChart";
import dayjs from "dayjs";

const ClicksChart = ({ clicks, loading, totalClicks }) => {
  const data = clicks?.map((item) => item?._count?.createdAt);
  const categories = clicks?.map((item) => dayjs(item?.date).format("MMM DD"));

  return (
    <div class="col-lg-6 mb-lg-5 mb-xl-0">
      <div class="card card-flush overflow-hidden h-md-100">
        <div class="card-header min-h-lg-70px min-h-50px px-5 px-lg-10 py-5">
          <h3 class="card-title m-0 align-items-start flex-column">
            <span class="card-label fs-3 fw-bold text-gray-900">
              Total Clicks
            </span>
          </h3>

          <div class="card-toolbar"></div>
        </div>

        <div class="card-body border-top d-flex justify-content-between flex-column pb-1 px-0">
          <div class="px-lg-9 px-5 mb-5">
            <div class="d-flex mb-2" style={{ alignItems: "flex-end" }}>
              <span class="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">
                {totalClicks}
              </span>
              <span class="fs-6 fw-semibold text-gray-500 me-1">Clicks</span>
            </div>
          </div>

          <div
            class="min-h-auto ps-2 clicksandscanlinebar"
          >
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "250px",
                }}
              >
                <Spinner />
              </div>
            ) : clicks?.length > 0 ? (
              <LineClicksChart stats={{ data, categories }} type={"clicks"} />
            ) : (
              <AnalyticsNotFound />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClicksChart;
