const LinkInBioTabs = ({ tabHandler, tab }) => {
  return (
    <div className="card-header min-h-auto mt-3 h-lg-50px px-0 w-100">
      <div className="card-title m-0 nav nav-tabs border-bottom-0 w-100">
        <div className="fs-6 border-0 g-5 mx-0 row gap-5 w-100">
          <div
            onClick={() => tabHandler("links")}
            className="nav-item mt-0 col px-0"
          >
            <div
              className={`nav-link w-100 btn text-dark rounded-1 border border-dark border-opacity-10 fs-3 fw-bold d-flex align-items-center activate-cat ${
                tab === "links" ? "activeLinkInBioTab" : ""
              }`}
            >
              <i className="fa-light fa-link fs-4 me-4"></i> Links
            </div>
          </div>
          <div
            onClick={() => tabHandler("customization")}
            className="nav-item mt-0 col px-0"
          >
            <div
              className={`nav-link w-100 btn text-dark rounded-1 border border-dark border-opacity-10 fs-3 fw-bold d-flex align-items-center activate-cat ${
                tab === "customization" ? "activeLinkInBioTab" : ""
              }`}
            >
              <i className="fal fa-palette fs-4 me-4"></i> Customization
            </div>
          </div>
          <div
            onClick={() => tabHandler("analytics")}
            className="nav-item mt-0 col px-0"
          >
            <div
              className={`nav-link w-100 btn text-dark rounded-1 border border-dark border-opacity-10 fs-3 fw-bold d-flex align-items-center activate-cat ${
                tab === "analytics" ? "activeLinkInBioTab" : ""
              }`}
            >
              <i className="fa-light fa-chart-mixed fs-4 me-4"></i> Analytics
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkInBioTabs;
