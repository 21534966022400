import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
const checkUserAuthentication = () => {
    let localData = localStorage.getItem("user_details");
    let local = localData;
    return JSON.parse(local);
};
const RestrictLoginPage = () => {

    const [searchParams] = useSearchParams();
    const invite = searchParams.get("invite") || "";
    
    const isAuthenticated = checkUserAuthentication();

    if (!invite && isAuthenticated !== null) {
        return isAuthenticated ? <Navigate to={isAuthenticated?.isAdmin ? '/admin-dashboard' : '/dashboard'} replace /> : null; // or loading indicator, etc...
    }
    return <Outlet to={!invite ? "/login" : '/signup'} replace />;
};
export default RestrictLoginPage;