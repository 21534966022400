const MSG_TYPE = {
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error'
}

export const MESSAGE = {
    ACCOUNT_ALREADY_EXIST: { label: "This account already exist!", value: MSG_TYPE.ERROR },
    VERIFICATION_EMAIL_NOT_SENT: { label: "Varification email not sent!", type: MSG_TYPE.INFO },
    VERIFICATION_EMAIL_SENT: { label: "Varification email sent to you!", type: MSG_TYPE.INFO },
    ACCOUNT_NOT_EXIST: { label: "This account does not exist!", type: MSG_TYPE.INFO },
    ACCOUNT_VERIFIED: { label: "This account is verified!", type: MSG_TYPE.INFO },
    ACCOUNT_NOT_VERIFIED: { label: "This account is not verified!", type: MSG_TYPE.INFO },

    ACCOUNT_DE_ACTIVATED: { label: "This account has been de-activated!", type: MSG_TYPE.INFO },
    REGISTERED_WITH_ANOTHER_METHOD: { label: "This account is registered with another method!", type: MSG_TYPE.INFO },
    INVALID_CREDENTIALS: { label: "Invalid crednetials!", type: MSG_TYPE.INFO },

    PASSWORD_RESET_EMAIL_SENT: { label: "Password reset email sent to you!", type: MSG_TYPE.INFO },
    PASSWORD_RESET_NOT_EMAIL_SENT: { label: "Password reset email not sent!", type: MSG_TYPE.INFO },
    PASSWORD_RESET_LINK_EXPIRED: { label: "Password reset link has been expired!", type: MSG_TYPE.INFO },
    PASSWORD_RESET: { label: "Password reset successfully!", type: MSG_TYPE.INFO },
    PASSWORD_NOT_RESET: { label: "Password not reset!", type: MSG_TYPE.INFO },
}