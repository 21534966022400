import React, { useCallback, useState, useMemo, useRef } from 'react';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Controls,
  addEdge,
  updateEdge,  // Import updateEdge
  reconnectEdge,
} from 'react-flow-renderer';
import 'react-flow-renderer/dist/style.css';
import { SourceNode, TargetNode } from './CustomNode';
import axios from 'axios';
import ProgressBar from '@ramonak/react-progress-bar';
import ContactApi from '../../services/http/contact';
import Papa from 'papaparse';
import CustomEdge from './CustomEdge';
import { Spinner } from 'reactstrap';

const toCamelCase = (str) => {
  return str
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase()
    )
    .replace(/\s+/g, '');
};

const CHUNK_SIZE = 1000;

const ColumnMapping = ({ fields, headers, csvData, closeAllCanvas, handleRelod }) => {

  const onDeleteEdge = (edgeId) => {
    setEdges((eds) => eds.filter((edge) => edge.id !== edgeId));
  };
  
  const initialNodes = [
    ...fields.map((field, index) => ({
      id: `field-${index + 1}`,
      type: 'sourceNode',
      data: { label: field.label, name: field?.name },
      position: { x: 100, y: index * 60 },
    })),
    ...headers.map((header, index) => ({
      id: `header-${index + 1}`,
      type: 'targetNode',
      data: { label: header, edgeId: null, onDeleteEdge: onDeleteEdge },
      position: { x: 500, y: index * 60 },
    }))
  ];

  // Prepopulate edges based on matching field names
  const initialEdges = fields
    .map((field, fieldIndex) => {
      const headerIndex = headers.findIndex(
        (header) => header.toLowerCase() === field.label.toLowerCase()
      );
      const edgeId = `edge-field-${fieldIndex + 1}-header-${headerIndex + 1}`;
      if (headerIndex !== -1) {
        // Update target node with the edgeId
        initialNodes[fields.length + headerIndex].data.edgeId = edgeId;
        return {
          id: edgeId,
          source: `field-${fieldIndex + 1}`,
          target: `header-${headerIndex + 1}`,
          // type: 'smoothstep',
          arrowHeadType: 'arrowclosed',
          style: { stroke: '#000', strokeWidth: 1 },
          markerEnd: { type: 'arrowclosed', color: '#000' },
        };
      }
      return null;
    })
    .filter(Boolean);

  let userData = localStorage?.getItem('user_details');

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [nextNodeId, setNextNodeId] = useState(initialNodes.length + 1);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [successResponse, setSuccessResponse] = useState(null);
  const [commonTags, setCommonTags] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [contactsWithError, setContactsWithError] = useState(null);

  const onConnect = useCallback(
    (params) => {
      const { source, target } = params;
  
      // Check if the source or target is already connected
      const targetNodeAlreadyConnected = edges.some((edge) => edge.target === target);
      const sourceNodeAlreadyConnected = edges.some((edge) => edge.source === source);
  
      if (!targetNodeAlreadyConnected && !sourceNodeAlreadyConnected) {
        const edgeId = `edge-${source}-${target}`;
  
        // Add the new edge
        setEdges((eds) =>
          addEdge(
            {
              ...params,
              id: edgeId, // Assign the generated edgeId
              // type: 'smoothstep',
              arrowHeadType: 'arrowclosed',
              style: { stroke: '#000', strokeWidth: 1 },
              markerEnd: { type: 'arrowclosed', color: '#000' },
            },
            eds
          )
        );
  
        // Update the target node's data with the edgeId
        setNodes((nds) =>
          nds.map((node) => {
            if (node.id === target) {
              return {
                ...node,
                data: { ...node.data, edgeId: edgeId }, // Set the edgeId in the target node's data
              };
            }
            return node;
          })
        );
      }
    },
    [edges, setEdges, setNodes]
  );  
  
  const onEdgeUpdate = useCallback(
    (oldEdge, newConnection) => {
      // Ensure the newConnection has an id; if not, use the oldEdge's id
      const updatedEdge = {
        ...newConnection,
        id: newConnection.id || oldEdge.id,
        arrowHeadType: 'arrowclosed',
        style: { stroke: '#000', strokeWidth: 1 },
        markerEnd: { type: 'arrowclosed', color: '#000' },
      };
  
      // Check if the new source or target is already connected to another node
      const sourceAlreadyConnected = edges.some(
        (edge) => edge.source === updatedEdge.source && edge.id !== oldEdge.id
      );
      const targetAlreadyConnected = edges.some(
        (edge) => edge.target === updatedEdge.target && edge.id !== oldEdge.id
      );
  
      if (sourceAlreadyConnected || targetAlreadyConnected) {
        // If either the source or target is already connected, reject the update
        return;
      }
  
      // Check if the new source or target is the same as the old ones
      const isSourceOrTargetChanged = oldEdge.source !== updatedEdge.source || oldEdge.target !== updatedEdge.target;
  
      // If the source or target has changed, clear the old edgeId from previous nodes
      if (isSourceOrTargetChanged) {
        setNodes((nds) =>
          nds.map((node) => {
            if (node.id === oldEdge.target) {
              // Clear the edgeId from the old target node
              return { ...node, data: { ...node.data, edgeId: null } };
            }
            return node;
          })
        );
      }
  
      // Update the edges state with the new connection
      setEdges((eds) => {
        // Remove old edge and add updated edge
        const updatedEdges = eds.filter((edge) => edge.id !== oldEdge.id);
        return addEdge(updatedEdge, updatedEdges);
      });
  
      // Update the new target node's edgeId
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === updatedEdge.target) {
            // Set the edgeId on the new target node
            return { ...node, data: { ...node.data, edgeId: updatedEdge.id } };
          }
          return node;
        })
      );
    },
    [edges, setEdges, setNodes]
  );
  

  const handleAddNode = () => {
    const lastSourceNode = nodes
      .filter((node) => node.type === 'sourceNode')
      .reduce((last, node) => (node.position.y > last.position.y ? node : last), { position: { y: 0 } });

    const newNode = {
      id: `${nextNodeId}`,
      type: 'sourceNode',
      data: {
        label: `New Column`,
        onEdit: (id) => handleNodeEdit(id),
        onUpdateLabel: (id, newLabel) => handleNodeLabelUpdate(id, newLabel),
      },
      position: { x: 100, y: lastSourceNode.position.y + 60 },
    };

    setNodes((nds) => [...nds, newNode]);
    setNextNodeId(nextNodeId + 1);
  };

  const handleNodeEdit = (id) => {
    // Logic for handling node edit, if any
  };

  const handleNodeLabelUpdate = (id, newLabel) => {
    const camelCaseName = toCamelCase(newLabel);
    setNodes((nds) =>
      nds.map((node) =>
        node.id === id ? { ...node, data: { ...node.data, label: newLabel, name: camelCaseName } } : node
      )
    );
  };

  // Memoize nodeTypes to prevent recreation on each render
  const nodeTypes = useMemo(() => ({
    sourceNode: SourceNode,
    targetNode: TargetNode,
  }), []);

  // download csv related options
  const handleDownloadCSV = () => {
    const csv = Papa.unparse(contactsWithError);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', 'contacts_with_errors.csv');
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // validate and upload related csv operations  
  const validateConnections = () => {
    const requiredFields = ['firstName', 'email', 'mobile'];
    const mappedFields = edges.map((edge) => {
      const sourceNode = nodes.find((node) => node.id === edge.source);
      const targetNode = nodes.find((node) => node.id === edge.target);
      return {
        applicationField: sourceNode?.data?.name,
        csvField: targetNode?.data?.label,
      };
    });

    const missingFields = requiredFields.filter((field) =>
      !mappedFields.some((mapped) => mapped.applicationField === field)
    );

    return {mappedFields, missingFields};
  };

  const isEmptyRow = (row) => {
    return Object.keys(row).every(key => {
      return row[key] === null || row[key] === '';
    });
  };

  const handleSubmit = async () => {
    
    setLoading(true);

    const {mappedFields, missingFields} = validateConnections();
    if (missingFields.length > 0) {
      setError(`Missing required fields: ${missingFields.join(', ')}`);
      setLoading(false);
      return;
    }

    setError(null);
    setSuccessMessage(null);
    setProgress(0);
    
    const tags = commonTags.trim() === "" ? [] : commonTags.trim().split(",").map(tag => tag.trim());
    const initialTransformedRowData = fields.reduce((acc, field) => ({ ...acc, [field.name]: null }), {});

    const chunks = [];
    const invalidContacts = [];
    const duplicateContacts = [];
    const numberEmailMap = new Map();
    const contactsWithValidation = [];

    for (let i = 0; i < csvData.length; i += CHUNK_SIZE) {
      let chunkRows = csvData.slice(i, i + CHUNK_SIZE);
      
      let transformedData = []
      chunkRows.forEach((row) => {

        if (isEmptyRow(row)) {
          return;
        }

        let transformedRow = { ...initialTransformedRowData, tags: [], other: {} };
        let validationRow = { ...row, error: '', cause: '' };

        mappedFields.forEach((mapping) => {
          const value = row[mapping.csvField];
          if (mapping.applicationField === "tags") {
            transformedRow.tags = value ? value.split(",") : [];
          } else if (initialTransformedRowData.hasOwnProperty(mapping.applicationField)) {
            transformedRow[mapping.applicationField] = value;
          } else {
            transformedRow.other[mapping.applicationField] = value;
          }
        });
  
        transformedRow.tags = [...tags, ...transformedRow.tags];
  
        let error = {};

        if (!transformedRow.firstName) error.firstName = "First name must be provided";
        if (!transformedRow.mobile) error.mobile = "Mobile number must be provided";
        if (!transformedRow.email) error.email = "Email must be provided";
  
        const key = `${transformedRow.email}_${transformedRow.mobile}`;
        const isDuplicate = numberEmailMap.has(key);
        
        if (isDuplicate) {
          validationRow = { ...validationRow, error: "Duplicate", cause: "Duplicate (Email + Mobile) in list" };
          duplicateContacts.push({ contact: transformedRow, cause: "Duplicate entry in list" });
    
          // Mark the first occurrence as duplicate as well
          const firstOccurrence = numberEmailMap.get(key);
          if (!firstOccurrence.isDuplicateMarked) {
            firstOccurrence.isDuplicateMarked = true;
            const firstValidationRow = contactsWithValidation.find(item => item.email === firstOccurrence.email && item.mobile === firstOccurrence.mobile);
            if (firstValidationRow) {
              firstValidationRow.error = "Duplicate";
              firstValidationRow.cause = "Duplicate (Email + Mobile) in list";
            }
          }
        } else if (Object.keys(error).length > 0) {
          if (error.firstName || error.mobile || error.email) {
            validationRow = { ...validationRow, error: "Invalid", cause: "Required fields are missing" };
            invalidContacts.push({ contact: transformedRow, cause: error });
          }
        } else {
          numberEmailMap.set(key, transformedRow);
        }
  
        const isRowEmpty = Object.keys(validationRow).every(key => 
          key === 'error' || key === 'cause' || validationRow[key] === null || validationRow[key] === ''
        );
        
        if (!isRowEmpty) {
          contactsWithValidation.push(validationRow);
        }
        
        transformedData.push(transformedRow);
      });
  
      if(transformedData.length > 0) {
        chunks.push(transformedData);
      }
    }

    if (duplicateContacts.length > 0 || invalidContacts.length > 0) {
      setError("Duplicate or Invalid entries found.");
      setContactsWithError(contactsWithValidation);
      setValidationError(true);
      setLoading(false);
      return;
    }

    // Initialize arrays to accumulate contacts
    let allValidContacts = [];
    let allInvalidContacts = [];
    let allDuplicateContacts = [];
    let allAlreadyPresentContacts = [];

    console.log(chunks.length);
    try {
      let progress = 0;
      for (let i = 0; i < chunks.length; i++) {
        const response = await uploadChunk(chunks[i]);

        // Accumulate contacts from response
        allValidContacts = allValidContacts.concat(response.validContacts);
        allInvalidContacts = allInvalidContacts.concat(response.invalidContacts);
        allDuplicateContacts = allDuplicateContacts.concat(response.duplicateContacts);
        allAlreadyPresentContacts = allAlreadyPresentContacts.concat(response.alreadyPresentContacts);

        progress = Math.floor(((i + 1) / chunks.length) * 100);
        setProgress(progress);

        if (progress === 100) {
          setSuccessMessage('All data uploaded successfully');
          setLoading(false);
          handleRelod();
          setCommonTags("")

          setSuccessResponse({
            validContacts: allValidContacts,
            invalidContacts: allInvalidContacts,
            duplicateContacts: allDuplicateContacts,
            alreadyPresentContacts: allAlreadyPresentContacts,
          })
        }
      }
    } catch (error) {
      setError('Upload failed');
      setLoading(false);
    }
  };

  const uploadChunk = async (chunk) => {
    try {
      const userId = JSON.parse(userData)?.id;
      const response = await ContactApi.bulkCreateContact({ contacts: chunk, userId });
      if (response.status === 201) {
        return response.data;
      } else {
        throw new Error('Failed to upload chunk');
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <input type='text' disabled={loading} value={commonTags} onChange={(e) => setCommonTags(e.target.value)} className='form-control mb-3' placeholder='Add your tags here with comma separator' />
      
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {(successResponse || validationError) ? <>
          <button className='btn btn-info px-5 py-3' onClick={closeAllCanvas}>Close</button>
          {(contactsWithError) && <>
            <button className='btn btn-info px-5 py-3' onClick={handleDownloadCSV}>Export CSV</button>
          </>}
        </> : <>
          <button className='btn btn-info px-5 py-3' onClick={handleAddNode} disabled={loading}>Add New Column</button>
          <button className='btn btn-info px-5 py-3' onClick={handleSubmit} disabled={loading}>{loading ? <Spinner size="sm"/> : <>Submit</>}</button>
        </>}
      </div>

      {error && <p style={{ color: 'red', textAlign: "center" }}>{error}</p>}
      {successMessage && <p style={{ color: 'green', textAlign: "center" }}>{successMessage}</p>}

      <ProgressBar className='mt-4' completed={progress} />

      {!successResponse && <>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgeUpdate={onEdgeUpdate}  // Add this to enable edge reconnection
          onConnect={onConnect}
          fitView
          attributionPosition="top-right"
          nodeTypes={nodeTypes}  // Use memoized node types
          onDeleteEdge={onDeleteEdge}  // Pass onDeleteEdge to all nodes
        >
          <Controls />
        </ReactFlow>
      </>}
    </>
  );
};

export default ColumnMapping;
