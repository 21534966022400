import { useEffect, useState } from "react";
import { getCountryCode } from "countries-list";
import { Spinner } from "reactstrap";
import Map from "./map";
import SharedApi from "../../../services/http/shared";
import MockCountryStat from "../../../mock/countryStat";

const FLAG_BASE_URL =
  "https://purecatamphetamine.github.io/country-flag-icons/3x2/";

const ClicksAnalytics = ({ country = [], city = [], shortlink }) => {
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [loading, setLoading] = useState(false);

  const [tab, setTab] = useState("country");

  const mappedData = {
    country: selectedCountries.length > 0 ? selectedCountries : country,
    city: selectedCities.length > 0 ? selectedCities : city,
  };

  const selectedData = mappedData[tab];

  const countriesHandler = (countryName) => {
    setSelectedCountries((preState) => {
      const mapperCountries =
        preState
          ?.filter((item) => item?.country !== "")
          .map((item) => item?.country) || [];
      if (mapperCountries.includes(countryName)) {
        setSelectedCities((preState) => {
          return preState?.filter((item) => item?.country !== countryName);
        });
        return preState?.filter((item) => item?.country !== countryName);
      } else {
        const fetchedClicks =
          country?.filter((item) => item?.country === countryName) || [];
        return fetchedClicks?.length > 0
          ? [...preState, fetchedClicks[0]]
          : preState;
      }
    });
  };

  const fetchCitiesHandler = async (countries) => {
    setLoading(true);
    const auth_token = localStorage.getItem("auth_token");
    SharedApi.fetchCities(auth_token, {
      shortlink,
      countries,
    })
      .then(({ data }) => {
        setSelectedCities(data?.resultData);
      })
      .catch(({ error }) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const mapperCountries =
      selectedCountries
        ?.filter((item) => item?.country !== "")
        .map((item) => item?.country) || [];
    if (shortlink && mapperCountries.length > 0) {
      fetchCitiesHandler(mapperCountries);
    }
  }, [shortlink, selectedCountries]);

  const values = country?.map((item) => item?._count?._all);

  const totalClicks = values?.reduce((total, val) => total + val, 0);

  const mappedCountries = country.map((item) => {
    return {
      id: getCountryCode(item?.country),
      name: item?.country,
      percent: `${
        ((item?._count?._all / totalClicks) * 100).toFixed(2) || 0
      }% <br/>`,
    };
  });

  return (
    <>
      <div className="row short-link-click-map-alts">
        <div className="col-12">
          <div className="d-flex mb-5 justify-content-between align-items-end cvlmedia">
            <h2 className="mb-0">Location</h2>
            <div className="nav-group nav-group-outline rounded-1 w-fit-content mt-5 mt-lg-0">
              <button
                onClick={() => setTab("country")}
                className={`btn btn-color-gray-400 btn-active btn-active-light-info rounded-1 fw-bold px-6 py-3 ${
                  tab === "country" ? " me-2 active" : ""
                }`}
              >
                Country
              </button>
              <button
                onClick={() => setTab("city")}
                className={`btn btn-color-gray-400 btn-active btn-active-light-info rounded-1 fw-bold px-6 py-3 ${
                  tab === "city" ? " me-2 active" : ""
                }`}
              >
                City
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card card-bordered w-100 border-gray-300 rounded-1 col-lg-6 mb-lg-4">
            <div className="card-body p-0">
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                {}
                <Map
                  countriesHandler={countriesHandler}
                  countries={mappedCountries}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6 mb-lg-0 mb-10">
          <div className="border border-gray-300 rounded-1 h-250px scroll-x p-3 ps-4">
            <table className="table table-row-bordered rounded-3 gy-5">
              <thead>
                <tr className="fw-semibold fs-6 text-muted">
                  <th>{tab === "country" ? "Country" : "City"}</th>
                  <th>Clicks</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <div
                    style={{
                      height: "200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spinner />
                  </div>
                ) : (
                  selectedData &&
                  selectedData?.map((data) => {
                    return (
                      <tr>
                        <td>
                          <span>
                            {tab === "country" ? (
                              <img
                                src={
                                  data?.country
                                    ? `${FLAG_BASE_URL}${getCountryCode(
                                        data?.country
                                      )}.svg`
                                    : "/no-image.png"
                                }
                                className="h-20px me-2"
                                alt={data?.country}
                              />
                            ) : null}
                            {data?.city || data?.country || "Others"}
                          </span>
                        </td>
                        <td>{data?._count?.city || data?._count?.country}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClicksAnalytics;
